import SimpleCorner from "../../model/SimpleCorner";
import {Environment} from "../environment/Environment";

export class OpeningConfigData {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.idOpeningCounter = 0;
        this.drawManager = drawManager;
    }


    /**
     * Mise en place de la structure de base d'une ouverture
     * @param {object} containerOpeningData
     * @returns {object}
     */
    getBasicOpeningScheme(containerOpeningData) {
        containerOpeningData = JSON.parse(JSON.stringify(Environment.globalConfigurationEnv.openingDataInit));
        return containerOpeningData
    }

    /**
     * mise à jour de l'ouverture à l'aide des informations du segment sélectionné
     * @param {object} openingData
     * @param {object} fenceElement
     * @param {Wall} wallSelected
     */
    setOpeningData(openingData, fenceElement, wallSelected) {
        openingData.belongToMainWallKey = wallSelected.belongToMainWallKey;
        openingData.belongToMainCornerSection = wallSelected.belongToMainCornerSection;
        openingData.wallBase = wallSelected;
        openingData.belongToFenceElement = fenceElement.name;
        openingData.id = this.idOpeningCounter++;
        openingData.positionOnMainWall = this.drawManager.openingManager.getPosOpeningOnMainWall(openingData);
        let lengthFromWallSelected = this.drawManager.openingManager.getPosition(wallSelected, openingData);
        let lengthFromMainWall = this.lengthFromMainWall(
            fenceElement[wallSelected.belongToMainCornerSection].mainWalls[wallSelected.belongToMainWallKey],
            wallSelected
        );
        openingData.posStart = lengthFromMainWall + lengthFromWallSelected;
    }

    /**
     * Crée et calcule la position des poteaux de l'ouverture mais, ne les dessine pas
     * @param {Wall} wallSelected
     * @param {object} fenceElement
     * @param type
     */
    createDataOpening(wallSelected, fenceElement,type) {
        // ici on ne connait pas encore la taille du portail on vérifié donc la possibilité de placer le plus petit
        if (!this.canPlaceDefaultOpening(wallSelected)) {
            return;
        }
        const keySection = wallSelected.belongToMainCornerSection;
        let keyOpening = fenceElement[keySection].openings.length;
        // Initialisation de la nouvelle ouverture
        const openingData = fenceElement[keySection].openings[keyOpening] = this.getBasicOpeningScheme(
            fenceElement[keySection].openings[keyOpening]
        );
        // mise à jour des informations à l'aide des informations du segment sélectionné
        this.setOpeningData(openingData, fenceElement, wallSelected)
        // cas glissière
        if(type === Environment.wallPanelEnv.button.addSlide){
            openingData.typeOpening = Environment.drawConfiguration.opening.type.slide;
            openingData.openingSize = Environment.drawConfiguration.opening.defaultDimension.slideSize;
            openingData.gapStart = null;
            openingData.gapEnd = null;
            this.createDataSlideMainSimpleCorners(openingData);
        }
        // cas portail
        if(type === Environment.wallPanelEnv.button.addOpening){
            openingData.typeOpening = Environment.drawConfiguration.opening.type.portal;
            openingData.openingSize = Environment.drawConfiguration.opening.defaultDimension.openingSize;
            openingData.gapStart = Environment.drawConfiguration.opening.defaultDimension.gapStart;
            openingData.gapEnd = Environment.drawConfiguration.opening.defaultDimension.gapEnd;
            this.createDataOpeningMainSimpleCorners(openingData);
        }
        fenceElement[keySection].openings.sort(function (a, b) {
            return a.posStart - b.posStart
        });
    }


    /**
     * Génère les données des poteaux principaux de l'ouverture
     * @param {object} openingData
     */
    createDataSlideMainSimpleCorners(openingData) {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        const keySection = openingData.belongToMainCornerSection;
        const positionFromWallStart = openingData.posStart;
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart,
            openingData
        );
        let referenceWallFixed = openingData.wallBase.centerDistance;
        let WallMobileCenterDistance = Environment.dataEcommerceEnv.handRail.slideLengthMini;
        if(openingData.wallPortalFixed){
            referenceWallFixed = openingData.wallPortalFixed.centerDistance;
        }
        if(openingData.wallPortal){
            WallMobileCenterDistance = openingData.wallPortal.centerDistance;
        }
        if(openingData.reverse){
            if(openingData.wallPortalFixed){
                referenceWallFixed = openingData.wallPortal.centerDistance;
            }
            if(openingData.wallPortal){
                WallMobileCenterDistance = openingData.wallPortalFixed.centerDistance;
            }
        }

        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart + referenceWallFixed,
            openingData
        );
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart + referenceWallFixed + WallMobileCenterDistance ,
            openingData
        );
        openingData.corners.forEach((simpleCorner) => {
            simpleCorner.setBelongToMainCornerSection(keySection);
        });
    }

    /**
     * Génère les données des poteaux principaux de l'ouverture
     * @param {object} openingData
     */
    createDataOpeningMainSimpleCorners(openingData) {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        const keySection = openingData.belongToMainCornerSection;
        const positionFromWallStart = openingData.posStart;
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart,
            openingData
        );
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart + openingData.gapStart,
            openingData
        );
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart + openingData.openingSize + openingData.gapStart,
            openingData
        );
        this.createDataOpeningMainSimpleCorner(
            fenceElement[keySection],
            positionFromWallStart + openingData.openingSize + openingData.gapStart + openingData.gapEnd,
            openingData
        );
        openingData.corners.forEach((simpleCorner) => {
            simpleCorner.setBelongToMainCornerSection(keySection);
        });
    }

    /**
     * Génère les données d'un poteau principal de l'ouverture
     * @param mainCornerSection
     * @param {number} positionFromWallStart
     * @param openingData
     */
    createDataOpeningMainSimpleCorner(mainCornerSection, positionFromWallStart, openingData) {
        let openingSimpleCornersData = openingData.corners;
        let mainWallSelected = mainCornerSection.mainWalls[openingData.belongToMainWallKey];
        let simpleCorner = new SimpleCorner(
            this.drawManager.getFloorplan(),
            mainWallSelected.start.x + mainWallSelected.wallDirectionNormalized().x * positionFromWallStart,
            mainWallSelected.start.y + mainWallSelected.wallDirectionNormalized().y * positionFromWallStart,
            "simpleCorner-" + (openingSimpleCornersData.length + 1) + ",opening-" + openingData.id
        )
        simpleCorner.setPosRelToStartCorner(mainWallSelected.start);
        openingSimpleCornersData.push(simpleCorner);
    }

    /**
     * Un mur principal a bougé on recalcule l'emplacement des openings
     * @param keySection
     * @param fenceElement
     */
    updateDataOpenings(keySection, fenceElement) {
        for (let i = 0; i < fenceElement[keySection].openings.length; i++) {
            fenceElement[keySection].openings[i].corners = [];
            // cas glissière
            if(fenceElement[keySection].openings[i].typeOpening === Environment.drawConfiguration.opening.type.slide){
                this.createDataSlideMainSimpleCorners(fenceElement[keySection].openings[i]);
            }
            // cas portal
            if(fenceElement[keySection].openings[i].typeOpening === Environment.drawConfiguration.opening.type.portal){
                this.createDataOpeningMainSimpleCorners(fenceElement[keySection].openings[i]);
            }
        }
    }

    /**
     * Retourne la distance entre le premier poteau de l'ouverture et le premier poteau du mur principal
     * @param {Wall} mainWall
     * @param {Wall} wallSelected
     */
    lengthFromMainWall(mainWall, wallSelected) {
        return Math.sqrt(Math.pow(mainWall.start.x - wallSelected.start.x, 2) + Math.pow(mainWall.start.y - wallSelected.start.y, 2));
    }

    /**
     * Met à jour les informations techniques de l'ouverture
     * @param {object} openingData
     * @param {string} portalType
     */
    setOpeningConfigData(openingData, portalType) {
        let gap = Environment.drawConfiguration.opening.defaultDimension.gapStart;
        // La taille d'une ouverture par défaut est la plus petite existante, on ne recalcule pas la posStart de l'ouverture
        openingData.openingSize = Environment.drawConfiguration.opening.defaultDimension.openingSize;
        openingData.gateLeavesNumber = null;
        openingData.portalType = null;
        openingData.technicalInfoPortal = null;
        let insideCornerRadius = Environment.drawConfiguration.opening.defaultDimension.cornerRadius;
        if (portalType) {
            const technicalInfo = this.drawManager.jsonDataManager.getTechnicalInfoPortalById(openingData.portal.id);
            if (technicalInfo) {
                openingData.openingSize = technicalInfo.centerDistanceInteriorCorner / 10;
                if (openingData.portalType !== portalType) {
                    this.drawManager.openingManager.replaceOpeningAtStart(openingData);
                }
                // on agrandit le gap pour meilleure affichage
                gap = technicalInfo.gapBetweenCornerFenceCornerPortal / 10;
                openingData.gateLeavesNumber = parseInt(technicalInfo.gateLeavesNumber);
                if(openingData.gateLeavesNumber === 2){
                    openingData.centerSealingWidth = technicalInfo.widthSealingCenter;
                    openingData.centerSealingLength = technicalInfo.lengthSealingCenter;
                    openingData.centerSealingHeight = technicalInfo.heightSealingCenter;
                }
                openingData.technicalInfoPortal = technicalInfo;
                insideCornerRadius = technicalInfo.widthCorner / 10
            }
            openingData.portalType = portalType;
        }
        this.setPortalFakeWallColor(openingData);
        let cornersRadius = [];
        for (let i = 0; i < openingData.corners.length; i++) {
            cornersRadius[i] = openingData.corners[i].cornerDiameterOrLength / 2;
            if (openingData.corners[i].cornerWidth > 0) {
                cornersRadius[i] = openingData.corners[i].cornerWidth / 2;
            }
        }
        openingData.gapStart = gap + cornersRadius[0] + insideCornerRadius;
        openingData.gapEnd = gap + insideCornerRadius + cornersRadius[3];
        this.updateDataOpenings(openingData.belongToMainCornerSection, this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement));
    }

    setPortalFakeWallColor(openingData) {
        if (openingData.portalType === null) {
            openingData.wallPortal.fakeWallColor = Environment.drawConfiguration.wall.fakeWall.defaultColor;
        }
        if (openingData.portalType === Environment.portalPanelEnv.inputFence2mPortal.type) {
            openingData.wallPortal.fakeWallColor = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeFakeWallColor["4"].portal;
        }
        if (openingData.portalType === Environment.portalPanelEnv.inputFence2mWicket.type) {
            openingData.wallPortal.fakeWallColor = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeFakeWallColor["4"].wicket;
        }
        if (openingData.portalType === Environment.portalPanelEnv.inputHandrailPortal.type) {
            openingData.wallPortal.fakeWallColor = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeFakeWallColor["2"].portal;
        }
        if (openingData.portalType === Environment.portalPanelEnv.inputHandrailWicket.type) {
            openingData.wallPortal.fakeWallColor = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeFakeWallColor["2"].wicket;
        }
    }

    reverseOpening(openingData, value) {
        openingData.reverse = value;
    }

    reversePivotOpening(openingData, value) {
        openingData.pivotLeft = value;
    }

    /**
     * Vérifie si une ouverture peut être placer sur le segment
     * @param {Wall} wallSelected
     * @returns {boolean}
     */
    canPlaceDefaultOpening(wallSelected) {
        const defaultOpeningSize = Environment.drawConfiguration.opening.defaultDimension.openingSize;
        const defaultGapStart = Environment.drawConfiguration.opening.defaultDimension.gapStart;
        const defaultGapEnd = Environment.drawConfiguration.opening.defaultDimension.gapEnd;
        const defaultSpacing = Environment.drawConfiguration.main.spacingMiniBetween2Corner;
        // Vérifie que le segment peut accepter une ouverture minimum
        const defaultLengthOpeningTotal = defaultGapStart + defaultGapEnd + defaultOpeningSize + 2 * defaultSpacing
        if (wallSelected.wallLength() < defaultLengthOpeningTotal) {
            alert(Environment.drawConfiguration.main.errorMessage.wallTooShort)
            return false;
        }
        return true;
    }

    /**
     *
     * @param {object} openingData
     * @returns {Number}
     */
    getOpeningSizeTotal(openingData) {
        return openingData.openingSize + openingData.gapStart + openingData.gapEnd;
    }

    /**
     * Estimation la taille minimum dont à besoin un portail
     * @returns {Number}
     * @param {object} openingData
     * @param {object} technicalInfo
     */
    openingEstimateNeededSpaceMini(openingData, technicalInfo) {
        let cornersRadius = [];
        for (let i = 0; i < openingData.corners.length; i++) {
            cornersRadius[i] = openingData.corners[i].cornerDiameterOrLength / 2;
            if (openingData.corners[i].cornerWidth > 0) {
                cornersRadius[i] = openingData.corners[i].cornerWidth / 2;

            }
        }
        let gap = technicalInfo.gapBetweenCornerFenceCornerPortal / 10;
        let openingSize = technicalInfo.centerDistanceInteriorCorner / 10;
        let insideCornerRadius = technicalInfo.widthCorner / 10
        let gapStart = gap + cornersRadius[0] + insideCornerRadius;
        let gapEnd = gap + insideCornerRadius + cornersRadius[3];

        return openingSize + gapStart + gapEnd + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2;
    }

}
