import {Vector2} from "three";
import {Dimensioning} from "../../core/dimensioning";
import {Configuration, snapToGrid, snapTolerance} from "../../core/configuration";
import {Environment} from "../environment/Environment";

export class MainCorner2D {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.drawManager = drawManager;
        this.__dragStartEvent = this.__dragStart.bind(this);
        this.__dragMoveEvent = this.__dragMove.bind(this);
        this.__dragEndEvent = this.__dragEnd.bind(this);
        this.__manageIntersectEvent = this.__manageIntersect.bind(this);
        this.__createNewMainCornerEvent = this.__createNewMainCorner.bind(this);
        this.startDrawX = 0;
        this.startDrawY = 0;
        this.isLastNodeSeleceted = false;
        this.modifyLastNodeMode = false;
        this.addEventOnClickMainCorner();
    }

    drawMainCorners(fenceElement) {
        for (const key of Object.keys(fenceElement)) {
            if (key.startsWith(Environment.globalConfigurationEnv.mainCornerSectionName)) {
                this.drawMainCorner(fenceElement, fenceElement[key]);
            }
        }
    }

    /**
     * @param fenceElement
     * @param fenceElementCorner
     */
    drawMainCorner(fenceElement, fenceElementCorner) {
        fenceElementCorner.corner = this.drawManager.getFloorplan().newCorner(fenceElementCorner.corner.x, fenceElementCorner.corner.y, fenceElementCorner.corner.id);
        fenceElementCorner.corner.belongToFenceElement = fenceElement.name;
    }

    /**
     * On récupère l'information du poteau le plus haut sur les segment liés à ce poteau
     * @param {Wall} wall
     * @param {Corner|SimpleCorner} corner
     * @returns {number}
     */
    getHighestMainCornerOnWall(wall, corner) {
        let highestWallCorner = 0;
        corner.wallStarts.forEach((itemWall) => {
            if (itemWall.id !== wall.id && itemWall.cornerHeightType >= wall.cornerHeightType) {
                highestWallCorner = itemWall.cornerHeightType;
            }
        })
        corner.wallEnds.forEach((itemWall) => {
            if (itemWall.id !== wall.id && itemWall.cornerHeightType >= wall.cornerHeightType) {
                highestWallCorner = itemWall.cornerHeightType;
            }
        })
        return highestWallCorner;
    }

    /**
     * On vérifie si le poteau cliqué est actuellement l'origine pour le dessin d'un segment
     * @param e
     */
    setIsLastNodeSeleceted(e) {
        return e.item.__corner.id === this.drawManager.blueprint3d.floorplanner.__lastNode.id &&
            e.item.__corner.belongToFenceElement === this.drawManager.blueprint3d.floorplanner.__lastNode.belongToFenceElement &&
            !this.modifyLastNodeMode;
    }

    /**
     * Mise à jour du dernier poteau comme origine pour le dessin d'un segment
     * @param e
     */
    setNewLastNodeSelected(e) {
        this.drawManager.blueprint3d.floorplanner.__lastNode = e.item.__corner;
    }

    createMainCornerCoToCm(co) {
        let cmCo = new Vector2(co.x, co.y);
        cmCo.x = Dimensioning.pixelToCm(cmCo.x);
        cmCo.y = Dimensioning.pixelToCm(cmCo.y);
        if (Configuration.getBooleanValue(snapToGrid) || this.drawManager.blueprint3d.floorplanner.__snapToGrid) {
            cmCo.x = Math.floor(cmCo.x / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
            cmCo.y = Math.floor(cmCo.y / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
        }
        return cmCo
    }

    /**
     * Neutralise le hover du fakeWall lorsqu'un poteau principal est cliqué
     */
    addEventOnClickMainCorner() {
        document.addEventListener('cornerMouseDown', () => {
            this.grugeVisible = false;
        })
        document.addEventListener('cornerMouseUp', () => {
            this.grugeVisible = true;
        })
    }

    cornerWallsHasOpening(corner) {
        if (this.cornerHasWallStarts(corner)) {
            if (this.drawManager.drawWall2D.checkWallsHasOpening(corner.wallStarts)) {
                return true;
            }
        }
        if (this.cornerHasWallEnds(corner)) {
            if (this.drawManager.drawWall2D.checkWallsHasOpening(corner.wallEnds)) {
                return true;
            }
        }
        return false;
    }

    cornerHasWallStarts(corner) {
        return corner.wallStarts.length > 0;
    }

    cornerHasWallEnds(corner) {
        return corner.wallEnds.length > 0;
    }

    /**
     * O/I Mode modification du point d'origine lors de l'ajout d'un segment
     */
    toggleModifyLastNodeMode() {
        this.modifyLastNodeMode = !this.modifyLastNodeMode;
    }

    /**
     * Vérifie si le poteau est du type start ou angle
     * @param {Wall} wall
     * @param {SimpleCorner|Corner} corner
     * @param {array} fenceTypeCodeList
     * @returns {boolean}
     */
    isCornerAngle(wall, corner, fenceTypeCodeList) {
        let isAngle = false;
        corner.wallStarts.forEach((item) => {
            if (item !== wall && fenceTypeCodeList.includes(item.fenceTypeCode) && item.visible) {
                isAngle = true;
            }
        })
        corner.wallEnds.forEach((item) => {
            if (item !== wall && fenceTypeCodeList.includes(item.fenceTypeCode) && item.visible) {
                isAngle = true;
            }
        })
        return isAngle;
    }

    /**
     * Retourne l'angle d'orientation d'un poteau type angle lié à plusieurs segments
     * @param {Corner|SimpleCorner} simpleCorner
     * @returns {number}
     */
    calculateAnyWallsCornerOrientationAngle(simpleCorner) {
        let countWall = 0;
        let angleTotal = 0;
        let result = 0;
        simpleCorner.wallStarts.forEach((itemWall) => {
            angleTotal += -Math.atan2(itemWall.wallDirectionNormalized().x, itemWall.wallDirectionNormalized().y)
            countWall++;
        })
        simpleCorner.wallEnds.forEach((itemWall) => {
            angleTotal += -Math.atan2(itemWall.wallDirectionNormalized().x, itemWall.wallDirectionNormalized().y)
            countWall++;
        })

        if (countWall > 0) {
            result = angleTotal / countWall;
        }
        return result;
    }

    /** ******************************************************************************************/

    /**                                 FONCTIONS EVENT                                         **/
    /** ******************************************************************************************/

    __createNewMainCorner(e) {
        let co = e.detail.event.data.getLocalPosition(this.drawManager.blueprint3d.floorplanner.__floorplanContainer);
        let cmCo = this.createMainCornerCoToCm(co);
        this.drawManager.fenceElementManager.createMainCornerSection(cmCo, this.drawManager.fenceElementManager.getFenceElementSelected());
        this.drawManager.configuratorStarter.drawPanel.toggleAddCornerButton(true);
    }

    __dragStart(e) {
        this.isLastNodeSeleceted = this.setIsLastNodeSeleceted(e);
        if (this.modifyLastNodeMode && !this.isLastNodeSeleceted) {
            this.setNewLastNodeSelected(e);
            this.isLastNodeSeleceted = false;
            this.toggleModifyLastNodeMode();
        }
    }

    __dragMove(e) {
        if (e.item.__isDragging === true) {
            this.drawManager.sealingManager.sealings.forEach((sealing) => {
                sealing.visible = false;
            })
            this.drawManager.dimensionManager.resetDimension(this.drawManager.sealingManager.sealingDims);
            let corner2D = e.item;
            let corner = e.item.__corner;
            this.drawManager.intermediateCorner2D.hideSimpleCornersBoundToCornerMainWalls(corner);
            this.drawManager.drawWall2D.grugeVisible = false;
            if (this.cornerWallsHasOpening(corner)) {
                corner2D.__isDragging = false;
                corner2D.selected = false;
                alert("Pour déplacer ce poteau, il faut au préalable supprimer les ouvertures des segments connexes.");
            }
        }
    }

    __dragEnd(e) {
        e.item.__corner.mergeWithIntersected();
        this.setNewLastNodeSelected(e);
        this.drawManager.drawFence();
    }

    __manageIntersect(e) {
        this.drawManager.fenceElementManager.manageIntersect(e.detail.movedCorner, e.detail.fixedCorner);
    }
}
