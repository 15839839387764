import {Graphics} from "pixi.js";
import {Environment} from "../environment/Environment";


export class FakeWall2D {

    constructor(options) {
        this.overEnd = false;
        this.overStart = false;
        this.opts = {
            normalizeVector: null,
            posX: null,
            posY: null,
            width: null,
            height: null,
            color: 0x000000,
            opacity: 1,
            leafGate: null,
            reverse: false,
            pivotLeft: false
        };
        for (let opt in this.opts) {
            if (this.opts.hasOwnProperty(opt) && options.hasOwnProperty(opt)) {
                this.opts[opt] = options[opt];
            }
        }
    }

    /**
     * Création d'un grugeur pour le déplacement d'une ouverture
     * @returns {Graphics}
     */
    drawFakeWall() {
        let grugeBox = new Graphics();
        grugeBox.beginFill(0xFFFFFF, this.opts.opacity);
        // Configure le rectangle en fonction du type de portail
        grugeBox = this.configureRectangle(grugeBox, this.opts.leafGate);
        grugeBox.endFill();
        grugeBox.interactive = true;
        grugeBox.tint = this.opts.color;
        // on place le centre de rotation sur le barycentre
        this.setBarycenter(grugeBox, this.opts.posX, this.opts.posY, this.opts.width);
        // rotation en fonction de l'orientation du mur
        grugeBox.rotation = Math.atan(this.opts.normalizeVector.y / this.opts.normalizeVector.x);
        // Gère le choix de la disposition du portail (intérieur/extérieur ; gauche/droite)
        if (this.opts.leafGate === 21 || this.opts.leafGate === 22 || this.opts.leafGate === 1) {
            grugeBox = this.configurePortalOrientation(grugeBox);
        }
        return grugeBox;
    }

    /**
     * Configure le rectangle en fonction du type de portail
     * @param {Graphics} grugeBox
     * @param {number|null} type
     * @returns {Graphics}
     */
    configureRectangle(grugeBox, type) {
        // cas glissière
        if (type === 32) {
            let x;
            let offset = this.opts.width/2;
            if(this.opts.normalizeVector.x < 0){
                x = this.opts.posX + offset;
                if(this.opts.reverse){
                    x = x - offset;
                }
            } else {
                x = this.opts.posX;
                if(this.opts.reverse){
                    x = x + offset;
                }
            }
            grugeBox.drawRect(x, this.opts.posY - this.opts.height / 2, this.opts.width - offset, this.opts.height);
        }
        // cas portillon ou mur droit
        if (type === null || type === 1 || type === 31) {
            grugeBox.drawRect(this.opts.posX, this.opts.posY - this.opts.height / 2, this.opts.width, this.opts.height);
        }
        // cas portail porte 1
        if (type === 21) {
            grugeBox.drawRect(this.opts.posX, this.opts.posY - this.opts.height / 2, this.opts.width / 2, this.opts.height);
        }
        // cas portail porte 2
        if (type === 22) {
            grugeBox.drawRect(this.opts.posX + this.opts.width / 2, this.opts.posY - this.opts.height / 2, this.opts.width / 2, this.opts.height);
        }
        return grugeBox;
    }

    /**
     * Configuration de l'orientation du portail (intérieur/extérieur ; gauche/droite)
     * @param {Graphics} grugeBox
     * @returns {Graphics}
     */
    configurePortalOrientation(grugeBox){
        const signPivot = this.opts.leafGate === 21 || this.opts.pivotLeft ? 1 : -1;
        grugeBox.pivot.x = grugeBox.pivot.x - signPivot * this.opts.width / 2;
        grugeBox.position.x = grugeBox.position.x - signPivot * (this.opts.width / 2) * this.opts.normalizeVector.x;
        grugeBox.position.y = grugeBox.position.y - signPivot * (this.opts.width / 2) * this.opts.normalizeVector.y;
        let angleLeafGate = Environment.drawConfiguration.wall.fakeWall.anglePortalOpening;
        if (this.opts.normalizeVector.x < 0) {
            angleLeafGate = Environment.drawConfiguration.wall.fakeWall.anglePortalOpening + Math.PI;
        }
        const signReverse = this.opts.reverse ? -1 : 1
        grugeBox.rotation = grugeBox.rotation + signReverse * signPivot * angleLeafGate;
        return grugeBox;
    }

    /**
     * Configure le barycentre d'un rectangle
     * @param {Graphics} rectangle
     * @param {number} posX in pixel
     * @param {number} posY in pixel
     * @param {number} width in pixel
     */
    setBarycenter(rectangle, posX, posY, width) {

        rectangle.pivot.x = posX + width / 2;
        rectangle.pivot.y = posY;
        rectangle.position.x = posX;
        rectangle.position.y = posY;
    }
}
