import {DrawManager} from "../manager/DrawManager";
import {Environment} from "../environment/Environment";
import {QuickSettingsHelper} from "./QuickSettingsHelper";

export class PortalPanel {

    /**
     * @param quickSettings
     * @param {DrawManager} drawManager
     */
    constructor(quickSettings, drawManager) {
        this.app_parent = document.getElementById('bp3d-js-app');
        this.openingSelected = null;
        this.quickSettings = quickSettings;
        this.drawManager = drawManager;
        this.settingPanel = null;
        this.__openPanelEvent = this.__openPanel.bind(this);
    }

    /**
     * Création du panneau settings de départ
     * @param {object} e
     * @param {number} x
     * @param {number} y
     */
    createStartControl(e, x = null, y = null) {
        const width = 350
        let title = Environment.portalPanelEnv.title.portal
        if (this.openingSelected.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            title = Environment.portalPanelEnv.title.slide
        }
        this.settingPanel = this.quickSettings.create(
            x !== null ? x : window.innerWidth - width - 50,
            y !== null ? y : window.innerWidth / 2 - width * 2,
            title,
            this.app_parent
        );
        this.settingPanel.setWidth(width);
        this.quickSettingsHelper = new QuickSettingsHelper(this.settingPanel);
    }

    /**
     * Ouverture de la fenêtre de configuration d'un segment
     * @param {object} e
     * @param {number} x
     * @param {number} y
     */
    __openPanel(e, x = null, y = null) {
        this.drawManager.configuratorStarter.openingClicked = true;
        if (e.detail && e.detail.item) {
            this.openingSelected = e.detail.item;
        }
        // Si une fenêtre existe déjà on la détruit
        if (this.settingPanel) {
            this.__closePanel();
        }
        // Création de base de la nouvelle fenêtre
        this.createStartControl(e, x, y);
        // glissière
        if (this.openingSelected.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            // Ajout du formulaire au panel
            this.addSlideForm()
        }
        // portail
        if (this.openingSelected.typeOpening === Environment.drawConfiguration.opening.type.portal) {
            // TODO METTRE dans une fonction la gestion du filet et le terminer
            // Si type pare-ballons on gère l'ajout d'un filet au dessus du portail
            if(this.openingSelected.wallStart.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main){
                // les poteaux extérieures doivent être de la même hauteur
                if(
                    this.openingSelected.wallStart.cornerHeightType !== null &&
                    this.openingSelected.wallStart.cornerHeightType === this.openingSelected.wallEnd.cornerHeightType &&
                    this.openingSelected.wallStart.netProductChoice === this.openingSelected.wallEnd.netProductChoice
                ) {
                    this.openingSelected.wallPortal.cornerHeightType = this.openingSelected.wallStart.cornerHeightType;
                    this.openingSelected.wallPortal.netProductChoice = this.openingSelected.wallStart.netProductChoice;
                    this.drawManager.wallConfigManager.setConfigCornerHeightType(this.openingSelected.wallPortal);
                    this.drawManager.wallConfigManager.setConfigNetProductChoice(this.openingSelected.wallPortal);
                    this.drawManager.wallConfigManager.setConfigNetStiffener(this.openingSelected.wallPortal);
                    this.settingPanel.addBoolean('filet au dessus du portail',this.openingSelected.wallPortal.netAbovePortal, this.setNetAbove.bind(this));
                } else {
                    this.settingPanel.addBoolean('les poteaux autour du portail ne sont pas de la même hauteur, leur hauteur n\'est pas encore déterminés et les filets connexe doivent être identiques', null);
                }
            }
            // Ajout du formulaire au panel
            this.addPortalForm();
            // on passe les options selectionnées si elles existent
            if (this.openingSelected.portal !== null) {
                this.fillingPanelForm();
            }
        }
        this.togglePositionDeleteButton();
        this.togglePositionCloseButton();
    }

    setNetAbove(e){
        const wallPortal = this.openingSelected.wallPortal;
        const wallStart = this.openingSelected.wallStart;
        const wallEnd = this.openingSelected.wallEnd;
        const taxonCodeNetFilling = Environment.dataEcommerceEnv.pareBallon.taxonCode.netFilling;
        wallPortal.netAbovePortal = e;
        wallPortal.sameNetIsPossible = true;
        if((wallStart.fillingTypeCode === taxonCodeNetFilling && wallEnd.fillingTypeCode === taxonCodeNetFilling) ||
            wallStart.fillingTypeCode !== wallEnd.fillingTypeCode
        ){
            wallPortal.sameNetIsPossible = false;
        }
        this.drawManager.wallConfigManager.setNetAbovePortal(this.openingSelected.wallPortal);
        this.drawManager.wallConfigManager.setSameNetIsPossible(this.openingSelected.wallPortal);
        this.drawManager.drawFence();
    }

    togglePositionDeleteButton(){
        this.settingPanel.removeControl(Environment.drawPanelEnv.button.deleteOpening);
        this.settingPanel.addButton(Environment.drawPanelEnv.button.deleteOpening, this.deleteOpening.bind(this));
    }
    togglePositionCloseButton(){
        this.settingPanel.removeControl(Environment.wallPanelEnv.button.closePanel);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.closePanel, this.__closePanel.bind(this));
    }
    remove() {
        this.settingPanel.destroy();
    }
    __closePanel() {
        if (this.settingPanel !== null) {
            this.settingPanel._content.parentElement.remove();
            this.settingPanel = null;
        }
    }
    updatePanel() {
        let fakeEvent = {
            detail: {
                item: this.openingSelected
            }
        }
        const x = this.settingPanel._panel.offsetLeft;
        const y = this.settingPanel._panel.offsetTop;
        this.drawManager.drawFence();
        this.__openPanel(fakeEvent, x, y);
    }
    deleteOpening() {
        this.drawManager.openingManager.opening2D.deleteOpening(
            this.drawManager.fenceElementManager.getFenceElementByName(this.openingSelected.belongToFenceElement),
            this.openingSelected.belongToMainCornerSection,
            this.openingSelected
        );
        this.__closePanel();
    }
    reversePortal(e) {
        this.drawManager.openingManager.openingConfigData.reverseOpening(this.openingSelected, e);
        this.drawManager.openingManager.openingConfigData.updateDataOpenings(
            this.openingSelected.belongToMainCornerSection,
            this.drawManager.fenceElementManager.getFenceElementByName(this.openingSelected.belongToFenceElement)
        )
        this.updatePanel();
    }
    reversePivotPortal(e) {
        this.drawManager.openingManager.openingConfigData.reversePivotOpening(this.openingSelected, e);
        this.drawManager.drawFence();
    }

    /** *******************************************************************/
    /** *********************     SLIDE     *******************************/
    /** *******************************************************************/

    addSlideForm() {
        this.quickSettingsHelper.addSectionTitle(Environment.portalPanelEnv.subtitleHandrailSlide.id, Environment.portalPanelEnv.subtitleHandrailSlide.text);
        this.settingPanel.addBoolean(Environment.portalPanelEnv.inputReverseOpening.titleInfoText, this.openingSelected.reverse, this.reversePortal.bind(this));
        this.quickSettingsHelper.addInfoText(
            Environment.wallPanelEnv.displayInfo.slideLength.title,
            Environment.wallPanelEnv.displayInfo.slideLength.text(this.openingSelected.wallPortalFixed.railLength / 1000)
        );
        // taille de la glissière
        this.addInputSlideLength();
        const fillingTypeNamesAuthorized = this.getFillingTypeNamesAuthorized();
        if (fillingTypeNamesAuthorized === null) {
            this.quickSettingsHelper.addInfoText(Environment.wallPanelEnv.defaultInfo.noFillingCombination.title, Environment.wallPanelEnv.defaultInfo.noFillingCombination.text);
            return;
        }
        this.addInputFillingType(fillingTypeNamesAuthorized);
    }

    /**
     * Gestion du select choix type remplissage et ajout du/des select choix du filet,panneau et/ou grillage torsadé
     */
    __setWallFillingSlideConfig(isCallBack, e) {
        if (isCallBack) {
            this.__removeWallConfigInput(this.openingSelected.wallPortalFixed)
        }
        this.openingSelected.wallPortalFixed.fillingTypeName = e.value;
        this.openingSelected.wallPortal.fillingTypeName = e.value;

        this.drawManager.wallConfigManager.setConfigFillingTypeName(this.openingSelected.wallPortalFixed);
        this.drawManager.wallConfigManager.setConfigFillingTypeName(this.openingSelected.wallPortal);
        const fillingTypeInfo = this.drawManager.jsonDataManager.getFillingTypeInfoByName(this.openingSelected.wallPortalFixed);

        if (fillingTypeInfo) {
            this.updateInfosConfig(fillingTypeInfo);
            this.handleFillingForm(fillingTypeInfo)
        } else {
            this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.openingSelected.wallPortalFixed)
            this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.openingSelected.wallPortal)
        }

        this.togglePositionDeleteButton();
        this.togglePositionCloseButton();
        this.drawManager.drawFence();
    }

    __setWallFillingSlideConfigPhase2(type, e) {
        if (type === Environment.portalPanelEnv.inputHandRailRailLength.type) {
            let value = e.value * 1000;
            this.drawManager.wallConfigManager.setConfigRailLength(this.openingSelected.wallPortalFixed, value);
            const technicalInfoHandRail = this.drawManager.jsonDataManager.getTechnicalInfoHandRail(this.openingSelected.wallPortalFixed.fillingTypeCode, this.openingSelected.wallPortalFixed.railLength);
            this.openingSelected.wallPortalFixed.centerDistance = technicalInfoHandRail.centerDistance / 10;
            this.quickSettingsHelper.updateInfoText(
                Environment.wallPanelEnv.displayInfo.slideLength.title,
                Environment.wallPanelEnv.displayInfo.slideLength.text(this.openingSelected.wallPortalFixed.railLength / 1000)
            );
            this.drawManager.openingManager.replaceOpeningAtStart(this.openingSelected);
            this.drawManager.openingManager.openingConfigData.updateDataOpenings(
                this.openingSelected.belongToMainCornerSection,
                this.drawManager.fenceElementManager.getFenceElementByName(this.openingSelected.belongToFenceElement)
            )
        }
        if (type === Environment.wallPanelEnv.type.panel) {
            this.openingSelected.wallPortalFixed.panelType = e.value;
            this.drawManager.wallConfigManager.setConfigPanelType(this.openingSelected.wallPortalFixed)
        }
        if (type === Environment.wallPanelEnv.type.fenceProduct) {
            this.openingSelected.wallPortalFixed.fenceProductType = e.value;
            this.drawManager.wallConfigManager.setConfigFenceProductType(this.openingSelected.wallPortalFixed)
        }
        this.updatePanel();
    }

    /**
     * Mise à jour des éléments après le choix du type de remplissage
     * @param {object} fillingTypeInfo
     */
    updateInfosConfig(fillingTypeInfo) {
        // Mise à jour du champ entraxe dans le panneau dans le cas d'une main courantes avec remplissage
        if (
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.panelFilling ||
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling
        ) {
            this.openingSelected.wallPortalFixed.railLength = Environment.drawConfiguration.opening.defaultDimension.handRailRailLength;
            this.drawManager.wallConfigManager.setConfigRailLength(this.openingSelected.wallPortalFixed, this.openingSelected.wallPortalFixed.railLength);
            this.openingSelected.wallPortalFixed.centerDistance = Environment.drawConfiguration.opening.defaultDimension.handRailCenterDistance;
        }
        // Mise à jour des infos de remplissage du segment
        this.openingSelected.wallPortalFixed.fillingTypeCode = fillingTypeInfo.code;
        this.openingSelected.wallPortal.fillingTypeCode = fillingTypeInfo.code;
        this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.openingSelected.wallPortalFixed)
        // this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.openingSelected.wallPortal)
    }

    /**
     * Ajout du formulaire avec la liste des produits de remplissage ou la longueur de lisse
     * @param {object} fillingTypeInfo
     */
    handleFillingForm(fillingTypeInfo) {
        if (fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.noFilling) {
            this.addInputRailLength();
        }
        if (fillingTypeInfo.fences.length > 0) {
            this.addInputFence(fillingTypeInfo);
        }
        if (fillingTypeInfo.panels.length > 0) {
            this.addInputPanel(fillingTypeInfo);
        }
    }

    setSlide(e) {
        this.openingSelected.wallPortal.centerDistance = e.value * 100;
        this.drawManager.wallConfigManager.setConfigSlideLength(this.openingSelected.wallPortal, e.value * 100);
        this.drawManager.openingManager.replaceOpeningAtStart(this.openingSelected);
        this.drawManager.openingManager.openingConfigData.updateDataOpenings(
            this.openingSelected.belongToMainCornerSection,
            this.drawManager.fenceElementManager.getFenceElementByName(this.openingSelected.belongToFenceElement)
        );
        this.updatePanel();
    }

    addInputSlideLength() {
        const slideLengthUnAndAvailanble = this.getSlideLengthUnAndAvailable()
        if (slideLengthUnAndAvailanble[0].length > 0) {
            this.quickSettingsHelper.addInfoText(
                Environment.portalPanelEnv.defaultInputMessage.slideLength,
                Environment.portalPanelEnv.defaultInputMessage.slideLength
            );
            // sélection de la taille d'ouverture d'un portail type glissière
            this.settingPanel.addDropDown(
                Environment.portalPanelEnv.inputHandrailSlide.titleInput,
                slideLengthUnAndAvailanble[0],
                this.setSlide.bind(this)
            )
        }
        if (slideLengthUnAndAvailanble[1].length > 0) {
            this.quickSettingsHelper.addInfoText(
                Environment.portalPanelEnv.defaultInputMessage.slideLengthUnavailable,
                Environment.portalPanelEnv.defaultInputMessage.slideLengthUnavailable
            );
            // sélection de la taille d'ouverture d'un portail type glissière
            this.settingPanel.addDropDown(
                Environment.portalPanelEnv.inputHandrailSlideUnavailable.titleInput,
                slideLengthUnAndAvailanble[1],
                null
            )
        }
        // update du champ avec la valeur courante
        if (this.settingPanel._controls[Environment.portalPanelEnv.inputHandrailSlide.titleInput]) {
            this.settingPanel._controls[Environment.portalPanelEnv.inputHandrailSlide.titleInput].control.value = this.openingSelected.wallPortal.centerDistance / 100;
        }
    }

    addInputRailLength(){
        const railLengthUnAndAvailable = this.getRailLengthUnAndAvailable();
        if (railLengthUnAndAvailable[0].length > 0) {
            this.quickSettingsHelper.addInfoText(
                Environment.wallPanelEnv.defaultInputMessage.RailLengthAvailable,
                Environment.wallPanelEnv.defaultInputMessage.RailLengthAvailable
            );
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.railLength,
                railLengthUnAndAvailable[0],
                this.__setWallFillingSlideConfigPhase2.bind(this, Environment.portalPanelEnv.inputHandRailRailLength.type)
            );
        }
        if (railLengthUnAndAvailable[1].length > 0) {
            this.quickSettingsHelper.addInfoText(
                Environment.wallPanelEnv.defaultInputMessage.RailLengthUnavailable,
                Environment.wallPanelEnv.defaultInputMessage.RailLengthUnavailable
            );
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.railLengthUnavailable,
                railLengthUnAndAvailable[1],
                null
            );
        }
        // on passe les options selectionnées si elles existent
        if (this.settingPanel._controls[Environment.wallPanelEnv.titleInput.railLength]) {
            let railLength = this.openingSelected.wallPortalFixed.railLength === null ?
                Environment.drawConfiguration.opening.defaultDimension.handRailRailLength :
                this.openingSelected.wallPortalFixed.railLength;
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.railLength].control.value = railLength / 1000;
        }
    }

    addInputFence(fillingTypeInfo){
        this.settingPanel.addDropDown(
            Environment.wallPanelEnv.titleInput.fenceProductType,
            this.drawManager.jsonDataManager.getFencesNames(fillingTypeInfo.fences),
            this.__setWallFillingSlideConfigPhase2.bind(this, Environment.wallPanelEnv.type.fenceProduct)
        );
        // on passe les options selectionnées si elles existent
        if (this.openingSelected.wallPortalFixed.fenceProductType !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fenceProductType].control.value = this.openingSelected.wallPortalFixed.fenceProductType;
        }
    }
    addInputPanel(fillingTypeInfo){
        this.settingPanel.addDropDown(
            Environment.wallPanelEnv.titleInput.panelType,
            this.drawManager.jsonDataManager.getPanelsNames(fillingTypeInfo.panels),
            this.__setWallFillingSlideConfigPhase2.bind(this, Environment.wallPanelEnv.type.panel)
        );
        // on passe les options selectionnées si elles existent
        if (this.openingSelected.wallPortalFixed.panelType !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.panelType].control.value = this.openingSelected.wallPortalFixed.panelType;
        }
    }

    getFillingTypeNamesAuthorized() {
        const fenceTypeInfo = this.drawManager.jsonDataManager.getFenceTypeInfoByName(this.openingSelected.wallPortalFixed.fenceTypeName);
        let fillingTypeNamesAuthorized = [];
        if (this.openingSelected.reverse) {
            fillingTypeNamesAuthorized = this.drawManager.drawWall2D.getFillingTypeNamesAuthorizedCombinationForBothCorner(
                fenceTypeInfo.fillingType,
                this.openingSelected.wallPortalFixed,
                [],
                [...this.openingSelected.wallPortal.end.wallStarts, ...this.openingSelected.wallPortal.end.wallEnds]
            );
        }
        if (!this.openingSelected.reverse) {
            fillingTypeNamesAuthorized = this.drawManager.drawWall2D.getFillingTypeNamesAuthorizedCombinationForBothCorner(
                fenceTypeInfo.fillingType,
                this.openingSelected.wallPortalFixed,
                [...this.openingSelected.wallPortalFixed.start.wallStarts, ...this.openingSelected.wallPortalFixed.start.wallEnds],
                []
            );
        }
        return fillingTypeNamesAuthorized
    }

    addInputFillingType(fillingTypeNamesAuthorized){
        this.quickSettingsHelper.addInfoText(
            Environment.wallPanelEnv.defaultInputMessage.FillingType,
            Environment.wallPanelEnv.defaultInputMessage.FillingType
        );
        // Ajout de l'input type de remplissage
        this.settingPanel.addDropDown(
            Environment.wallPanelEnv.titleInput.fillingType,
            fillingTypeNamesAuthorized,
            this.__setWallFillingSlideConfig.bind(this, true)
        );
        // on passe les options selectionnées si elles existent
        if (this.openingSelected.wallPortalFixed.fillingTypeName !== null) {
            let ec = {
                value: this.openingSelected.wallPortalFixed.fillingTypeName
            }
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fillingType].control.value = this.openingSelected.wallPortalFixed.fillingTypeName;
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fillingType].control.value = this.openingSelected.wallPortal.fillingTypeName;
            this.__setWallFillingSlideConfig(false, ec);
        }
    }

    getSlideLengthUnAndAvailable() {

        let lengthAvailable = new Array(...Environment.dataEcommerceEnv.handRail.slideLengthAvailable);
        let lengthUnavailable = [];
        const lengthWallBase = this.openingSelected.wallStart.wallLength() + this.openingSelected.wallEnd.wallLength() + this.openingSelected.wallPortalFixed.centerDistance + this.openingSelected.wallPortal.centerDistance
        Environment.dataEcommerceEnv.handRail.slideLengthAvailable.forEach((value) => {
            const slideNeedSpace = this.openingSelected.wallPortalFixed.centerDistance + value * 100 + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2;
            if (slideNeedSpace > lengthWallBase) {
                lengthAvailable.splice(lengthAvailable.indexOf(value), 1);
                lengthUnavailable.push(value)
            }
        })
        return [lengthAvailable, lengthUnavailable];
    }

    getRailLengthUnAndAvailable() {

        let railLengthAvailable = new Array(...Environment.dataEcommerceEnv.handRail.railLengthAvailable);
        let railLengthUnavailable = [];
        const lengthWallBase = this.openingSelected.wallStart.wallLength() + this.openingSelected.wallEnd.wallLength() + this.openingSelected.wallPortalFixed.centerDistance + this.openingSelected.wallPortal.centerDistance
        Environment.dataEcommerceEnv.handRail.railLengthAvailable.forEach((value) => {
            const slideNeedSpace = this.openingSelected.wallPortal.centerDistance + value * 50 + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2;
            if (slideNeedSpace > lengthWallBase) {
                railLengthAvailable.splice(railLengthAvailable.indexOf(value), 1);
                railLengthUnavailable.push(value)
            }
        })
        return [railLengthAvailable, railLengthUnavailable];
    }

    __removeWallConfigInput(wall) {
        this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.fenceProductType)
        this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.panelType)
        this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.railLength)
        this.settingPanel.removeControl(Environment.wallPanelEnv.defaultInputMessage.RailLengthAvailable)
        this.settingPanel.removeControl(Environment.wallPanelEnv.defaultInputMessage.RailLengthUnavailable)
        wall.fenceProductType = null;
        wall.panelType = null;
        wall.railLength = null;
        this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductType = null;
        this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelType = null;
        this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].railLength = null;

    }

    /** *******************************************************************/
    /** *********************     PORTAL     *******************************/
    /** *******************************************************************/

    /**
     * Action après le choix d'un portail
     * @param type
     * @param e
     * @param isUpdate
     */
    setPortal(type, e, isUpdate = false) {
        // initialisation des input
        if (e.value === Environment.portalPanelEnv.defaultInputMessage.wickets || e.value === Environment.portalPanelEnv.defaultInputMessage.portals) {
            type = null;
            this.openingSelected.portal = null;
            this.__openPanel(
                e,
                this.settingPanel._panel.style.left.substring(0, this.settingPanel._panel.style.left.length - 2),
                this.settingPanel._panel.style.top.substring(0, this.settingPanel._panel.style.top.length - 2)
            );
            this.drawManager.openingManager.openingConfigData.setOpeningConfigData(this.openingSelected, type);
            this.drawManager.drawFence();
            return;
        }
        this.removeUselessDropDown(type);
        // si les controls addBoolean n'existe pas on les crée
        if (!this.settingPanel._controls[Environment.portalPanelEnv.inputReverseOpening.titleInfoText]) {
            if (type !== null) {
                this.settingPanel.addBoolean(Environment.portalPanelEnv.inputReverseOpening.titleInfoText, this.openingSelected.reverse, this.reversePortal.bind(this));
            }
            if (type === Environment.portalPanelEnv.inputHandrailWicket.type || type === Environment.portalPanelEnv.inputFence2mWicket.type) {
                this.settingPanel.addBoolean(Environment.portalPanelEnv.inputReversePivotOpening.titleInfoText, this.openingSelected.pivotLeft, this.reversePivotPortal.bind(this));
            }
        }
        this.openingSelected.portal = this.drawManager.portalManager.getPortalByName(type, e.value);
        if (!isUpdate) {
            this.drawManager.openingManager.openingConfigData.setOpeningConfigData(this.openingSelected, type);

        }
        this.drawManager.drawFence();
    }

    /**
     *
     * @param {number} wallLength
     * @param {string[]} portalList
     * @param {object} inputPortalInfo
     */
    handleTypePortalForm(wallLength, portalList, inputPortalInfo) {
        const adaptiveAndNotAdaptiveListnamesPortalHandRail = this.drawManager.portalManager.getAdaptiveAndNotAdaptiveListPortalName(
            portalList,
            inputPortalInfo.type,
            wallLength,
            this.openingSelected
        );
        if (adaptiveAndNotAdaptiveListnamesPortalHandRail[0].length > 1) {
            this.quickSettingsHelper.addInfoText(
                inputPortalInfo.titleInfoText,
                inputPortalInfo.adaptivePortalMessage
            );
            this.settingPanel.addDropDown(
                inputPortalInfo.titleInputAdaptive,
                adaptiveAndNotAdaptiveListnamesPortalHandRail[0],
                this.setPortal.bind(this, inputPortalInfo.type)
            );
        }

        if (adaptiveAndNotAdaptiveListnamesPortalHandRail[1].length > 1) {
            this.quickSettingsHelper.addInfoText(
                inputPortalInfo.titleNoAdaptiveInfoText,
                inputPortalInfo.noAdaptivePortalMessage
            );
            this.quickSettingsHelper.changeBackgroundColorInfoText(inputPortalInfo.titleNoAdaptiveInfoText, Environment.portalPanelEnv.defaultInfoText.color.noAdaptive);
            this.settingPanel.addDropDown(
                inputPortalInfo.titleInputNoAdaptive,
                adaptiveAndNotAdaptiveListnamesPortalHandRail[1],
                null
            );
        }
    }

    /**
     * Ajoute le formulaire de base au panneau configution ouverture
     */
    addPortalForm() {
        const currentOpeningSizeTotal = this.drawManager.openingManager.openingConfigData.getOpeningSizeTotal(this.openingSelected);
        const wallLength = Math.round(this.openingSelected.wallStart.wallLength()) + Math.round(this.openingSelected.wallEnd.wallLength()) + currentOpeningSizeTotal;

        /** Partie du formulaire portail mains courantes */
        this.quickSettingsHelper.addSectionTitle(Environment.portalPanelEnv.subtitleHandRail.id, Environment.portalPanelEnv.subtitleHandRail.text);
        // gestion formulaire mains courantes portail
        this.handleTypePortalForm(
            wallLength,
            this.drawManager.portalManager.namesPortalHandRail,
            Environment.portalPanelEnv.inputHandrailPortal,
        );
        // gestion formulaire mains courantes portillon
        this.handleTypePortalForm(
            wallLength,
            this.drawManager.portalManager.namesWicketHandRail,
            Environment.portalPanelEnv.inputHandrailWicket,
        );
        /** Partie du formulaire portail clôture 2m*/
        this.quickSettingsHelper.addSectionTitle(Environment.portalPanelEnv.subtitleFence2m.id, Environment.portalPanelEnv.subtitleFence2m.text);
        // gestion formulaire clotures 2m portail
        this.handleTypePortalForm(
            wallLength,
            this.drawManager.portalManager.namesPortalCloture2m,
            Environment.portalPanelEnv.inputFence2mPortal
        );
        // gestion formulaire cloture 2m wicket
        this.handleTypePortalForm(
            wallLength,
            this.drawManager.portalManager.namesWicketCloture2m,
            Environment.portalPanelEnv.inputFence2mWicket
        );
    }

    /**
     * Remplit le formulaire du panneau de configuration des ouvertures
     */
    fillingPanelForm() {
        let ec = {
            value: this.openingSelected.portal.translations.fr_FR.name
        }
        let controlTitle = null;
        let currentNamesAndCodesList = [];
        let currentIndex = 0;
        if (this.openingSelected.portalType === Environment.portalPanelEnv.inputHandrailPortal.type) {
            controlTitle = Environment.portalPanelEnv.inputHandrailPortal.titleInputAdaptive;
            currentNamesAndCodesList = this.drawManager.portalManager.namesPortalHandRail;
        }
        if (this.openingSelected.portalType === Environment.portalPanelEnv.inputHandrailWicket.type) {
            controlTitle = Environment.portalPanelEnv.inputHandrailWicket.titleInputAdaptive;
            currentNamesAndCodesList = this.drawManager.portalManager.namesWicketHandRail;
        }
        if (this.openingSelected.portalType === Environment.portalPanelEnv.inputFence2mPortal.type) {
            controlTitle = Environment.portalPanelEnv.inputFence2mPortal.titleInputAdaptive;
            currentNamesAndCodesList = this.drawManager.portalManager.namesPortalCloture2m;
        }
        if (this.openingSelected.portalType === Environment.portalPanelEnv.inputFence2mWicket.type) {
            controlTitle = Environment.portalPanelEnv.inputFence2mWicket.titleInputAdaptive;
            currentNamesAndCodesList = this.drawManager.portalManager.namesWicketCloture2m;
        }

        currentNamesAndCodesList.forEach((value, index) => {
            if (this.openingSelected.portal.translations.fr_FR.name === value) {
                currentIndex = index;
            }
        });
        this.settingPanel._controls[controlTitle].control.options.selectedIndex = currentIndex
        // Comme le champ potail n'est pas null, on l'active
        this.setPortal(this.openingSelected.portalType, ec, true);
    }

    /**
     * Supprime les éléments du panel devenus inutiles
     * @param {string} type
     */
    removeUselessDropDown(type) {

        const inputsListInfo = [
            Environment.portalPanelEnv.inputHandrailPortal,
            Environment.portalPanelEnv.inputHandrailWicket,
            Environment.portalPanelEnv.inputFence2mPortal,
            Environment.portalPanelEnv.inputFence2mWicket
        ]
        // Suppression des inputs et title inutiles et lancement des mises à jour de l'élément sélectionné
        inputsListInfo.forEach((inputListInfo) => {
            if (type !== inputListInfo.type) {
                this.settingPanel.removeControl(inputListInfo.titleInputAdaptive);
                document.querySelector("#" + inputListInfo.titleInfoText)?.remove();
            }
        })
        if (type === inputsListInfo[0].type || type === inputsListInfo[1].type) {
            this.settingPanel.removeControl(Environment.portalPanelEnv.subtitleFence2m.id);
        }
        if (type === inputsListInfo[2].type || type === inputsListInfo[3].type) {
            this.settingPanel.removeControl(Environment.portalPanelEnv.subtitleHandRail.id);
        }

        this.settingPanel.removeControl(Environment.portalPanelEnv.inputFence2mWicket.titleInputNoAdaptive);
        this.settingPanel.removeControl(Environment.portalPanelEnv.inputFence2mPortal.titleInputNoAdaptive);
        this.settingPanel.removeControl(Environment.portalPanelEnv.inputHandrailWicket.titleInputNoAdaptive);
        this.settingPanel.removeControl(Environment.portalPanelEnv.inputHandrailPortal.titleInputNoAdaptive);
        document.querySelector("#" + Environment.portalPanelEnv.inputFence2mWicket.titleNoAdaptiveInfoText)?.remove();
        document.querySelector("#" + Environment.portalPanelEnv.inputFence2mPortal.titleNoAdaptiveInfoText)?.remove();
        document.querySelector("#" + Environment.portalPanelEnv.inputHandrailWicket.titleNoAdaptiveInfoText)?.remove();
        document.querySelector("#" + Environment.portalPanelEnv.inputHandrailPortal.titleNoAdaptiveInfoText)?.remove();

    }
}
