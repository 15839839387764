import {Environment} from "../environment/Environment";
import {Vector2} from "three";

export class IntermediateCorner2D {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.optionsDimension = {
            dimlinecolor: '#084',
            dimarrowcolor: '#FFCCCC',
            dimtextcolor: '#FFAA00',
            dimtextalpha: 1,
            reverse: false,
            offset: Environment.drawConfiguration.main.cotation.step2
        }
        this.drawManager = drawManager;
        this.intermediateDimension = [];
    }

    drawIntermediateCorner(fenceElement, wall) {
        const nbColumnSide = Math.ceil((Math.round(wall.wallLength() * 10) / 10) / wall.centerDistance);
        // la longueur du mur est arrondie au mm près
        // valeur par défaut de la position du dernier poteau intermédiaire, nous permettra de la corriger si trop proche
        const lengthMaxCornerFromWallStart = wall.wallLength() - Environment.drawConfiguration.main.spacingMiniBetween2Corner
        // Configuration des intermediateCorners
        let previousSimpleCorner = null;

        let start = wall.start;
        let end = wall.end;
        this.optionsDimension.reverse = false;
        if (wall.intermediateCornerReverse) {
            this.optionsDimension.reverse = true;
            start = wall.end;
            end = wall.start;
        }
        let lastPositionExtraStrut = 0;
        for (let i = 1; i < nbColumnSide; i++) {
            let simpleCornerVector;
            // Calcul de la position par défaut du poteau
            const currentLengthOfCornerFromWallStart = Math.sqrt(
                Math.pow(wall.wallDirectionNormalized().x * i * wall.centerDistance, 2) +
                Math.pow(wall.wallDirectionNormalized().y * i * wall.centerDistance, 2)
            );
            // gestion cas particuliers du dernier poteau qui doit respecter une espace miminmum spacingMiniBetween2Corner
            if (currentLengthOfCornerFromWallStart > wall.wallLength() - Environment.drawConfiguration.main.spacingMiniBetween2Corner) {
                simpleCornerVector = this.setPositionIntermediateCorner(wall, lengthMaxCornerFromWallStart, wall.intermediateCornerReverse)
            } else {
                simpleCornerVector = this.setPositionIntermediateCorner(wall, i * wall.centerDistance, wall.intermediateCornerReverse);
            }
            let currentSimpleCorner = this.drawManager.getFloorplan().newSimpleCorner(simpleCornerVector.x, simpleCornerVector.y);

            const pareBallon = Environment.dataEcommerceEnv.pareBallon;
            const cloture2m = Environment.dataEcommerceEnv.cloture2m;
            // Vérification si le poteau courant doit être un poteau de rétention.
            // Condition pour mettre un poteau de rétention uniquement sur les poteaux pare-ballons dans le cas d'un remplissage mixte avec les clôtures 2m.
            // Donc, dans le cas d'un pare-ballons mixte, on ignore le poteau de 2 mètres.
            if (
                (wall.fenceTypeCode === pareBallon.taxonCode.main && i % 2 === 0 && (wall.fillingTypeCode === pareBallon.taxonCode.fenceFilling || wall.fillingTypeCode === pareBallon.taxonCode.panelFilling)) ||
                (wall.fenceTypeCode === pareBallon.taxonCode.main && wall.fillingTypeCode === pareBallon.taxonCode.netFilling) ||
                wall.fenceTypeCode === cloture2m.taxonCode.main
            ) {
                currentSimpleCorner.isExtraStrut = this.checkIsExtraStrut(wall, lastPositionExtraStrut, currentLengthOfCornerFromWallStart);
                if (currentSimpleCorner.isExtraStrut) {
                    lastPositionExtraStrut = currentLengthOfCornerFromWallStart / 100;
                }
            }
            this.drawManager.jsonDataManager.setConfigCornerInRelationWall(wall, currentSimpleCorner, i);
            this.drawManager.sealingManager.addSealing(wall, currentSimpleCorner);
            currentSimpleCorner.belongToMainWallKey = wall.belongToMainWallKey;
            fenceElement[wall.belongToMainCornerSection].simpleCornersMainWall.push(currentSimpleCorner);
            // Gestion de l'affichage des cotes des poteaux intermédiaires
            if (this.drawManager.configuratorStarter.drawPanel.displayIntermediateDimension) {
                if (i === 1) {
                    this.drawManager.dimensionManager.addDimension(start, currentSimpleCorner, this.optionsDimension, this.intermediateDimension);
                }
                if (i === nbColumnSide - 1) {
                    this.drawManager.dimensionManager.addDimension(currentSimpleCorner, end, this.optionsDimension, this.intermediateDimension);
                }
                if (i > 1 && i < nbColumnSide) {
                    this.drawManager.dimensionManager.addDimension(previousSimpleCorner, currentSimpleCorner, this.optionsDimension, this.intermediateDimension);
                }
            }
            previousSimpleCorner = currentSimpleCorner;
        }
    }

    /**
     * Vérifie si le poteau courant doit être un poteau de rétention
     * @param {Wall} wall
     * @param {number} lastPositionExtraStrut
     * @param {number} currentLengthOfCornerFromWallStart
     * @returns {boolean}
     */
    checkIsExtraStrut(wall, lastPositionExtraStrut, currentLengthOfCornerFromWallStart) {
        let centerDistance = wall.centerDistance;
        let recommendExtraStrut = Environment.dataEcommerceEnv.cloture2m.recommendExtraStrut;
        if(wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main){
            recommendExtraStrut = Environment.dataEcommerceEnv.pareBallon.recommendExtraStrut;
        }
        // on ajuste l'entraxe dans le cas d'un pare-ballons mixte
        if (
            wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main &&
            (
                wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.fenceFilling ||
                wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.panelFilling
            )
        ) {
            centerDistance *= 2;
        }
        let nextExtraStrutLimit = lastPositionExtraStrut + recommendExtraStrut;
        return ((currentLengthOfCornerFromWallStart + centerDistance) / 100) > nextExtraStrutLimit;
    }

    /**
     * Calcule les coordonnées du poteau intermédiaire sur le segment ciblé
     * @param {Wall} wall
     * @param {Number} posFromWallStart
     * @param {boolean} reverse
     * @returns {Vector2}
     */
    setPositionIntermediateCorner(wall, posFromWallStart, reverse) {
        let simpleCornerX;
        let simpleCornerY;
        if (reverse) {
            simpleCornerX = wall.getEndX() - (wall.wallDirectionNormalized().x * posFromWallStart);
            simpleCornerY = wall.getEndY() - (wall.wallDirectionNormalized().y * posFromWallStart);
        } else {
            simpleCornerX = wall.getStartX() + (wall.wallDirectionNormalized().x * posFromWallStart);
            simpleCornerY = wall.getStartY() + (wall.wallDirectionNormalized().y * posFromWallStart);
        }
        return new Vector2(simpleCornerX, simpleCornerY);
    }

    /**
     * supprime du dessin les poteaux intermédiaires
     * @param {Wall} wall
     */
    hideSimpleCornersMainWall(wall) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(wall.belongToFenceElement);
        let mainCornerSection = wall.belongToMainCornerSection;
        for (let k = 0; k < fenceElement[mainCornerSection].simpleCornersMainWall.length; k++) {
            if (fenceElement[mainCornerSection].simpleCornersMainWall[k].belongToMainWallKey === wall.belongToMainWallKey) {
                fenceElement[mainCornerSection].simpleCornersMainWall[k].simpleCornerView.visible = false;
            }
        }
    }

    /**
     * Supprime les poteaux intermédiaires des murs liés à un poteau principal lors du mouvement
     * @param {Corner} corner
     */
    hideSimpleCornersBoundToCornerMainWalls(corner) {
        this.drawManager.dimensionManager.resetDimension(this.intermediateDimension);
        for (let i = 0; i < corner.wallStarts.length; i++) {
            this.drawManager.intermediateCorner2D.hideSimpleCornersMainWall(corner.wallStarts[i]);
        }
        for (let i = 0; i < corner.wallEnds.length; i++) {
            this.drawManager.intermediateCorner2D.hideSimpleCornersMainWall(corner.wallEnds[i]);
        }
    }
}
