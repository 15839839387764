import {Environment} from "../environment/Environment";

export class PortalManager {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.drawManager = drawManager;
        this.allPortalProductAvailable = this.drawManager.jsonDataManager.getAllPortals();
        this.namesPortalHandRail = this.getNamesAndCodesListsPortalHandRail()[0];
        this.codesPortalHandRail = this.getNamesAndCodesListsPortalHandRail()[1];
        this.namesWicketHandRail = this.getNamesAndCodesListsWicketHandRail()[0];
        this.codesWicketHandRail = this.getNamesAndCodesListsWicketHandRail()[1];
        this.namesPortalCloture2m = this.getNamesAndCodesListsPortalCloture2m()[0];
        this.codesPortalCloture2m = this.getNamesAndCodesListsPortalCloture2m()[1];
        this.namesWicketCloture2m = this.getNamesAndCodesListsWicketCloture2m()[0];
        this.codesWicketCloture2m = this.getNamesAndCodesListsWicketCloture2m()[1];
    }

    /**
     * Retourne le produit portail
     * @param {string} type
     * @param {string} name
     * @returns {object|null}
     */
    getPortalByName(type, name) {
        let currentList = [];
        let portal = null;
        if (type === Environment.portalPanelEnv.inputHandrailPortal.type) {
            currentList = this.codesPortalHandRail;
        }
        if (type === Environment.portalPanelEnv.inputHandrailWicket.type) {
            currentList = this.codesWicketHandRail;
        }
        if (type === Environment.portalPanelEnv.inputFence2mPortal.type) {
            currentList = this.codesPortalCloture2m;
        }
        if (type === Environment.portalPanelEnv.inputFence2mWicket.type) {
            currentList = this.codesWicketCloture2m;
        }
        currentList.forEach((item) => {
            if (item.split('@@')[0] === name) {
                this.allPortalProductAvailable.forEach((obj) => {
                    if (obj.code === item.split('@@')[1]) {
                        portal = obj;
                    }
                })
            }
        })
        return portal;
    }

    /**
     *
     * @param {string[]} portalList
     * @param {string} type
     * @param {Number} wallLength
     * @param {object} openingData
     * @returns {[string[],string[]]}
     */
    getAdaptiveAndNotAdaptiveListPortalName(portalList, type, wallLength,openingData) {
        let portalsNameIsAdaptive = [portalList[0]];
        let portalsNameIsNotAdaptive = [portalList[1]];
            portalList.forEach((name) => {
            const portal = this.getPortalByName(type, name);
            if (portal) {
                let technicalInfo = this.drawManager.jsonDataManager.getTechnicalInfoPortalById(portal.id);
                const openingEstimateNeededSpace = this.drawManager.openingManager.openingConfigData.openingEstimateNeededSpaceMini(
                    openingData,
                    technicalInfo
                );
                if (wallLength - openingEstimateNeededSpace > 0) {
                    portalsNameIsAdaptive.push(name)
                } else {
                    portalsNameIsNotAdaptive.push(name)
                }
            }
        })

        return [portalsNameIsAdaptive, portalsNameIsNotAdaptive];
    }

    getNamesAndCodesListsPortalHandRail() {
        return this.drawManager.jsonDataManager.getPortalsNamesAndCodes(
            Environment.dataEcommerceEnv.handRail.taxonCode.main,
            Environment.portalPanelEnv.portalCodeStartsWith.portal
        )
    }

    getNamesAndCodesListsWicketHandRail() {
        return this.drawManager.jsonDataManager.getPortalsNamesAndCodes(
            Environment.dataEcommerceEnv.handRail.taxonCode.main,
            Environment.portalPanelEnv.portalCodeStartsWith.wicket
        );
    }

    getNamesAndCodesListsPortalCloture2m() {
        return this.drawManager.jsonDataManager.getPortalsNamesAndCodes(
            Environment.dataEcommerceEnv.cloture2m.taxonCode.main,
            Environment.portalPanelEnv.portalCodeStartsWith.portal
        );
    }

    getNamesAndCodesListsWicketCloture2m() {
        return this.drawManager.jsonDataManager.getPortalsNamesAndCodes(
            Environment.dataEcommerceEnv.cloture2m.taxonCode.main,
            Environment.portalPanelEnv.portalCodeStartsWith.wicket
        );
    }


}
