import {Opening2D} from "../viewer2D/Opening2D";
import {OpeningConfigData} from "../configurationData/OpeningConfigData";
import {Environment} from "../environment/Environment";

export class OpeningManager {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.drawManager = drawManager;
        this.opening2D = new Opening2D(drawManager, this);
        this.openingConfigData = new OpeningConfigData(drawManager);
    }

    /**
     * Retourne le nombre d'ouverture sur le segment
     * @param {object[]} openingList
     * @param {int} keyMainWall
     * @returns {number}
     */
    getCountOpeningsOnMainWall(openingList, keyMainWall) {
        let counterOpeningsOnMainWall = 0;
        for (let i = 0; i < openingList.length; i++) {
            if (openingList[i].belongToMainWallKey === keyMainWall) {
                counterOpeningsOnMainWall++;
            }
        }
        return counterOpeningsOnMainWall;
    }

    /**
     * retourne la position d'une ouverture sur son segment
     * @param {object} opening
     * @returns {number}
     */
    getPosOpeningOnMainWall(opening) {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(opening.belongToFenceElement);
        const openingList = fenceElement[opening.belongToMainCornerSection].openings;
        let posOpeningOnMainWall = 0;
        for (let i = 0; i < openingList.length; i++) {
            if (openingList[i].belongToMainWallKey === opening.belongToMainWallKey && opening.id !== openingList[i].id) {
                if (opening.posStart > openingList[i].posStart) {
                    posOpeningOnMainWall++;
                }
            }
        }
        return posOpeningOnMainWall;
    }

    /**
     * Obtenir l'ouverture suivante
     * @param {object} opening
     * @returns {object|null}
     */
    getNextOpeningOnMainWall(opening) {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(opening.belongToFenceElement);
        const openingList = fenceElement[opening.belongToMainCornerSection].openings
        let nextOpeningOnMainWall = null;
        let posTemp = null;
        for (let i = 0; i < openingList.length; i++) {
            if (openingList[i].belongToMainWallKey === opening.belongToMainWallKey && opening.id !== openingList[i].id) {
                if (!posTemp) {
                    if (opening.posStart < openingList[i].posStart) {
                        posTemp = openingList[i].posStart;
                        nextOpeningOnMainWall = openingList[i];
                    }
                    continue;
                }
                if (opening.posStart < openingList[i].posStart && posTemp > openingList[i].posStart) {
                    posTemp = openingList[i].posStart;
                    nextOpeningOnMainWall = openingList[i];
                }
            }
        }
        return nextOpeningOnMainWall;
    }

    /**
     * Obtenir l'ouverture précédente
     * @param {object} opening
     * @returns {object|null}
     */
    getPreviousOpeningOnMainWall(opening) {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(opening.belongToFenceElement);
        const openingList = fenceElement[opening.belongToMainCornerSection].openings
        let previousOpeningOnMainWall = null;
        let posTemp = null;
        for (let i = 0; i < openingList.length; i++) {
            if (openingList[i].belongToMainWallKey === opening.belongToMainWallKey && opening.id !== openingList[i].id) {
                if (!posTemp) {
                    if (opening.posStart > openingList[i].posStart) {
                        posTemp = openingList[i].posStart;
                        previousOpeningOnMainWall = openingList[i];
                    }
                    continue;
                }
                if (opening.posStart > openingList[i].posStart && posTemp < openingList[i].posStart) {
                    posTemp = openingList[i].posStart;
                    previousOpeningOnMainWall = openingList[i];
                }
            }
        }
        return previousOpeningOnMainWall;
    }

    /**
     * Retourne la position d'une ouverture dans sa liste à partir de son id et son segment
     * @returns {int|null}
     */
    getPositionOfOpeningIntoList(fenceElement, keyMainCornerSection, id) {
        let position = null;
        for (let k = 0; k < fenceElement[keyMainCornerSection].openings.length; k++) {
            if (fenceElement[keyMainCornerSection].openings[k].id === parseInt(id)) {
                position = k;
            }
        }
        return position;
    }

    /**
     * Retourne la position de l'ouverture par rapport au poteau start de son mur principal
     * @param {Wall} wallSelected
     * @param openingData
     * @returns {number}
     */
    getPosition(wallSelected, openingData) {
        // à ce stade, on a déjà vérifié que le mur peut supporter la taille d'une ouverture
        let posZero = Environment.drawConfiguration.main.spacingMiniBetween2Corner;
        if (openingData.posStart) {
            posZero = openingData.posStart;
        }
        return posZero;
    }

    /**
     * Retourne true si la grugeBox est entre les deux points startBox et endBox
     * @param betweenBox
     * @param startBox
     * @param endBox
     */
    grugeBoxIsNotBetween(betweenBox, startBox, endBox) {
        let between = betweenBox.getBounds();
        let start = startBox.getBounds();
        let end = endBox.getBounds();
        if (start.x <= end.x && start.y <= end.y) {
            if (!(between.x + between.width < end.x) && !(between.y + between.height < end.y)) {
                betweenBox.overEnd = startBox;
            }
            if ((!(between.x > start.x + start.width) && !(between.y > start.y + start.height))) {
                betweenBox.overStart = endBox;
            }
        }
        if (start.x < end.x && start.y > end.y) {
            if ((!(between.x + between.width < end.x) && !(between.y > end.y + end.height))) {
                betweenBox.overEnd = startBox;
            }
            if (!(between.x > start.x + start.width) && !(between.y + between.height < start.y)) {
                betweenBox.overStart = endBox;
            }
        }
        if (start.x >= end.x && start.y >= end.y) {
            if (!(between.x + between.width <= start.x) && !(between.y + between.height <= start.y)) {
                betweenBox.overEnd = endBox;
            }
            if ((!(between.x > end.x + end.width) && !(between.y > end.y + end.height))) {
                betweenBox.overStart = startBox;
            }
        }
        if (start.x > end.x && start.y < end.y) {
            if (!(between.x > end.x + end.width) && !(between.y + between.height < end.y)) {
                betweenBox.overEnd = startBox;
            }
            if ((!(between.x + between.width < start.x) && !(between.y > start.y + start.height))) {
                betweenBox.overStart = endBox;
            }
        }
    }

    getPreviousOpeningEndPositionOrStartWall(openingData){
        const previousOpening = this.drawManager.openingManager.getPreviousOpeningOnMainWall(openingData);
        let posEndPreviousOpening = 0;
        if (previousOpening) {
            if(previousOpening.typeOpening === Environment.drawConfiguration.opening.type.slide){
                posEndPreviousOpening = previousOpening.posStart + previousOpening.wallPortal.centerDistance + previousOpening.wallPortalFixed.centerDistance;
            }
            if(previousOpening.typeOpening === Environment.drawConfiguration.opening.type.portal){
                posEndPreviousOpening = previousOpening.posStart + previousOpening.gapStart + previousOpening.gapEnd + previousOpening.openingSize;
            }
        }
        return posEndPreviousOpening;
    }

    getNextOpeningStartPositionOrEndWall(openingData){
        const nextOpening = this.drawManager.openingManager.getNextOpeningOnMainWall(openingData);
        if (nextOpening) {
            return nextOpening.posStart;
        }
        let wall = null;
        openingData.startMainWall.wallStarts.forEach((wallStart) => {
            openingData.endMainWall.wallEnds.forEach((wallEnd) => {
                if(wallEnd === wallStart){
                    wall = wallEnd
                }
            })
        })
        return wall.wallLength();
    }

    replaceOpeningAtStart(openingData){
        let posEndPreviousOpening = this.getPreviousOpeningEndPositionOrStartWall(openingData);
        openingData.posStart = posEndPreviousOpening + Environment.drawConfiguration.main.spacingMiniBetween2Corner;
    }
    replaceOpeningAtEnd(openingData){
        let posEndNextOpeningOrEndWall = this.getNextOpeningStartPositionOrEndWall(openingData);
        console.log(posEndNextOpeningOrEndWall)
        if(openingData.typeOpening === Environment.drawConfiguration.opening.type.slide){
            openingData.posStart = posEndNextOpeningOrEndWall - Environment.drawConfiguration.main.spacingMiniBetween2Corner - openingData.wallPortal.centerDistance - openingData.wallPortalFixed.centerDistance;
        }
        if(openingData.typeOpening === Environment.drawConfiguration.opening.type.portal){
            openingData.posStart = posEndNextOpeningOrEndWall - Environment.drawConfiguration.main.spacingMiniBetween2Corner - openingData.gapStart - openingData.gapEnd - openingData.openingSize;
        }
    }

}
