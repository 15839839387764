import {Environment} from "../environment/Environment";

export class FenceElementManager {

    constructor(drawManager) {
        this.fenceElementInit = Environment.globalConfigurationEnv.mainCornerSectionJsonInit;
        this.drawManager = drawManager;
        this.fenceElementSelected = null;
        this.fenceElements = [];
        this.fenceElementsName = [];
        this.__changeFenceElementEvent = this.__changeFenceElement.bind(this);
        this.__createFenceElementEvent = this.__createFenceElement.bind(this);
    }

    /**
     * Création d'un élément de grillage
     * @param willSelectedFenceElement
     * @returns {{numberOfCorner: number, name: null, wallThickness: number, mainCornerSection0: {mainWall: null, corner: null, openingsWalls: *[], simpleCornersMainWall: *[], id: number, openings: *[]}, cornerRadius: number}}
     */
    newFenceElement(willSelectedFenceElement = false) {
        let newFenceElement = Object.create(this.fenceElementInit);
        newFenceElement.name = Environment.drawPanelEnv.defaultInputValue.elementName(this.countFenceElements() + 1);
        newFenceElement.cornerRadius = this.drawManager.options.viewer2d.viewer2dOptions["corner-radius"];
        newFenceElement.wallThickness = this.drawManager.options.viewer2d.viewer2dOptions["line-wall-thickness"];
        this.fenceElements.push(newFenceElement);
        this.fenceElementsName.push(newFenceElement.name);
        if (willSelectedFenceElement) {
            this.fenceElementSelected = newFenceElement;
        }
        return newFenceElement;
    }

    getFenceElement(key) {
        return this.fenceElements[key];
    }

    getFenceElements() {
        return this.fenceElements;
    }

    createMainCornerSection(cmCo, fenceElement) {
        // Utilisation de cette méthode pour que la valeur retournée soit immédiatement le bon corner s'il y a intersection
        let corner = this.drawManager.floorplan.newCorner(cmCo.x, cmCo.y, Environment.globalConfigurationEnv.mainCornerSectionName + fenceElement.numberOfCorner + '-' + fenceElement.name);
        if (!corner.hadChangedByIntersection) {
            corner.belongToFenceElement = fenceElement.name;
            fenceElement[Environment.globalConfigurationEnv.mainCornerSectionName + fenceElement.numberOfCorner] = {
                "id": fenceElement.numberOfCorner,
                'corner': corner,
                "cornerLinkedTo": [],
                'mainWalls': [],
                'simpleCornersMainWall': [],
                'openingsWalls': [],
                'openings': []
            };
            this.setCornerLinkedTo(fenceElement);
            fenceElement.numberOfCorner++;
        } else {
            let fenceElementLastNode = this.getFenceElementByName(corner.belongToFenceElement);
            let cornerBaseLastNode = corner.id.split('-')[0];
            fenceElementLastNode[cornerBaseLastNode].cornerLinkedTo.push([
                this.drawManager.blueprint3d.floorplanner.__lastNode.belongToFenceElement,
                this.drawManager.blueprint3d.floorplanner.__lastNode.id
            ])
        }
        this.drawManager.blueprint3d.floorplanner.__lastNode = corner;
    }

    /**
     * retourne la liste du champ cornerLinkedTo d'une section
     * @param {Corner} mainCorner
     * @returns {[]}
     */
    getCornerLinkedToList(mainCorner) {
        return this.getFenceElementByName(mainCorner.belongToFenceElement)[mainCorner.id.split('-')[0]].cornerLinkedTo;
    }

    setCornerLinkedTo(fenceElement) {
        if (this.drawManager.blueprint3d.floorplanner.__lastNode) {
            fenceElement[Environment.globalConfigurationEnv.mainCornerSectionName + fenceElement.numberOfCorner].cornerLinkedTo.push([
                this.drawManager.blueprint3d.floorplanner.__lastNode.belongToFenceElement,
                this.drawManager.blueprint3d.floorplanner.__lastNode.id
            ]);
        }
    }

    getFenceElementSelected() {
        return this.fenceElementSelected;
    }

    setFenceElementSelected(fenceElement) {
        this.fenceElementSelected = fenceElement;
    }

    countFenceElements() {
        return this.fenceElements.length;
    }

    getFenceElementByName(name) {
        let fenceElement = null;
        for (let i = 0; i < this.fenceElements.length; i++) {
            if (this.fenceElements[i].name === name) {
                fenceElement = this.fenceElements[i]
            }
        }
        return fenceElement
    }

    /**
     * Mise à jour de fenceElementsList lors de l'intersection de deux poteaux principaux
     * @param {Corner} from
     * @param {Corner} to
     */
    manageIntersect(from, to) {
        this.switchCornerLinkedToListOnOtherSection(from, to)
        this.modifyAllCornerLinkedToFromMainCornerToOther(from, to)
        this.deleteFenceElement(from);
    }

    /**
     * Ajoute la liste cornerLinkedTo du poteau supprimé vers le poteau fusionné
     * Empêche également l'ajout d'un segment inverse à un segment existant
     * @param {Corner} from
     * @param {Corner} to
     */
    switchCornerLinkedToListOnOtherSection(from, to) {
        this.getCornerLinkedToList(from).forEach((item) => {
            // on vérifie item[1] !== to.id pour ne pas lier le poteau (to) à lui même
            if(item[1] !== to.id){
                let reverseExist = false;
                this.getFenceElementByName(item[0])[item[1].split('-')[0]].cornerLinkedTo.forEach((value) => {
                    if(value[0] === to.belongToFenceElement && value[1] === to.id){
                        reverseExist = true;
                    }
                })
                // on vérifie qu'il n'y a pas de lien dans les deux sens
                if(!reverseExist){
                    this.getCornerLinkedToList(to).push(item);
                }
            }

        })
    }

    /**
     * Gère la liaison des segments liés au poteau qui fusionne
     * @param {Corner} from
     * @param {Corner} to
     */
    modifyAllCornerLinkedToFromMainCornerToOther(from, to) {
        const fromName = from.belongToFenceElement;
        const fromSection = from.id;
        const toName = to.belongToFenceElement;
        const toSection = to.id;
        this.getFenceElements().forEach((fenceElement) => {
            for (const key of Object.keys(fenceElement)) {
                if (key.startsWith(Environment.globalConfigurationEnv.mainCornerSectionName)) {
                    fenceElement[key].cornerLinkedTo.forEach((link) => {
                        if (link[0] === fromName && link[1] === fromSection) {
                            link[0] = toName;
                            link[1] = toSection;
                        }
                    })
                }
            }
        })
    }

    /**
     * Supprime la section fusionnée
     * @param {Corner} mainCorner
     */
    deleteFenceElement(mainCorner) {
        let name = mainCorner.belongToFenceElement;
        Reflect.deleteProperty(this.getFenceElementByName(name), mainCorner.id.split('-')[0]);
        this.getFenceElementByName(name).numberOfCorner--;
    }

    /** ******************************************************************************************/

    /**                                 FONCTIONS EVENT                                         **/
    /** ******************************************************************************************/

    __createFenceElement() {
        this.newFenceElement();
        this.drawManager.configuratorStarter.drawPanel.updateCornerBaseListControl()
    }

    __changeFenceElement(e) {
        this.drawManager.blueprint3d.floorplanner.__lastNode = null;
        for (let i = 0; i < this.countFenceElements(); i++) {
            if (e.value === this.getFenceElement(i).name) {
                this.setFenceElementSelected(this.getFenceElement(i));
                if (this.fenceElementSelected.numberOfCorner > 1) {
                    this.drawManager.blueprint3d.floorplanner.__lastNode = this.fenceElementSelected[Environment.globalConfigurationEnv.mainCornerSectionName + (this.fenceElementSelected.numberOfCorner - 1)].corner;
                }
            }
        }
    }
}
