import Wall from "../../model/wall";
import {WallDimensions2D} from "../../viewer2d/WallView2D";

export class DimensionManager {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.drawManager = drawManager;
    }

    /**
     * Ajoute la cote sur le segment et le container ciblés
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param {object} options
     * @param {WallDimensions2D[]} container
     */
    addDimension(start,end,options,container){
        let wall = new Wall(start,end);
        let dim = new WallDimensions2D(
            this.drawManager.floorplan, options, wall,
        );
        container.push(dim);
        wall.remove();
        return dim;
    }

    drawDimension(container){
        container.forEach((dimension) => {
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(dimension);
        })

    }

    resetDimension(container){
        container.forEach((dimension) => {
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(dimension);
        })
    }
}


