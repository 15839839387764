import {Environment} from "../environment/Environment";

export class JsonDataManager {

    constructor(drawManager, jsonData) {
        this.jsonData = jsonData;
        this.drawManager = drawManager;

    }

    /**
     * Retourne la liste des options type de clôture pour le select
     * @param {string[]} authorizedList
     * @param {string[]} excludedFenceTaxonCode
     * @returns {null|string[]}
     */
    getFenceTypeNames(authorizedList = null, excludedFenceTaxonCode = null) {
        let fenceTypes = [Environment.wallPanelEnv.defaultInputMessage.FenceType];
        this.jsonData.configurationTypeInformations.forEach((item) => {
            if (excludedFenceTaxonCode && excludedFenceTaxonCode.includes(item.code)) {
                return;
            }
            if (authorizedList === null || authorizedList.includes(item.code)) {
                fenceTypes.push(item.nom);
            }
        })
        return fenceTypes.length === 1 ? null : fenceTypes;
    }

    /**
     * Retourne le nom du type de clôture en fonction de sa clé
     * @param {string} code
     * @returns {string}
     */
    getFenceTypeNameByTaxonCode(code) {
        let fenceName = "";
        this.jsonData.configurationTypeInformations.forEach((item) => {
            if (item.code === code) {
                fenceName = item.nom;
            }
        })
        return fenceName;
    }


    /**
     * retourne un l'objet type de clôture de jsondata correspondant aux informations fournies
     * @param {string} name
     * @returns {object}
     */
    getFenceTypeInfoByName(name) {
        let fenceTypeInfo = null;
        this.jsonData.configurationTypeInformations.forEach((item) => {
            if (item.nom === name) {
                fenceTypeInfo = item;
            }
        })
        return fenceTypeInfo;
    }

    /**
     * Retourne la liste des options type de remplissage pour le select
     * @param {object[]} fillingTypes
     * @param {string[]} authorizedList
     * @param {string[]} excludedFillingTaxonCode
     * @returns {string[]}
     */
    getFillingTypeNames(fillingTypes, authorizedList = null, excludedFillingTaxonCode = null) {
        let fillingTypesNameList = [Environment.wallPanelEnv.defaultInputMessage.FillingType];
        fillingTypes.forEach((item) => {
            if (excludedFillingTaxonCode && excludedFillingTaxonCode.includes(item.code)) {
                return;
            }
            if (authorizedList === null || authorizedList.includes(item.code)) {
                fillingTypesNameList.push(item.nom);
            }
        })
        return fillingTypesNameList.length === 1 ? null : fillingTypesNameList;
    }


    /**
     * retourne un l'objet type de remplissage de jsondata correspondant aux informations fournies
     * @param {Wall} wall
     * @returns {object}
     */
    getFillingTypeInfoByName(wall) {
        let fillingTypeInfo = null;
        this.getFenceTypeInfoByName(wall.fenceTypeName).fillingType.forEach((item) => {
            if (item.nom === wall.fillingTypeName) {
                fillingTypeInfo = item;
            }
        })
        return fillingTypeInfo;
    }

    /**
     * Retourne la liste des valeurs fournies au sélecteur
     * @param {object[]} technicalInfoProducts
     * @returns {string[]}
     */
    getProducts(technicalInfoProducts) {
        let productList = [];
        technicalInfoProducts.forEach((item) => {
            productList[item.translations.fr_FR.name] = item;
        })
        return productList;
    }

    /**
     * Retourne une list clé(valeur donnée au selecteur) => valeur(informations produit du site ecommerce)
     * @param {object[]} panels
     * @param {string} defaultValue
     * @returns {string[]}
     */
    getProductsNames(panels, defaultValue) {
        let productsName = [defaultValue];
        panels.forEach((item) => {
            productsName.push(item.translations.fr_FR.name);
        })
        return productsName;
    }

    /**
     * Récupère les informations techniques des portails type mains courantes
     */
    getPortalsNamesAndCodes(fenceTaxonCode, startName) {
        let portalsNameList = [];
        let portalsNameCodeList = [];
        if (startName === Environment.portalPanelEnv.portalCodeStartsWith.wicket) {
            portalsNameList.push(Environment.portalPanelEnv.defaultInputMessage.wickets);
            portalsNameList.push(Environment.portalPanelEnv.defaultInputMessage.wicketsNoAdaptive);
        }
        if (startName === Environment.portalPanelEnv.portalCodeStartsWith.portal) {
            portalsNameList.push(Environment.portalPanelEnv.defaultInputMessage.portals);
            portalsNameList.push(Environment.portalPanelEnv.defaultInputMessage.portalsNoAdaptive);
        }

        this.jsonData.configurationTypeInformations.forEach((item) => {
            if (item.code === fenceTaxonCode) {
                item.fillingType.forEach((fillingItem) => {
                    if (
                        fillingItem.code === Environment.dataEcommerceEnv.cloture2m.taxonCode.fenceFilling ||
                        fillingItem.code === Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling
                    ) {
                        fillingItem.portals.forEach((portalItem) => {
                            if (portalItem.code.toLowerCase().startsWith(startName)) {
                                portalsNameList.push(portalItem.translations.fr_FR.name)
                                portalsNameCodeList.push(portalItem.translations.fr_FR.name + '@@' + portalItem.code)
                            }
                        })
                    }
                });
            }
        })
        return [portalsNameList, portalsNameCodeList];
    }

    /**
     *
     * @param {number} id
     * @returns {object|null}
     */
    getTechnicalInfoPortalById(id) {
        let technicalInfo = null;
        for (const property in Environment.dataEcommerceEnv.combineTechnicalInfoPortalNameWithIdPortal) {
            if (Environment.dataEcommerceEnv.combineTechnicalInfoPortalNameWithIdPortal[property].includes(id)) {
                this.jsonData.technicalInformations.portals.forEach((item) => {
                    if (item.name === property) {
                        technicalInfo = item;
                    }
                })
            }
        }
        return technicalInfo;
    }

    /**
     * Retourne la liste des portails du site e-commerce
     * @returns {object[]}
     */
    getAllPortals() {
        let portalsList = [];
        let listId = [];
        this.jsonData.configurationTypeInformations.forEach((item) => {
            item.fillingType.forEach((fillingItem) => {
                fillingItem.portals.forEach((portalItem) => {
                    if (!listId.includes(portalItem.id)) {
                        portalsList.push(portalItem);
                        listId.push(portalItem.id);
                    }
                })
            });
        })
        return portalsList;
    }


    /**
     * Récupère les informations techniques des pare-ballons en fonction du code taxon et la hauteur du poteau
     * @param wall
     * @param {Corner|SimpleCorner}simpleCorner
     */
    getTechnicalInfoBallScreen(wall, simpleCorner = null) {
        let technicalInformation = null;
        let cornerHeight = wall.cornerHeightType;
        // detection de la taille du mainCorner
        if (simpleCorner !== null) {
            if (simpleCorner.heightCorner >= cornerHeight) {
                cornerHeight = simpleCorner.heightCorner;
            } else {
                simpleCorner.heightCorner = cornerHeight;
            }
        }
        this.jsonData.technicalInformations.ballScreen.forEach((item) => {
            if (wall.fillingTypeCode === item.codeTaxon && (cornerHeight * 1000).toString() === item.heightAboveGroundCorner) {
                technicalInformation = item;
            }
        })
        return technicalInformation;

    }

    /**
     * Récupère les informations techniques des mains courantes en fonction du code taxon
     */
    getTechnicalInfoHandRail(codeTaxon, railLength) {
        railLength = railLength ? railLength : 5000;
        let technicalInformation = null;
        this.jsonData.technicalInformations.handRails.forEach((item) => {
            if (codeTaxon === item.codeTaxon && item.slideLength === String(railLength)) {
                technicalInformation = item;
            }
        })
        return technicalInformation;

    }

    /**
     * Récupère les informations techniques des clôtures 2m en fonction du code taxon
     */
    getTechnicalInfoCloture2m(codeTaxon,square = "false") {
        let technicalInformation = null;
        this.jsonData.technicalInformations.corner2m.forEach((item) => {
            if (codeTaxon === item.codeTaxon && square === item.square) {
                technicalInformation = item;
            }
        })
        return technicalInformation;

    }

    /**
     * Aiguille en fonction des informations du segment vers la mise à jour des éléments
     * @param {Wall} wall
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     * @param {object|null} openingData
     */
    setConfigCornerInRelationWall(wall, corner, position, openingData = null) {
        // Orientation du poteau dans le même sens que le segment
        if (position === 0) {
            corner.wallAngle = this.drawManager.mainCorner2D.calculateAnyWallsCornerOrientationAngle(corner);
        } else {
            corner.wallAngle = -Math.atan2(wall.wallDirectionNormalized().x, wall.wallDirectionNormalized().y);
        }

        if (wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main) {
            this.setConfigPareBallons(wall, corner, position, openingData);
        }
        if (wall.fenceTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.main) {
            this.setConfigMainCourantes(wall, corner, position, openingData);
        }
        if (wall.fenceTypeCode === Environment.dataEcommerceEnv.cloture2m.taxonCode.main) {
            this.setConfigCloture2m(wall, corner, position);
        }
        if (corner.isInsideOpeningCornerStart || corner.isInsideOpeningCornerEnd) {
            this.setInfosPortal(wall, openingData)
        }
        this.drawManager.currentCartData.addCornerProduct(wall, corner);
    }

    /**
     * informe du type de remplissage d'un pare-ballons pour la mise à jour des informations
     * @param {Wall} wall
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     */
    setConfigPareBallons(wall, corner, position = null) {

        /** position 0 c'est à dire c'est un mainCorner */
        let technicalInfo;
        if (position === 0) {
            technicalInfo = this.getTechnicalInfoBallScreen(wall, corner);
        } else {
            technicalInfo = this.getTechnicalInfoBallScreen(wall);
        }
        /** *******/

        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.netFilling) {
            if (technicalInfo) {
                this.setInfosCornerBallScreen(wall, technicalInfo, corner, position);
            }
        }
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.fenceFilling) {
            if (position % 2 === 0) {
                if (technicalInfo) {
                    this.setInfosCornerBallScreen(wall, technicalInfo, corner, position);
                }
            } else {
                let square = (wall.cornerHeightType > 6) ? "true" : "false";
                this.setInfosCornerCloture2m(wall, Environment.dataEcommerceEnv.cloture2m.taxonCode.fenceFilling, corner, position, square);
            }
        }
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.panelFilling) {
            if (position % 2 === 0) {
                if (technicalInfo) {
                    this.setInfosCornerBallScreen(wall, technicalInfo, corner, position);
                }

            } else {
                let square = (wall.cornerHeightType > 6) ? "true" : "false";
                this.setInfosCornerCloture2m(wall, Environment.dataEcommerceEnv.cloture2m.taxonCode.panelFilling, corner, position, square)
            }
        }
    }

    /**
     * informe du type de remplissage d'une mains courantes pour la mise à jour des informations
     * @param {Wall} wall
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     */
    setConfigMainCourantes(wall, corner, position) {
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.noFilling) {
            this.setInfosHandRail(Environment.dataEcommerceEnv.handRail.taxonCode.noFilling, corner, wall, position);
        }
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling) {
            this.setInfosHandRail(Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling, corner, wall, position)
        }
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.panelFilling) {
            this.setInfosHandRail(Environment.dataEcommerceEnv.handRail.taxonCode.panelFilling, corner, wall, position);
        }
    }

    /**
     * informe du type de remplissage d'une clôture 2m pour la mise à jour des informations
     * @param {Wall} wall
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     */
    setConfigCloture2m(wall, corner, position,) {
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.cloture2m.taxonCode.fenceFilling) {
            this.setInfosCornerCloture2m(wall, Environment.dataEcommerceEnv.cloture2m.taxonCode.fenceFilling, corner, position);

        }
        if (wall.fillingTypeCode === Environment.dataEcommerceEnv.cloture2m.taxonCode.panelFilling) {
            this.setInfosCornerCloture2m(wall, Environment.dataEcommerceEnv.cloture2m.taxonCode.panelFilling, corner, position)
        }
    }

    /**
     * met à jour les informations des éléments pare-ballons
     * @param {Wall} wall
     * @param {object} technicalInfo
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     */
    setInfosCornerBallScreen(wall, technicalInfo, corner, position) {
        // ajoute les informations de couleur et type de poteau
        if (!corner.isInsideOpeningCornerStart && !corner.isInsideOpeningCornerEnd) {
            if (position === 0) {
                if (corner.isOutsideOpeningCornerStart || corner.isOutsideOpeningCornerEnd) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.ballScreen.fillColor;
                }
                if (this.drawManager.mainCorner2D.isCornerAngle(wall, corner, [Environment.dataEcommerceEnv.pareBallon.taxonCode.main, Environment.dataEcommerceEnv.cloture2m.taxonCode.main])) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.ballScreen.fillColor;
                } else {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.ballScreen.fillColor;
                }
            } else {
                corner.cornerType = Environment.drawConfiguration.main.cornerType.inter.name;
                corner.fillColor = Environment.drawConfiguration.main.cornerType.inter.color.ballScreen.fillColor;
                if (corner.isExtraStrut) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.ballScreen.fillColor;
                }
            }
            corner.lengthSealing = technicalInfo.lengthSealing;
            corner.widthSealing = technicalInfo.widthSealing;
            corner.heightSealing = technicalInfo.heightSealing;
            corner.cornerFenceType = Environment.dataEcommerceEnv.pareBallon.taxonCode.main
            const diameterCornerList = technicalInfo.diameterCorner.split('x');
            corner.cornerDiameterOrLength = diameterCornerList[0] / 10;
            corner.cornerWidth = null;
            if (diameterCornerList.length > 1) {
                corner.cornerWidth = diameterCornerList[1] / 10;
            }
        }
    }

    /**
     * met à jour les informations des éléments mains courantes
     * @param {string} filingTaxonCode
     * @param {SimpleCorner|Corner} corner
     * @param {Wall} wall
     * @param {number} position
     */
    setInfosHandRail(filingTaxonCode, corner, wall, position) {

        const technicalInfoHandRail = this.drawManager.jsonDataManager.getTechnicalInfoHandRail(filingTaxonCode, wall.railLength);
        // ajoute les informations de couleur, hauteur hors sol et type de poteau
        if (!corner.isInsideOpeningCornerStart && !corner.isInsideOpeningCornerEnd) {
            if (position === 0 && !corner.isSlideOpeningCornerStart && !corner.isSlideOpeningCornerMiddle && !corner.isSlideOpeningCornerEnd) {
                if (corner.isOutsideOpeningCornerStart || corner.isOutsideOpeningCornerEnd) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.handrail.fillColor;
                    corner.heightCorner = technicalInfoHandRail.heightAboveGroundOtherCorner;
                }
                if (this.drawManager.mainCorner2D.isCornerAngle(wall, corner, [Environment.dataEcommerceEnv.handRail.taxonCode.main])) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.handrail.fillColor;
                    corner.heightCorner = technicalInfoHandRail.heightAboveGroundAngleCorner;
                } else {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.handrail.fillColor;
                    corner.heightCorner = technicalInfoHandRail.heightAboveGroundOtherCorner;
                }
            } else {
                corner.heightCorner = technicalInfoHandRail.heightAboveGroundOtherCorner;
                corner.fillColor = Environment.drawConfiguration.main.cornerType.inter.color.handrail.fillColor;
                corner.cornerType = Environment.drawConfiguration.main.cornerType.inter.name;
                if ((corner.isSlideClosure && corner.isSlideOpeningCornerEnd) || (corner.isSlideClosure && corner.isSlideOpeningCornerStart)) {
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.specialSlide.fillColor;
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.specialSlide.name;
                }
            }
            corner.cornerFenceType = Environment.dataEcommerceEnv.handRail.taxonCode.main
            corner.lengthSealing = technicalInfoHandRail.lengthSealing;
            corner.widthSealing = technicalInfoHandRail.widthSealing;
            corner.heightSealing = technicalInfoHandRail.heightSealing;


            // ajoute les informations de circonférence d'un poteau
            corner.cornerDiameterOrLength = technicalInfoHandRail.diameterCorner / 10;
            corner.cornerWidth = null;
        }

    }

    /**
     * met à jour les informations des éléments clôture 2m
     * @param {Wall} wall
     * @param {string} filingTaxonCode
     * @param {SimpleCorner|Corner} corner
     * @param {number} position
     * @param {string} square
     */
    setInfosCornerCloture2m(wall, filingTaxonCode, corner, position, square = "false") {
        const technicalInfoCloture2m = this.getTechnicalInfoCloture2m(filingTaxonCode,square);
        if (!corner.isInsideOpeningCornerStart && !corner.isInsideOpeningCornerEnd) {
            // Si le poteau est déjà un poteau pare-ballons on ne modifie que les infos d'angle si nécessaire
            // if (corner.heightCorner > 2) {
            //     if (this.drawManager.mainCorner2D.isCornerAngle(wall, corner, [Environment.dataEcommerceEnv.cloture2m.taxonCode.main, Environment.dataEcommerceEnv.pareBallon.taxonCode.main])) {
            //         corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
            //         corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.ballScreen.fillColor;
            //     }
            //     return;
            // }
            // ajoute les informations de couleur et type de poteau
            if (position === 0) {
                if (corner.isOutsideOpeningCornerStart || corner.isOutsideOpeningCornerEnd) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.cloture2m.fillColor;
                }
                if (this.drawManager.mainCorner2D.isCornerAngle(wall, corner, [Environment.dataEcommerceEnv.cloture2m.taxonCode.main, Environment.dataEcommerceEnv.pareBallon.taxonCode.main])) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.cloture2m.fillColor;
                } else {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.start.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.start.color.cloture2m.fillColor;
                }
            } else {
                corner.cornerType = Environment.drawConfiguration.main.cornerType.inter.name;
                corner.fillColor = Environment.drawConfiguration.main.cornerType.inter.color.ballScreen.fillColor;
                if (corner.isExtraStrut) {
                    corner.cornerType = Environment.drawConfiguration.main.cornerType.angle.name;
                    corner.fillColor = Environment.drawConfiguration.main.cornerType.angle.color.ballScreen.fillColor;
                }
            }
            corner.cornerFenceType = Environment.dataEcommerceEnv.cloture2m.taxonCode.main
            corner.lengthSealing = technicalInfoCloture2m.lengthSealing;
            corner.widthSealing = technicalInfoCloture2m.widthSealing;
            corner.heightSealing = technicalInfoCloture2m.heightSealing;
            corner.heightCorner = technicalInfoCloture2m.heightAboveGroundCorner;

            const diameterCornerList = technicalInfoCloture2m.diameterCorner.split('x');
            corner.cornerDiameterOrLength = diameterCornerList[0] / 10;
            corner.cornerWidth = null;
            if (diameterCornerList.length > 1) {
                corner.cornerWidth = diameterCornerList[1] / 10;
            }
        }
    }

    setInfosPortal(wall, openingData) {
        openingData.corners[1].cornerDiameterOrLength = Environment.drawConfiguration.mainCorner.defaultDimension.diameterOrLength / 10;
        openingData.corners[1].cornerWidth = 0;
        openingData.corners[2].cornerDiameterOrLength = Environment.drawConfiguration.mainCorner.defaultDimension.diameterOrLength / 10;
        openingData.corners[2].cornerWidth = 0;
        if (openingData.technicalInfoPortal) {
            openingData.corners[1].cornerDiameterOrLength = (openingData.technicalInfoPortal.widthCorner) / 10;
            openingData.corners[1].cornerWidth = openingData.technicalInfoPortal.widthCorner / 10;
            openingData.corners[1].lengthSealing = openingData.technicalInfoPortal.lengthSealingCorner;
            openingData.corners[1].heightSealing = openingData.technicalInfoPortal.heightSealingCorner;
            openingData.corners[1].widthSealing = openingData.technicalInfoPortal.widthSealingCorner;
            openingData.corners[2].cornerDiameterOrLength = openingData.technicalInfoPortal.widthCorner / 10;
            openingData.corners[2].cornerWidth = openingData.technicalInfoPortal.widthCorner / 10;
            openingData.corners[2].lengthSealing = openingData.technicalInfoPortal.lengthSealingCorner;
            openingData.corners[2].heightSealing = openingData.technicalInfoPortal.heightSealingCorner;
            openingData.corners[2].widthSealing = openingData.technicalInfoPortal.widthSealingCorner;

            // couleur
            if (openingData.portalType === Environment.portalPanelEnv.inputHandrailWicket.type || openingData.portalType === Environment.portalPanelEnv.inputHandrailPortal.type) {
                openingData.corners[1].fillColor = Environment.drawConfiguration.main.cornerType.inter.color.handrail.fillColor;
                openingData.corners[2].fillColor = Environment.drawConfiguration.main.cornerType.inter.color.handrail.fillColor;
            }
            if (openingData.portalType === Environment.portalPanelEnv.inputFence2mPortal.type || openingData.portalType === Environment.portalPanelEnv.inputFence2mWicket.type) {
                openingData.corners[1].fillColor = Environment.drawConfiguration.main.cornerType.inter.color.cloture2m.fillColor;
                openingData.corners[2].fillColor = Environment.drawConfiguration.main.cornerType.inter.color.cloture2m.fillColor;
            }
            // type
            openingData.corners[1].cornerType = Environment.drawConfiguration.main.cornerType.inter.name;
            openingData.corners[2].cornerType = Environment.drawConfiguration.main.cornerType.inter.name;
        }

    }

}
