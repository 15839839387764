import {Environment} from "../environment/Environment";

export class AccessoryConfigData {
    constructor(products) {
        this.products = products;
    }

    /**
     * Ajoute à la quantité ou la créée
     * @param {string} code
     * @param {number} quantity
     */
    addAccessory(code, quantity) {
        const product = this.getAccessory(code);
        if (product) {
            product.quantity += quantity;
            return;
        }
        this.createAccessory(code, quantity);
    }

    /**
     * remplace la quantité
     * @param {string} code
     * @param {number} quantity
     */
    setAccessory(code, quantity) {
        const product = this.getAccessory(code);
        if (product) {
            product.quantity = quantity;
            return;
        }
        this.createAccessory(code, quantity);
    }

    createAccessory(code,quantity){
        this.products.push({
            "code": code,
            "quantity": quantity
        });
    }

    /**
     * @param {string} code
     * @param {number} quantity
     */
    removeAccessory(code, quantity) {
        const product = this.products.find(item => item.code === code);
        if (product) {
            product.quantity -= quantity;
        }
    }

    getAccessory(code) {
        const product = this.products.find(item => item.code === code);
        if (product) {
            return product;
        }
        return null;
    }

    /**
     *  Traitement particulier car le site e-commerce a besoin des informations de hauteur et longueur
     * @param {string} code
     * @param {number} height
     * @param {number} length
     */
    addNet(code, height, length) {
        // filet
        const product = this.products.find(item => item.code === code && item.height === height);
        if (product) {
            product.length = length;
        } else {
            this.products.push({
                "height": height,
                "length": length,
                "code": code
            });
        }
    }

    getHeightNet(wall) {
        let offset = 1;
        if (
            (wall.isPortal && wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.netFilling)||
            wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.fenceFilling ||
            wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.panelFilling
        ) {
            offset += 1;
        }
        return parseInt(wall.cornerHeightType) - offset;
    }

    // poteau de rétention
    getQuantityExtraStrut(wall) {
        let recommendExtraStrut = 1;
        if (wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main) {
            recommendExtraStrut = Environment.dataEcommerceEnv.pareBallon.recommendExtraStrut;
        }
        if (wall.fenceTypeCode === Environment.dataEcommerceEnv.cloture2m.taxonCode.main) {
            recommendExtraStrut = Environment.dataEcommerceEnv.cloture2m.recommendExtraStrut;
        }
        return Math.ceil((wall.wallLength() / (100 * recommendExtraStrut))) - 1
    }
}
