import {DrawManager} from "./manager/DrawManager";
import {DrawPanel} from "./panel/DrawPanel";
import {Configuration, gridSpacing, scale, snapTolerance, viewBounds} from "../core/configuration";
import {WallPanel} from "./panel/WallPanel";
import {ImageScalingPanel} from "./panel/ImageScalingPanel";
import {Environment} from "./environment/Environment";
import {EVENT_NOTHING_2D_SELECTED} from '../core/events';
import {PortalPanel} from "./panel/PortalPanel";

export class ConfiguratorStarter {

    constructor(quickSettings, blueprint3d, options) {
        this.options = options;
        this.quickSettings = quickSettings
        this.blueprint3d = blueprint3d;
        this.wallPanel = null;
        this.drawPanel = null;
        this.typeFencePanel = null;
        this.jsonData = null;
        this.wallClicked = false;
        this.openingClicked = false;
    }

    async init(pass) {
        // INITIALISATION DE LA CONFIGURATION DE LA GRILLE
        // valeur 1 snap au cm près
        Configuration.setValue(snapTolerance, 1);
        Configuration.setValue(gridSpacing, 50);
        Configuration.setValue(viewBounds, 500000);
        Configuration.setValue(scale, Environment.scalingConfiguration.defaultScale);

        await fetch(Environment.globalConfigurationEnv.clomoDomain + Environment.globalConfigurationEnv.uriGetJsonData)
            .then(response => response.json())
            .then(data => this.jsonData = data);


        // MANAGER DRAWER 2D
        const drawManager = new DrawManager(this.blueprint3d, this.options, this);
        // INITIALISATION PANELS
        this.initPanels(drawManager,pass);
        // INITIALISATION EVENT LISTENER
        this.initListener(drawManager)
    }

    initPanels(drawManager,pass) {
        // Initialisation des panneaux de configuration
        // this.typeFencePanel = new TypeFencePanel(this.quickSettings, Environment.globalConfigurationEnv.clomoDomain)
        // this.typeFencePanel.createStartControl();
        this.drawPanel = new DrawPanel(this.quickSettings, drawManager,pass, this);
        this.drawPanel.createStartControl();
        this.wallPanel = new WallPanel(this.quickSettings, drawManager);
        this.portalPanel = new PortalPanel(this.quickSettings, drawManager);
        this.imageScalingPanel = new ImageScalingPanel(this.quickSettings, drawManager);
        this.imageScalingPanel.createStartControl();
    }

    resetPanels() {
        this.typeFencePanel.remove();
        this.drawPanel.remove();
        this.wallPanel.remove();
    }

    hidePanels() {
        if (!this.wallClicked && this.wallPanel.settingPanel)  {
            this.wallPanel.__closePanel();
        }
        if (this.openingClicked && this.portalPanel.settingPanel)  {
            this.portalPanel.__closePanel();
        }
        this.wallClicked = false;
        this.openingClicked = false;
    }

    initListener(drawManager) {
        // Drag main Corner
        document.addEventListener("cornerMouseDown", drawManager.mainCorner2D.__dragStartEvent);
        document.addEventListener("cornerMouseMove", drawManager.mainCorner2D.__dragMoveEvent);
        document.addEventListener("cornerMouseUp", drawManager.mainCorner2D.__dragEndEvent);
        document.addEventListener("cornerHaveBeenIntersected", drawManager.mainCorner2D.__manageIntersectEvent);
        drawManager.blueprint3d.floorplanner.addFloorplanListener(EVENT_NOTHING_2D_SELECTED, this.hidePanels.bind(this));
        // MODE POINT BY POINT
        document.addEventListener("drawModeAddCorner", drawManager.mainCorner2D.__createNewMainCornerEvent);
        // Un mur a été cliqué demande d'ajout d'ouverture
        document.addEventListener("wallClicked", this.wallPanel.__openPanelEvent);
        document.addEventListener("openingClicked", this.portalPanel.__openPanelEvent);
    }


}
