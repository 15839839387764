import {BaseFloorplanViewElement2D} from './BaseFloorplanViewElement2D.js';
import {Dimensioning} from '../core/dimensioning.js';
import {EVENT_MOVED, EVENT_DELETED} from '../core/events.js';
import {Point} from 'pixi.js';
import {Configuration, snapTolerance, snapToGrid, dragOnlyX, dragOnlyY} from '../core/configuration.js';
import {dragMoveOpening} from "../clomo/environment/Event";


// import {pointPolygon, pointBox} from 'intersects';


export class SimpleCornerView2D extends BaseFloorplanViewElement2D {
    constructor(floorplan, options, simpleCorner) {
        super(floorplan, options);
        this.__options['corner-radius'] = 12.5;
        this.__options['coefficient-corner-outer-radius'] = 1;
        this.__options['intermediate-corner-fix-alpha-out'] = 0.3;
        this.__options['intermediate-corner-opening-fix-color-in'] = '0xFFFFFF'
        this.__options['intermediate-corner-opening-fix-color-out'] = '0xFFFFFF'
        this.__options['intermediate-corner-opening-fix-alpha-out'] = 0.3;
        for (var opt in options) {
            if (this.__options.hasOwnProperty(opt) && options.hasOwnProperty(opt)) {
                this.__options[opt] = options[opt];
            }
        }

        this.__simpleCorner = simpleCorner;
        this.pivot.x = this.pivot.y = 0.5;
        // this.__cornerUpdateEvent = this.__updateWithModel.bind(this);
        // this.__cornerDeletedEvent = this.__cornerDeleted.bind(this);

        // this.interactive = false;
        // this.buttonMode = false;
        // if (simpleCorner.isLocked) {
        //     this.__deactivate();
        // }
        this.__deactivate();
        this.__drawHoveredOffState();

        // this.__corner.addEventListener(EVENT_MOVED, this.__cornerUpdateEvent);
        // this.__corner.addEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        this.__updateWithModel();
        this.simpleCorner.setBounds(this.getBounds());
    }

    __drawSimpleCornerState() {
        this.clear();
        let alpha = this.simpleCorner.isOpening ? this.__options['intermediate-corner-opening-fix-alpha-out'] : this.__options['intermediate-corner-fix-alpha-out']

        let xOut = 0;//useRadius * 0.5;//
        let yOut = 0;//useRadius * 0.5;//
        if (this.__simpleCorner.cornerWidth) {
            this.beginFill(this.__simpleCorner.fillColor, alpha);
            this.drawRect(
                xOut - Dimensioning.cmToPixel(this.__simpleCorner.cornerDiameterOrLength) / 2,
                yOut - Dimensioning.cmToPixel(this.__simpleCorner.cornerWidth) / 2,
                Dimensioning.cmToPixel(this.__simpleCorner.cornerDiameterOrLength),
                Dimensioning.cmToPixel(this.__simpleCorner.cornerWidth)
            );
            this.endFill();
        } else {
            // this.beginFill(this.__simpleCorner.borderColor, alpha);
            // this.drawCircle(xOut, yOut, useRadius);
            // this.endFill();
            this.beginFill(this.__simpleCorner.fillColor, alpha);
            this.drawCircle(xOut, yOut, Dimensioning.cmToPixel(this.__simpleCorner.cornerDiameterOrLength) / 2);
            this.endFill();
        }
        this.rotation = this.__simpleCorner.wallAngle;
    }

    // __drawSelectedState() {
    //     super.__drawSelectedState();
    //     let radius = this.__options['corner-radius'];
    //     this.__drawSimpleCornerState(radius, 0x04A9F5, this.simpleCorner.isOpening ? 0xff0000 : 0x00CC00,this.simpleCorner.isOpening ? 1 : 0.3);
    // }
    // __drawHoveredOnState() {
    //     super.__drawHoveredOnState();
    //     let radius = this.__options['corner-radius'] * 1.0;
    //     this.__drawSimpleCornerState(radius, 0x000000, this.simpleCorner.isOpening ? 0xff0000 : 0x00CC00,this.simpleCorner.isOpening ? 1 : 0.3);
    // }
    __drawHoveredOffState() {
        super.__drawHoveredOffState();
        this.__drawSimpleCornerState();
    }

    __updateWithModel() {
        let xx = Dimensioning.cmToPixel(this.__simpleCorner.location.x);
        let yy = Dimensioning.cmToPixel(this.__simpleCorner.location.y);
        this.position.x = xx;
        this.position.y = yy;
    }

    __click(evt) {
        evt.stopPropagation();
        // this.selected = true; //!this.selected;
        // this.__isDragging = true;
        // this.__dragStart(evt);
        // this.__drawSelectedState();
        // if (evt !== undefined) {
        //     evt.stopPropagation();
        // }
    }

    __dragStart(evt) {
        // dragStartOpening.detail.item = this;
        // dragStartOpening.detail.event = evt;
        // document.dispatchEvent(dragStartOpening);
        // super.__dragStart(evt);
    }

    __dragMove(evt) {
        dragMoveOpening.detail.item = this;
        dragMoveOpening.detail.event = evt;
        document.dispatchEvent(dragMoveOpening);
        super.__dragMove(evt);
        if (this.__isDragging) {
            let co = evt.data.getLocalPosition(this.parent);
            let cmCo = new Point(co.x, co.y);

            cmCo.x = Dimensioning.pixelToCm(cmCo.x);
            cmCo.y = Dimensioning.pixelToCm(cmCo.y);

            if (Configuration.getBooleanValue(snapToGrid) || this.__snapToGrid) {
                cmCo.x = Math.floor(cmCo.x / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
                cmCo.y = Math.floor(cmCo.y / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
            }

            if (Configuration.getBooleanValue(dragOnlyX) && !Configuration.getBooleanValue(dragOnlyY)) {
                cmCo.y = this.__simpleCorner.location.y;
            }

            if (!Configuration.getBooleanValue(dragOnlyX) && Configuration.getBooleanValue(dragOnlyY)) {
                cmCo.x = this.__simpleCorner.location.x;
            }

            if (this.__floorplan.boundary) {
                if (this.__floorplan.boundary.containsPoint(cmCo.x, cmCo.y)) {
                    return;
                }
                if (this.__floorplan.boundary.intersectsExternalDesign(cmCo.x, cmCo.y)) {
                    return;
                }
            }
            this.__simpleCorner.move(cmCo.x, cmCo.y);
        }
    }

    __dragEnd(evt) {
        super.__dragEnd(evt);
        this.__floorplan.update();
    }

    __cornerDeleted() {
        this.remove();
        this.__simpleCorner = null;
    }

    __removeFromFloorplan() {
        this.__simpleCorner.remove();
    }

    remove() {
        this.__simpleCorner.removeEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        this.__simpleCorner.removeEventListener(EVENT_MOVED, this.__cornerUpdateEvent);
        super.remove();
    }

    get simpleCorner() {
        return this.__simpleCorner;
    }
}
