import {Dimensioning} from "../../core/dimensioning";
import {Utils} from "../../core/utils";
import {Graphics} from "pixi.js";
import {Vector2} from "three";
import Corner from "../../model/corner";
import {FakeWall2D} from "../viewer2D/FakeWall2D";
import {Environment} from "../environment/Environment";


export class SealingManager {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.optionsDimension = {
            dimlinecolor: '#888',
            dimarrowcolor: '#FFCCCC',
            dimtextcolor: '#FFAA00',
            dimtextalpha: 1,
            reverse: false
        }
        this.drawManager = drawManager;
        this.sealings = [];
        this.hasAlreadySealingCorner = [];
        this.sealingDims = []
        this.sealingVolumeText = [];
    }

    /**
     *
     * @param wall
     * @param corner
     * @param widthSealing pixel
     * @param lengthSealing pixel
     * @param heightSealing pixel
     * @param offset
     * @param isMergeSealing
     * @returns {Graphics}
     */
    drawSealing(wall, corner, widthSealing, lengthSealing, heightSealing, offset = 0, isMergeSealing = false) {
        const normalizedVector = wall.wallDirectionNormalized();
        if (!isMergeSealing) {
            widthSealing = Dimensioning.cmToPixel(corner.widthSealing / 10);
            lengthSealing = Dimensioning.cmToPixel(corner.lengthSealing / 10);
        }

        const posX = Dimensioning.cmToPixel(corner.x) + offset * normalizedVector.x;
        const posY = Dimensioning.cmToPixel(corner.y) + offset * normalizedVector.y;
        let opacity = 0.7;
        let sealing = new Graphics();
        sealing.lineStyle(1, 0x000000, opacity);
        sealing.drawRect(posX, posY - lengthSealing / 2, widthSealing, lengthSealing);
        sealing.endFill();
        sealing.id = corner.id
        sealing.normalizedVector = normalizedVector;
        sealing.corner = corner
        sealing.wall = wall
        // volume en mm3
        sealing.volumeInfo = Dimensioning.pixelToCm(widthSealing) * Dimensioning.pixelToCm(lengthSealing) * Dimensioning.pixelToCm(heightSealing) * 1000;
        this.setBarycenter(sealing, posX, posY, widthSealing);
        sealing.rotation = Math.atan(normalizedVector.y / normalizedVector.x);

        sealing.dimensions = []
        if (this.drawManager.configuratorStarter.drawPanel.displaySealingDimension) {
            let dimensions = this.addSealingDims(normalizedVector, posX, posY, widthSealing, lengthSealing, sealing.id);
            sealing.dimensions.push(dimensions[0]);
            sealing.dimensions.push(dimensions[1]);
        }
        return sealing;
    }

    drawSealingCenter(openingData) {
        if (openingData && openingData.centerSealingLength && openingData.centerSealingWidth) {
            let corner = new Corner(
                this.drawManager.floorplan,
                openingData.corners[1].x + (openingData.openingSize / 2) * openingData.wallPortal.wallDirectionNormalized().x,
                openingData.corners[1].y + (openingData.openingSize / 2) * openingData.wallPortal.wallDirectionNormalized().y,
                "1"
            )
            corner.lengthSealing = openingData.centerSealingLength;
            corner.widthSealing = openingData.centerSealingWidth;
            corner.heightSealing = openingData.centerSealingHeight;

            this.drawManager.sealingManager.addSealing(openingData.wallPortal, corner);
            const options = {
                normalizeVector: openingData.wallPortal.wallDirectionNormalized(),
                posX: Dimensioning.cmToPixel(corner.x),
                posY: Dimensioning.cmToPixel(corner.y),
                width: 6,
                height: 4,
                color: openingData.corners[1].fillColor,
                opacity: Environment.drawConfiguration.wall.fakeWall.opacity
            }
            let center = (new FakeWall2D(options)).drawFakeWall();
            this.drawManager.sealingManager.sealingDims.push(center);
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(center);
            corner.remove();
        }
    }

    getAdjustTextRotation(wall) {
        let rotation = 0
        if (wall.wallDirectionNormalized().x < 0 && wall.wallDirectionNormalized().y < 0 ||
            wall.wallDirectionNormalized().x < 0 && wall.wallDirectionNormalized().y >= 0
        ) {
            rotation = Math.PI
        }
        return rotation;
    }

    addSealingDims(normalizedVector, x, y, width, length) {
        let dimensions = []
        let x1 = Dimensioning.pixelToCm(x - width / 2 * normalizedVector.x);
        let y1 = Dimensioning.pixelToCm(y - width / 2 * normalizedVector.y);
        let corner1 = new Corner(this.drawManager.floorplan, x1, y1, "1");
        let corner2 = new Corner(this.drawManager.floorplan, x1 + width * 2 * normalizedVector.x, y1 + width * 2 * normalizedVector.y, "2");
        this.optionsDimension.offset = length + 20;
        dimensions.push(this.drawManager.dimensionManager.addDimension(corner1, corner2, this.optionsDimension, this.sealingDims));
        corner1.remove();
        corner2.remove();

        let vectorPerpendicular = new Vector2(-normalizedVector.y, normalizedVector.x);
        let x2 = Dimensioning.pixelToCm(x - length / 2 * vectorPerpendicular.x);
        let y2 = Dimensioning.pixelToCm(y - length / 2 * vectorPerpendicular.y);
        let corner3 = new Corner(this.drawManager.floorplan, x2, y2, "3");
        let corner4 = new Corner(this.drawManager.floorplan, x2 + length * 2 * vectorPerpendicular.x, y2 + length * 2 * vectorPerpendicular.y, "4");
        this.optionsDimension.offset = width + 20;
        dimensions.push(this.drawManager.dimensionManager.addDimension(corner3, corner4, this.optionsDimension, this.sealingDims));
        corner3.remove();
        corner4.remove();
        return dimensions;
    }


    /**
     * Ajoute le gruge de scellement
     * @param {Wall} wall
     * @param {Corner|SimpleCorner} corner
     */
    addSealing(wall, corner) {
        if (corner.lengthSealing !== null && corner.widthSealing !== null && !this.hasAlreadySealingCorner.includes(corner)) {
            let sealing = this.drawManager.sealingManager.drawSealing(
                wall,
                corner,
                Dimensioning.cmToPixel(corner.widthSealing/10),
                Dimensioning.cmToPixel(corner.lengthSealing/10),
                Dimensioning.cmToPixel(corner.heightSealing/10)
            );
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(sealing);
            this.sealings.push(sealing);
            this.hasAlreadySealingCorner.push(corner);
        }
    }

    checkMergeSealings(wall) {
        let sealingAlreadyMerge = [];
        // On en premier fusionne les portails
        this.sealings.forEach((sealing1) => {
            this.sealings.forEach((sealing2) => {
                if (sealing1.corner.id !== sealing2.corner.id &&
                    (
                        sealing1.corner.isOutsideOpeningCornerEnd && sealing2.corner.isInsideOpeningCornerEnd ||
                        sealing1.corner.isOutsideOpeningCornerStart && sealing2.corner.isInsideOpeningCornerStart
                    )
                ) {
                    if (this.intersectSealing(sealing1, sealing2) &&
                        (!sealingAlreadyMerge.includes(sealing1.corner.id + '@' + sealing2.corner.id) && !sealingAlreadyMerge.includes(sealing2.corner.id + '@' + sealing1.corner.id))
                    ) {
                        sealingAlreadyMerge.push(sealing2.corner.id + '@' + sealing1.corner.id);
                        sealingAlreadyMerge.push(sealing1.corner.id + '@' + sealing2.corner.id);
                        this.mergeSealings(wall, sealing1, sealing2);
                    }
                }
            })
        })
        sealingAlreadyMerge = [];
        // On fusionne les scellement du segment
        this.sealings.forEach((sealing1) => {
            this.sealings.forEach((sealing2) => {
                if (
                    sealing1.corner.id !== sealing2.corner.id &&
                    sealing1.wall === sealing2.wall
                ) {
                    if (this.intersectSealing(sealing1, sealing2) && !sealingAlreadyMerge.includes([sealing1, sealing2])) {
                        sealingAlreadyMerge.push(sealing1.corner.id + '@' + sealing2.corner.id);
                        sealingAlreadyMerge.push(sealing2.corner.id + '@' + sealing1.corner.id);
                        this.mergeSealings(wall, sealing1, sealing2);
                    }
                }
            })
        })
    }

    mergeSealings(wall, sealing1, sealing2) {
        const boundsSealing1 = sealing1.getLocalBounds();
        const boundsSealing2 = sealing2.getLocalBounds();
        const firstSealing = this.getFirstSealingFromWallStart(wall, sealing1, sealing2);


        let newWidth = Dimensioning.cmToPixel(Utils.distance(sealing1.corner, sealing2.corner)) + boundsSealing1.width / 2 + boundsSealing2.width / 2;
        let newLength = Math.max(boundsSealing1.height, boundsSealing2.height);
        let newHeight = Math.max(Dimensioning.cmToPixel(sealing1.corner.heightSealing/10), Dimensioning.cmToPixel(sealing2.corner.heightSealing/10));

        let mergedSealing = this.drawSealing(wall, firstSealing.corner, newWidth, newLength, newHeight, newWidth / 2 - firstSealing.width / 2, true);
        this.removeSealingDimension(sealing1);
        this.removeSealingDimension(sealing2);
        this.removeSealingVolumeText(sealing1);
        this.removeSealingVolumeText(sealing2);
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(sealing1);
        this.sealings.splice(this.drawManager.sealingManager.sealings.indexOf(sealing1), 1);
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(sealing2);
        this.sealings.splice(this.drawManager.sealingManager.sealings.indexOf(sealing2), 1);
        this.sealings.push(mergedSealing)
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(mergedSealing);
    }

    removeSealingDimension(sealing) {
        sealing.dimensions.forEach((dimension) => {
            this.sealingDims.splice(this.sealingDims.indexOf(dimension), 1);
        })
    }

    removeSealingVolumeText(sealing) {
        this.sealingVolumeText.forEach((textField) => {
            if(textField === sealing.volumeText){
                this.sealingVolumeText.splice(this.sealingDims.indexOf(textField), 1);
                this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(textField);
            }
        })
    }


    getFirstSealingFromWallStart(wall, sealing1, sealing2) {
        let distFromWallStart1 = Utils.distance(wall.start, sealing1.corner)
        let distFromWallStart2 = Utils.distance(wall.start, sealing2.corner)
        let firstSealing = null;
        if ((sealing1.corner.isInsideOpeningCornerStart && sealing2.corner.isOutsideOpeningCornerStart) ||
            (sealing2.corner.isInsideOpeningCornerEnd && sealing1.corner.isOutsideOpeningCornerEnd)
        ) {
            firstSealing = sealing2;
        }
        if ((sealing2.corner.isInsideOpeningCornerStart && sealing1.corner.isOutsideOpeningCornerStart) ||
            (sealing1.corner.isInsideOpeningCornerEnd && sealing2.corner.isOutsideOpeningCornerEnd)
        ) {
            firstSealing = sealing1;
        }
        if (firstSealing === null && distFromWallStart2 < distFromWallStart1) {
            firstSealing = sealing2;
        }
        if (firstSealing === null && distFromWallStart2 > distFromWallStart1) {
            firstSealing = sealing1;
        }
        return firstSealing;
    }

    /**
     * Nettoie la liste et l'affichage du gruge plus petit
     * @param {Wall} wall
     */
    deleteLowestSealing(wall) {
        this.hasAlreadySealingCorner.forEach((corner) => {
            let highestWallCorner = this.drawManager.mainCorner2D.getHighestMainCornerOnWall(wall, corner);
            if (wall.start.id === corner.id && wall.cornerHeightType > highestWallCorner) {
                this.sealings.forEach((sealing) => {
                    if (corner.id === sealing.id) {
                        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(sealing);
                        this.hasAlreadySealingCorner.splice(this.hasAlreadySealingCorner.indexOf(corner), 1);
                    }
                })
            }
            if (wall.end.id === corner.id && wall.cornerHeightType > highestWallCorner) {
                this.sealings.forEach((sealing) => {
                    if (corner.id === sealing.id) {
                        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(sealing);
                        this.hasAlreadySealingCorner.splice(this.hasAlreadySealingCorner.indexOf(corner), 1);
                    }
                })
            }
        })
    }

    /**
     * Mise à zéro des éléments fake
     */
    resetSealings() {
        this.sealings.forEach((sealing) => {
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(sealing);
        })
        this.sealingVolumeText.forEach((textField) => {
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(textField);
        })
        this.sealings = [];
        this.sealingVolumeText = [];
        this.hasAlreadySealingCorner = [];
    }

    intersectSealing(sealing1, sealing2) {
        return Dimensioning.cmToPixel(Utils.distance(sealing1.corner, sealing2.corner)) < (sealing1.width / 2 + sealing2.width / 2);
    }

    /**
     *
     * @param {Graphics} sealing
     * @param {number} posX in pixel
     * @param {number} posY in pixel
     * @param {number} widthSealing in pixel
     */
    setBarycenter(sealing, posX, posY, widthSealing) {
        sealing.pivot.x = posX + widthSealing / 2;
        sealing.pivot.y = posY;
        sealing.position.x = posX;
        sealing.position.y = posY;
    }
}
