import {Environment} from "../environment/Environment";

export class WallConfigData {

    constructor(drawManager) {
        this.drawManager = drawManager;
        this.wallsConfigData = {}
    }

    /**
     * Ajoute la configuration d'un Wall
     * @param {Wall} wall
     * @returns {object}
     */
    addNewWallConfig(wall) {
        if (!this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id]) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id] = JSON.parse(JSON.stringify(Environment.globalConfigurationEnv.wallDataInit));
        }
        return this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id];
    }

    /**
     * Remise à zéro de la configuration d'un segment
     * @param wall
     */
    resetWallConfig(wall) {
        if (wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id in this.wallsConfigData) {
            const wallConfigData = this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id];
            wallConfigData.fenceTypeName = null;
            wallConfigData.fenceTypeCode = null;
            wallConfigData.fillingTypeName = null;
            wallConfigData.fillingTypeCode = null;
            wallConfigData.netType = null;
            wallConfigData.fenceProductType = null;
            wallConfigData.panelType = null;
            wallConfigData.cornerHeightType = null;
            wallConfigData.railLength = null;
            wallConfigData.slideLength = null;
            wallConfigData.netProductChoice = null;
            wallConfigData.fenceProductChoice = null;
            wallConfigData.panelProductChoice = null;
            wallConfigData.netStiffenerColor = null;
            wallConfigData.plugColor = null;
            wallConfigData.intermediateCornerReverse = false;
            wallConfigData.netAbovePortal = false;
            wallConfigData.sameNetIsPossible = false;
            wallConfigData.fakeWallColor = Environment.drawConfiguration.wall.fakeWall.defaultColor;
        }

    }

    /**
     * Supprime la configuration d'un segment
     * @param {Wall} wall
     */
    deleteWallConfig(wall) {
        Reflect.deleteProperty(this.wallsConfigData, wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id);
    }

    /**
     * retourne la configuration du mur si elle existe
     * @param {Wall} wall
     * @returns {object|null}
     */
    getWallConfigData(wall) {
        if (this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id]) {
            return this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id];
        }
        return null;
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigFenceProductType(wall) {
        if (wall.fenceProductType === Environment.wallPanelEnv.defaultInputMessage.FenceProductType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductType = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductType = wall.fenceProductType;
        }
    }

    /**
     * mise à jour de l'attribut cornerHeightType de Wall
     * @param {Wall} wall
     */
    setConfigCornerHeightType(wall) {
        if (wall.cornerHeightType === Environment.wallPanelEnv.defaultInputMessage.CornerHeightType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].cornerHeightType = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].cornerHeightType = wall.cornerHeightType;
        }
    }

    /**
     * mise à jour de l'attribut cornerHeightType de Wall
     * @param {Wall} wall
     * @param {boolean} value
     */
    toggleConfigIntermediateCornerReverse(wall, value) {
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].intermediateCornerReverse = value;
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigFillingTypeName(wall) {
        if (wall.fillingTypeName === Environment.wallPanelEnv.defaultInputMessage.FillingType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeName = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeName = wall.fillingTypeName;
        }
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigFillingTypeCode(wall) {
        if (wall.fillingTypeName === Environment.wallPanelEnv.defaultInputMessage.FillingType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeCode = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeCode = wall.fillingTypeCode;
        }
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigNetStiffener(wall) {
        if (wall.netStiffenerColor === null) {
            wall.netStiffenerColor = Environment.dataEcommerceEnv.sets.defaultColor.netStiffener;
        }
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netStiffenerColor = wall.netStiffenerColor;
    }

    /**
     * @param {Wall} wall
     */
    setNetAbovePortal(wall){
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netAbovePortal = wall.netAbovePortal;
    }

    /**
     * @param {Wall} wall
     */
    setSameNetIsPossible(wall){
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].sameNetIsPossible = wall.sameNetIsPossible;
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigFenceStiffener(wall) {
        if (wall.netStiffenerColor === null) {
            wall.netStiffenerColor = Environment.dataEcommerceEnv.sets.defaultColor.fenceStiffener;
        }
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netStiffenerColor = wall.netStiffenerColor;
    }

    /**
     *
     * @param {Wall} wall
     */
    setConfigPlug(wall) {
        if (wall.plugColor === null) {
            wall.plugColor = Environment.dataEcommerceEnv.sets.defaultColor.plug;
        }
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].plugColor = wall.plugColor;
    }

    /**
     * @param {Wall} wall
     */
    setConfigNetType(wall) {
        if (wall.netType === Environment.wallPanelEnv.defaultInputMessage.NetType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netType = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netType = wall.netType;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigFenceTypeName(wall) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeName = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeName = wall.fenceTypeName;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigFenceTypeCode(wall) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeCode = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeCode = wall.fenceTypeCode;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigNetProductChoice(wall) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netProductChoice = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netProductChoice = wall.netProductChoice;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigFenceProductChoice(wall) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductChoice = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductChoice = wall.fenceProductChoice;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigPanelProductChoice(wall) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelProductChoice = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelProductChoice = wall.panelProductChoice;
        }
    }

    setFakeWallColor(wall, init = false) {
        if (wall.fenceTypeName === Environment.wallPanelEnv.defaultInputMessage.FenceType || init) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fakeWallColor = Environment.drawConfiguration.wall.fakeWall.defaultColor;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fakeWallColor = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeFakeWallColor[wall.fenceTypeCode].base;
        }
    }

    /**
     * @param {Wall} wall
     */
    setConfigPanelType(wall) {
        if (wall.netType === Environment.wallPanelEnv.defaultInputMessage.PanelType) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelType = null;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelType = wall.panelType;
        }
    }

    /**
     * @param {Wall} wall
     * @param {number} value
     */
    setConfigRailLength(wall, value) {
        this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].railLength = value;
        wall.railLength = value;
    }

    /**
     * @param {Wall} wall
     * @param {number} value
     */
    setConfigSlideLength(wall, value) {
        if (value === Environment.wallPanelEnv.defaultInputMessage.slideLength) {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].slideLength = Environment.dataEcommerceEnv.handRail.slideLengthMini;
            wall.centerDistance = Environment.dataEcommerceEnv.handRail.slideLengthMini;
        } else {
            this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].slideLength = value;
            wall.centerDistance = value;
        }
    }

    /**
     * Mise à jour de la configuration sur chaque segment du dessin
     */
    updateWallsConfigDataOnFenceElements() {
        const fenceElements = this.drawManager.fenceElementManager.getFenceElements();
        fenceElements.forEach((fenceElement) => {
            for (const key of Object.keys(fenceElement)) {
                if (key.startsWith(Environment.globalConfigurationEnv.mainCornerSectionName)) {
                    fenceElement[key].mainWalls.forEach((wall) => {
                        this.setWallConfig(wall)
                    })
                    fenceElement[key].openingsWalls.forEach((wall) => {
                        this.setWallConfig(wall)
                    })
                }
            }
        })

    }

    /**
     * Mise à jour de la config du segment
     * @param {Wall} wall
     */
    setWallConfig(wall) {
        if (wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id in this.wallsConfigData) {
            const wallConfigData = this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id];
            wall.fenceTypeName = wallConfigData.fenceTypeName;
            wall.fenceTypeCode = wallConfigData.fenceTypeCode;
            wall.fillingTypeName = wallConfigData.fillingTypeName;
            wall.fillingTypeCode = wallConfigData.fillingTypeCode;
            wall.netType = wallConfigData.netType;
            wall.fenceProductType = wallConfigData.fenceProductType;
            wall.panelType = wallConfigData.panelType;
            wall.cornerHeightType = wallConfigData.cornerHeightType;
            wall.railLength = wallConfigData.railLength;
            wall.netProductChoice = wallConfigData.netProductChoice;
            wall.fenceProductChoice = wallConfigData.fenceProductChoice;
            wall.panelProductChoice = wallConfigData.panelProductChoice;
            wall.netStiffenerColor = wallConfigData.netStiffenerColor;
            wall.plugColor = wallConfigData.plugColor;
            wall.netAbovePortal = wallConfigData.netAbovePortal;
            wall.sameNetIsPossible = wallConfigData.sameNetIsPossible;
            if (wallConfigData.slideLength !== null) {
                wall.centerDistance = wallConfigData.slideLength;
            }
            wall.intermediateCornerReverse = wallConfigData.intermediateCornerReverse;
            wall.fakeWallColor = wallConfigData.fakeWallColor;


            // Mise à jour des données
            if (wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main && wall.cornerHeightType > 2) {
                const technicalInfo = this.drawManager.jsonDataManager.getTechnicalInfoBallScreen(wall);
                if (!technicalInfo) {
                    alert(Environment.drawConfiguration.main.errorMessage.technicalInfoNotFound);
                } else {
                    wall.centerDistance = technicalInfo.centerDistance / 10;
                }

            }
            if (wall.fenceTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.main && wall.fillingTypeCode !== null && wall.railLength !== null) {
                const technicalInfo = this.drawManager.jsonDataManager.getTechnicalInfoHandRail(wall.fillingTypeCode, wall.railLength);
                if (!technicalInfo) {
                    alert(Environment.drawConfiguration.main.errorMessage.technicalInfoNotFound);
                } else {
                    wall.centerDistance = technicalInfo.centerDistance / 10;
                }

            }
        }
    }

    /**
     * Copie les données de configuration d'un segment pour une segment ciblé
     * @param {Wall} wall
     * @param {Wall} wallTarget
     */
    copyAndSetWallConfig(wall, wallTarget) {
        const wallConfigData = this.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id];
        this.addNewWallConfig(wallTarget);
        const wallTargetConfigData = this.wallsConfigData[wallTarget.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wallTarget.end.id];
        wallTargetConfigData.fenceTypeName = wallConfigData.fenceTypeName;
        wallTargetConfigData.fenceTypeCode = wallConfigData.fenceTypeCode;
        wallTargetConfigData.fillingTypeName = wallConfigData.fillingTypeName;
        wallTargetConfigData.fillingTypeCode = wallConfigData.fillingTypeCode;
        wallTargetConfigData.netType = wallConfigData.netType;
        wallTargetConfigData.fenceProductType = wallConfigData.fenceProductType;
        wallTargetConfigData.panelType = wallConfigData.panelType;
        wallTargetConfigData.cornerHeightType = wallConfigData.cornerHeightType;
        wallTargetConfigData.railLength = wallConfigData.railLength;
        wallTargetConfigData.netProductChoice = wallConfigData.netProductChoice;
        wallTargetConfigData.fenceProductChoice = wallConfigData.fenceProductChoice;
        wallTargetConfigData.panelProductChoice = wallConfigData.panelProductChoice;
        wallTargetConfigData.openingConfigData = wallConfigData.openingConfigData;
        wallTargetConfigData.netStiffenerColor = wallConfigData.netStiffenerColor;
        wallTargetConfigData.plugColor = wallConfigData.plugColor;
        wallTargetConfigData.intermediateCornerReverse = wallConfigData.intermediateCornerReverse;
        if (!wallTarget.isPortal) {
            wallTargetConfigData.fakeWallColor = wallConfigData.fakeWallColor;
        }
        if (wallTarget.isPortal) {
            wallTargetConfigData.fakeWallColor = wallTarget.fakeWallColor;
        }
        this.setWallConfig(wallTarget);
    }
}
