import {Environment} from "../environment/Environment";

export class ImageScalingPanel {



    /**
     *
     * @param quickSettings
     * @param {DrawManager} drawManager
     */
    constructor(quickSettings, drawManager) {
        this.app_parent = document.getElementById('bp3d-js-app');
        this.quickSettings = quickSettings;
        this.settingPanel = this.quickSettings.create(150, 0, Environment.scalePanelEnv.title, this.app_parent);
        this.drawManager = drawManager;
        this.distanceInCmToSegment = null;
    }

    /**
     * Création du panneau settings de départ
     */
    createStartControl() {
        this.settingPanel.setPosition(Environment.scalePanelEnv.defaultInputValue.panelX, Environment.scalePanelEnv.defaultInputValue.panelY);
        this.settingPanel.addNumber(
            Environment.scalePanelEnv.titleInput.distance,
            Environment.scalePanelEnv.defaultInputValue.minValue,
            Environment.scalePanelEnv.defaultInputValue.maxValue,
            Environment.scalePanelEnv.defaultInputValue.distanceValue,
            Environment.scalePanelEnv.defaultInputValue.stepValue,
            this.setDistanceInCmToSegment.bind(this));
        this.settingPanel.addButton(Environment.scalePanelEnv.button.cancel, this.hidePanel.bind(this));
        this.settingPanel.addButton(Environment.scalePanelEnv.button.confirm, this.setNewScale.bind(this));
        this.settingPanel.hide();
    }

    setDistanceInCmToSegment(e) {
        this.distanceInCmToSegment = e * 100;
    }

    setNewScale() {
        this.drawManager.scale = 2 * this.drawManager.imageScaling.distanceInPixelToSegment/this.distanceInCmToSegment;
        let result = Math.round(this.distanceInCmToSegment/this.drawManager.imageScaling.distanceInPixelToSegment * 10)/10
        this.drawManager.configuratorStarter.drawPanel.quickSettingsHelper.updateTextSectionTitle(Environment.drawPanelEnv.subtitleScaling.id,Environment.drawPanelEnv.subtitleScaling.text(result));
        document.querySelector('#' + Environment.drawPanelEnv.button.updateScale.id).removeAttribute("disabled");
        this.settingPanel.hide();
        this.drawManager.imageScaling.setPositionImageInRelationToOrigin();
        this.drawManager.imageScaling.resetImageScaling();
        this.drawManager.drawFence();
    }

    hidePanel() {
        this.settingPanel.hide();
        this.drawManager.imageScaling.resetImageScaling();
        document.querySelector('#' + Environment.drawPanelEnv.button.updateScale.id).removeAttribute("disabled");
    }
}
