import {Environment} from "../../environment/Environment";

export class FenceDataSet {

    constructor(products, accessoryConfigData) {
        this.products = products;
        this.accesoryConfigData = accessoryConfigData;
        this.wallLengthTotal = {};
        this.tensionCableTotal = {};
    }

    addFenceSet(wall) {
        // tensionBar: {code: 'longueur_barre_2_m', quantity: 2}
        const codeFence = this.getCodeFence(wall);
        const newLength = this.getLength(wall);
        const newTensionCableLength = this.getNewTensionCableLength(wall);
        const quantityExtraStrut = this.accesoryConfigData.getQuantityExtraStrut(wall, newLength);
        this.setWallLengthTotal(codeFence, newLength);
        this.setTensionCableTotal(codeFence, newTensionCableLength);
        // Ajout des rouleaux de grillage
        this.addRollerAccessory(codeFence, this.getFenceRollerNeeded(codeFence));
        // Ajout des raidisseurs
        this.accesoryConfigData.addAccessory(this.getCodeStiffener(codeFence), this.getStiffenerQuantity(newLength,quantityExtraStrut));
        // Ajout du fil de tension
        this.accesoryConfigData.addAccessory(this.getTensionCableCode(codeFence), this.getTensionCableQuantity(codeFence));
        // Ajout des bar de tension
        this.accesoryConfigData.setAccessory(this.getTensionBarCode(codeFence), this.getTensionBarQuantity(quantityExtraStrut));
    }

    // grillage
    getCodeFence(wall) {
        return wall.fenceProductChoice.code
    }

    getFenceRollerNeeded(code) {
        return Math.ceil(this.wallLengthTotal[code] / Environment.sparePartsEnv.rollerLength[code]);
    }

    addRollerAccessory(code, fenceRollerNeeded) {
        let rollerAccessory = this.accesoryConfigData.getAccessory(code);
        if (rollerAccessory) {
            rollerAccessory.quantity = fenceRollerNeeded;
        } else {
            this.accesoryConfigData.addAccessory(code, fenceRollerNeeded);
        }
    }

    getLength(wall) {
        return wall.wallLength() / 100;
    }

    // cable tenseur
    getNewTensionCableLength(wall) {
        return this.getLength(wall) * (Environment.sparePartsEnv.cloture2m.stiffener.defaultQuantity / 2)
    }

    getTensionCableCode(codeFence) {
        // diamètre du fil du grillage
        const fenceThreadDiameter = Environment.sparePartsEnv.fenceThreadDiameter[codeFence]
        return Environment.sparePartsEnv.cloture2m.tensionCable[fenceThreadDiameter].code;
    }

    getTensionCableQuantity(codeFence) {
        return Math.ceil(this.tensionCableTotal[codeFence] / this.getTensionCableLinearMeter(codeFence))
    }

    getTensionCableLinearMeter(codeFence) {
        // diamètre du fil du grillage
        const fenceThreadDiameter = Environment.sparePartsEnv.fenceThreadDiameter[codeFence]
        return Environment.sparePartsEnv.cloture2m.tensionCable[fenceThreadDiameter].linearMeter;
    }

    // raidisseur
    getStiffenerQuantity(length, quantityExtraStrut) {
        let quantityStiffener = Environment.sparePartsEnv.cloture2m.stiffener.defaultQuantity;
        if (quantityExtraStrut > 0) {
            quantityStiffener += (quantityExtraStrut + 1) * Environment.sparePartsEnv.cloture2m.stiffener.defaultQuantity;
        }
        return quantityStiffener;
    }

    getCodeStiffener(codeFence) {
        // diamètre du fil du grillage
        const fenceThreadDiameter = Environment.sparePartsEnv.fenceThreadDiameter[codeFence]
        return Environment.sparePartsEnv.cloture2m.stiffener[fenceThreadDiameter]['green'].code;
    }

    // barre de tension

    getTensionBarCode(codeFence) {
        // diamètre du fil du grillage
        const fenceThreadDiameter = Environment.sparePartsEnv.fenceThreadDiameter[codeFence]
        return Environment.sparePartsEnv.cloture2m.tensionBar[fenceThreadDiameter].code;
    }

    getTensionBarQuantity(quantityExtraStrut) {
        let quantityTensionBar = 2;
        if (quantityExtraStrut > 0) {
            quantityTensionBar += quantityExtraStrut * 2;
        }
        return quantityTensionBar;
    }

    // setters

    setWallLengthTotal(code, length) {
        if (this.wallLengthTotal.hasOwnProperty(code)) {
            this.wallLengthTotal[code] += length;
        } else {
            this.wallLengthTotal[code] = length;
        }
    }

    setTensionCableTotal(code, length) {
        if (this.tensionCableTotal.hasOwnProperty(code)) {
            this.tensionCableTotal[code] += length;
        } else {
            this.tensionCableTotal[code] = length;
        }
    }
}
