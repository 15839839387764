import FPS from 'fps-now';

import {BlueprintJS} from './scripts/blueprint.js';
import {
    EVENT_LOADED,
    EVENT_NOTHING_2D_SELECTED,
    EVENT_CORNER_2D_CLICKED,
    EVENT_WALL_2D_CLICKED,
} from './scripts/core/events.js';
import {
    Configuration,
    configDimUnit,
    viewBounds,
} from './scripts/core/configuration.js';
import {dimMeter} from './scripts/core/constants.js';
import QuickSettings from 'quicksettings';

import {ConfiguratorStarter} from "./scripts/clomo/ConfiguratorStarter";

const fps = FPS.of({x: 0, y: 0});
fps.start();
let blueprint3d;

let configurationHelper;
let floorplanningHelper;


let opts = {
    viewer2d: {
        id: 'bp3djs-viewer2d',
        viewer2dOptions: {
            'line-wall-thickness': 5,
            'corner-radius': 7,
            'coefficient-corner-outer-radius': 1.5,

            'intermediate-corner-fix-color-in': '0x00FF00',
            'intermediate-corner-fix-color-out': '0xFFFFFF',
            'intermediate-corner-fix-alpha-out': 1,

            'intermediate-corner-opening-fix-color-in': '0xFF0000',
            'intermediate-corner-opening-fix-color-out': '0xFFFFFF',
            'intermediate-corner-opening-fix-alpha-out': 1,

            'main-corner-fix-color-in': '0x0000FF',
            'main-corner-fix-color-out': '0xFFFFFF',
            'main-corner-move-color-out': '0x04A9F5',
            'main-corner-move-color-in': '0x049995',
            'main-corner-hover-color-out': '0x04A9F5',
            'main-corner-hover-color-in': '0x049995',
            'main-corner-alpha-out': 1,

            'wall-visible-color': '0xCCCCCC',

            'boundary-point-radius': 5.0,
            'boundary-line-thickness': 2.0,
            'boundary-point-color': '#030303',
            'boundary-line-color': '#00ff00',
            pannable: true,
            zoomable: true,
            scale: false,
            rotate: true,
            translate: true,
            dimAlpha: 1,
            pixiAppOptions: {
                resolution: 1,
            },
            pixiViewportOptions: {
                passiveWheel: false,
            }
        },
    },
    viewer3d: {
        id: 'bp3djs-viewer3d',
        viewer3dOptions: {
            occludedWalls: false,
            occludedRoofs: false
        }
    },
    textureDir: "models/textures/",
    widget: false,
    resize: true,
};

console.log('ON DOCUMENT READY ');

Configuration.setValue(viewBounds, 30000);//In CMS

blueprint3d = new BlueprintJS(opts);
Configuration.setValue(configDimUnit, dimMeter);

configurationHelper = blueprint3d.configurationHelper;
floorplanningHelper = blueprint3d.floorplanningHelper;

blueprint3d.model.addEventListener(EVENT_LOADED, function () {
    console.log('LOAD SERIALIZED JSON ::: ');
});
blueprint3d.floorplanner.addFloorplanListener(EVENT_NOTHING_2D_SELECTED, function () {
    //settingsViewer2d.hideControl('Delete');
});
blueprint3d.floorplanner.addFloorplanListener(EVENT_CORNER_2D_CLICKED, function () {
    // settingsViewer2d.showControl('Delete');
});
blueprint3d.floorplanner.addFloorplanListener(EVENT_WALL_2D_CLICKED, function () {
    //settingsViewer2d.showControl('Delete');
});

if (!opts.widget) {

    // DEBUT CLOMO CONFIGURATOR
    /******************************* ********************************** *************************************************************/
    /******************************* INITIALISATION CONFIGURATEUR CLOMO *************************************************************/
    /******************************* ********************************** *************************************************************/
    (new ConfiguratorStarter(QuickSettings, blueprint3d, opts)).init();
    /******************************* ********************************** *************************************************************/
    /******************************* ********************************** *************************************************************/
    /******************************* ********************************** *************************************************************/
    // FIN

    // settingsViewer2d = QuickSettings.create(0, 0, 'Viewer 2D', app_parent);
    // settingsViewer2d.collapse();
    //
    // settingsViewer2d.addButton('Draw Mode', switchViewer2DToDraw);
    // settingsViewer2d.addButton('Move Mode', switchViewer2DToMove);
    // settingsViewer2d.addButton('Transform Mode', switchViewer2DToTransform);
    // settingsViewer2d.addButton('Delete', floorplanningHelper.deleteCurrentItem.bind(floorplanningHelper));
    //
    // settingsViewer2d.bindBoolean('snapToGrid', configurationHelper.snapToGrid, configurationHelper);
    // settingsViewer2d.bindBoolean('directionalDrag', configurationHelper.directionalDrag, configurationHelper);
    // settingsViewer2d.bindBoolean('dragOnlyX', configurationHelper.dragOnlyX, configurationHelper);
    // settingsViewer2d.bindBoolean('dragOnlyY', configurationHelper.dragOnlyY, configurationHelper);
    // settingsViewer2d.bindRange('snapTolerance', 1, 200, configurationHelper.snapTolerance, 1, configurationHelper);
    // settingsViewer2d.bindRange('gridSpacing', 10, 200, configurationHelper.gridSpacing, 1, configurationHelper);
    // settingsViewer2d.bindNumber('boundsX', 1, 200, configurationHelper.boundsX, 1, configurationHelper);
    // settingsViewer2d.bindNumber('boundsY', 1, 200, configurationHelper.boundsY, 1, configurationHelper);
    //
    // settingsViewer2d.hideControl('Delete');
    // settingsViewer2d.setWidth(panelWidths);
    // settingsViewer2d.setHeight(subPanelsHeight);
    // settingsViewer2d.setPosition(panelWidths * 2, 0);

}
