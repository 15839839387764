export class PanelDataSet {

    constructor(products, accessoryConfigData) {
        this.products = products;
        this.accesoryConfigData = accessoryConfigData;
    }

    addPanelSet(wall) {
        const code = wall.panelProductChoice.code;
        const quantity = Math.ceil(wall.wallLength()/wall.centerDistance);
        console.log(quantity)
        console.log(wall.centerDistance)
        this.accesoryConfigData.addAccessory(code, quantity);
    }
}
