import Wall from "../../model/wall";
import {FakeWall2D} from "./FakeWall2D";
import {Dimensioning} from "../../core/dimensioning";
import {WallDimensions2D} from "../../viewer2d/WallView2D";
import {wallClicked} from "../environment/Event";
import Corner from "../../model/corner";
import {Environment} from "../environment/Environment";

export class DrawWall2D {

    /**
     * @param {DrawManager} drawManager
     */
    constructor(drawManager) {
        this.optionsDImension = {
            dimlinecolor: '#808',
            dimarrowcolor: '#FFCCCC',
            dimtextcolor: '#FFAA00',
            dimtextalpha: 1,
            reverse: true,
            offset: Environment.drawConfiguration.main.cotation.step2
        };
        this.drawManager = drawManager;
        this.grugeVisible = true;
        this.fakeWalls = [];
        this.fakeWallDims = [];
        this.wallBases = [];
        this.fakeGrugeWalls = [];
        this.scale = 1;
    }

    /**
     * @param fenceElement
     * @param {Corner|SimpleCorner} corner1
     * @param {Corner|SimpleCorner} corner2
     * @param {string} cornerBaseName
     * @param wallNumber
     * @param idOpening
     * @param {boolean} isMainWall
     * @param {boolean} isVisible
     * @param fakeWall
     */
    addDrawWall(fenceElement, corner1, corner2, cornerBaseName, wallNumber = null, idOpening = null, isMainWall = false, isVisible = true, fakeWall = false) {
        let wall;
        if (fakeWall) {
            wall = new Wall(corner1, corner2, null, null);
        } else {
            wall = this.drawManager.getFloorplan().newWall(corner1, corner2, null, null);
            this.wallBases.push(wall)
        }
        wall.id = "wall-" + wallNumber + ",idOpening-" + idOpening + ",id-" + cornerBaseName
        wall.belongToMainCornerSection = cornerBaseName;
        if (isMainWall) {
            wall.isMainWall = true;
            fenceElement[cornerBaseName].mainWalls = [...fenceElement[cornerBaseName].mainWalls, wall];
        } else {
            fenceElement[cornerBaseName].openingsWalls.push(wall);
        }
        wall.belongToMainWallKey = fenceElement[cornerBaseName].mainWalls.length - 1;
        if (!isVisible) {
            wall.visible = false;
        }
        wall.noDimension = !this.drawManager.configuratorStarter.drawPanel.displayWallBaseDimension
        wall.belongToFenceElement = fenceElement.name;
        this.drawManager.wallConfigManager.setWallConfig(wall);
        return wall;
    }

    /**
     * Met en place les éléments fake demandés pour un mur ciblé
     * @param fenceElement
     * @param {Wall} wall
     * @param {boolean} withDimension
     * @param {boolean} withGruge
     * @param {object} openingData
     */
    addFakeWallSet(fenceElement, wall, openingData = null, withDimension = true, withGruge = true) {
        this.addFakeWall(fenceElement, wall, openingData);
        if (!openingData) {
            if (withDimension && this.drawManager.configuratorStarter.drawPanel.displayOpeningDimension) {
                this.drawManager.dimensionManager.addDimension(wall.start, wall.end, this.optionsDImension, this.fakeWallDims)
            }
            if (withGruge) {
                this.addGrugeFakeWall(fenceElement.cornerRadius, wall);
            }
        }

    }

    /**
     * Dessine un trait et sa côte
     * @param fenceElement
     * @param {Wall} wall
     * @param {object} openingData
     */
    addFakeWall(fenceElement, wall, openingData = null) {
        let leafGate = openingData !== null ? openingData.gateLeavesNumber : null;
        const reverse = openingData !== null ? openingData.reverse : false;
        const pivot = openingData !== null ? openingData.pivotLeft : false;

        if (openingData && openingData.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            let color = 0xff0000;
            if ((!reverse && wall === openingData.wallPortalFixed) || (reverse && wall === openingData.wallPortal)) {
                color = 0x000000;
                leafGate = 31;
            }
            if ((reverse && wall === openingData.wallPortalFixed) || (!reverse && wall === openingData.wallPortal)) {
                color = 0xff0000;
                leafGate = 32;
            }
            this.configFakeWall(wall, leafGate, reverse, pivot, color);
        }
        if (openingData && openingData.typeOpening === Environment.drawConfiguration.opening.type.portal) {
            if (!leafGate) {
                this.configFakeWall(wall, leafGate, reverse, pivot, wall.fakeWallColor);
            }
            if (leafGate === 1) {
                this.configFakeWall(wall, leafGate, reverse, pivot, wall.fakeWallColor);
            }
            if (leafGate === 2) {
                // leafGate 22 pour deux vantaux vantail droit
                this.configFakeWall(wall, 22, reverse, pivot, wall.fakeWallColor);
                // leafGate 21 pour deux vantaux vantail gauche
                this.configFakeWall(wall, 21, reverse, pivot, wall.fakeWallColor);
            }
        }
        if (!openingData && !leafGate) {
            this.configFakeWall(wall, leafGate, reverse, pivot, wall.fakeWallColor);
        }

    }

    /**
     *
     * @param {Wall} wall
     * @param {number} leafGate
     * @param {boolean} reverse
     * @param {boolean} pivot
     * @param {number} color
     */
    configFakeWall(wall, leafGate, reverse, pivot, color) {
        const options = {
            normalizeVector: wall.wallDirectionNormalized(),
            posX: Dimensioning.cmToPixel(((wall.start.x + wall.end.x) / 2)),
            posY: Dimensioning.cmToPixel(((wall.start.y + wall.end.y) / 2)),
            width: Dimensioning.cmToPixel(Math.sqrt(Math.pow(wall.start.x - wall.end.x, 2) + Math.pow(wall.start.y - wall.end.y, 2))),
            height: Environment.drawConfiguration.wall.fakeWall.thicknessWall,
            color: color,
            opacity: Environment.drawConfiguration.wall.fakeWall.opacity,
            leafGate: leafGate,
            reverse: reverse,
            pivotLeft: pivot
        }
        let fakeWall = (new FakeWall2D(options)).drawFakeWall();
        fakeWall.zIndex = 1;
        fakeWall.id = wall.id;
        fakeWall.belongToFenceElement = wall.belongToFenceElement;
        fakeWall.belongToMainWallKey = wall.belongToMainWallKey;
        this.fakeWalls.push(fakeWall);
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(fakeWall);
    }

    /**
     * Dessine le sélecteur dynamique du fakeWall
     * @param cornerRadius
     * @param {Wall} wall
     */
    addGrugeFakeWall(cornerRadius, wall) {
        const options = {
            normalizeVector: wall.wallDirectionNormalized(),
            posX: Dimensioning.cmToPixel((wall.start.x + wall.end.x) / 2),
            posY: Dimensioning.cmToPixel((wall.start.y + wall.end.y) / 2),
            width: Dimensioning.cmToPixel(Math.sqrt(Math.pow(wall.start.x - wall.end.x, 2) + Math.pow(wall.start.y - wall.end.y, 2))) - (cornerRadius * 2),
            height: Environment.drawConfiguration.wall.fakeWall.thickness,
            color: Environment.drawConfiguration.wall.grugeBox.color.off,
            opacity: Environment.drawConfiguration.wall.grugeBox.opacity.off
        }
        let grugeFakeWall = (new FakeWall2D(options)).drawFakeWall();
        grugeFakeWall.belongToFenceElement = wall.belongToFenceElement;
        grugeFakeWall.belongToMainWallKey = wall.belongToMainWallKey;
        this.addEventsFakeWall(wall, grugeFakeWall);
        this.fakeGrugeWalls.push(grugeFakeWall);
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(grugeFakeWall);
    }

    /**
     * Ajoute les événements du grugeur du fakeWall
     * @param {Wall} wall
     * @param {Graphics} grugeFakeWall
     */
    addEventsFakeWall(wall, grugeFakeWall) {
        grugeFakeWall.on('mousedown', () => {
            wallClicked.item = wall;
            document.dispatchEvent(wallClicked);
        })
        grugeFakeWall.on("pointerover", () => {
            if (this.grugeVisible) {
                grugeFakeWall.alpha = Environment.drawConfiguration.wall.grugeBox.opacity.hover;
                grugeFakeWall.tint = Environment.drawConfiguration.wall.grugeBox.color.hover;
            }
        });
        grugeFakeWall.on("pointerout", () => {
            grugeFakeWall.alpha = Environment.drawConfiguration.wall.grugeBox.opacity.off;
            grugeFakeWall.tint = Environment.drawConfiguration.wall.grugeBox.color.off;
        });
    }

    /**
     * Mise à zéro des éléments fake
     */
    resetFakeWall() {
        this.fakeGrugeWalls.forEach((item) => {
            item.grugeVisible = true;
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(item);
        })
        this.fakeWalls.forEach((item) => {
            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(item);
        })
        this.fakeWalls = [];
        this.fakeGrugeWalls = [];
    }

    /**
     * Mise à jour de tous les éléments fake de l'ouverture en mouvement
     * @param fenceElement
     * @param {object} openingOnDrag
     */
    updateFakeElements(fenceElement, openingOnDrag) {
        fenceElement[openingOnDrag.belongToMainCornerSection].openingsWalls.forEach((wall) => {
            if (parseInt(wall.id.split(',')[1].split('-')[1]) === openingOnDrag.id && wall.belongToMainWallKey === openingOnDrag.belongToMainWallKey) {
                // Récupération des ids des fake walls concernés
                let idFakeWalls = this.getFakeElementIdToOpeningOnDrag(wall, openingOnDrag);
                // Récupération du premier poteau
                let start = this.getFirstCornerBeforeOpening(fenceElement, openingOnDrag, wall);
                // Récupération du dernier poteau
                let end = this.getFirstCornerAfterOpening(fenceElement, openingOnDrag, wall);
                // Mise à jour des fake walls
                this.updateFakeWalls(fenceElement, start, end, openingOnDrag, idFakeWalls);
                // Mise à jour des dimensions des fake walls
                this.updateFakeWallDims(fenceElement, start, end, openingOnDrag, idFakeWalls);
            }
        })
    }

    /**
     * Mise à jour des fake walls liés à une ouverture
     * @param fenceElement
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param {object} openingOnDrag
     * @param {string[]} idFakeWalls
     */
    updateFakeWalls(fenceElement, start, end, openingOnDrag, idFakeWalls) {
        // dans le cas ou le portail à deux vantaux il possède le même id
        // ce compteur renseigne si c'est le premier vantail ou le deuxième
        let countIdemId = 0;

        this.drawManager.drawWall2D.fakeWalls.forEach((fakeWall) => {
            if (fakeWall.belongToFenceElement === fenceElement.name && fakeWall.belongToMainWallKey === openingOnDrag.belongToMainWallKey) {
                // cas glissière
                if (openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
                    if (fakeWall.id === idFakeWalls[0]) {
                        this.updateFakeWall(fenceElement, fakeWall, start, openingOnDrag.corners[0], openingOnDrag);
                    }
                    if(openingOnDrag.reverse){
                        if (fakeWall.id === idFakeWalls[1]) {
                            this.updateFakeWall(fenceElement, fakeWall, openingOnDrag.corners[0], openingOnDrag.corners[2], openingOnDrag);
                        }
                        if (fakeWall.id === idFakeWalls[2]) {
                            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(fakeWall);
                        }
                    } else {
                        if (fakeWall.id === idFakeWalls[1]) {
                            this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(fakeWall);
                        }
                        if (fakeWall.id === idFakeWalls[2]) {
                            this.updateFakeWall(fenceElement, fakeWall, openingOnDrag.corners[0], openingOnDrag.corners[2], openingOnDrag, true);
                        }
                    }

                    if (fakeWall.id === idFakeWalls[3]) {
                        this.updateFakeWall(fenceElement, fakeWall, openingOnDrag.corners[2], end, openingOnDrag);
                    }
                }
                // cas portail
                if (openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.portal) {
                    if (fakeWall.id === idFakeWalls[0]) {
                        this.updateFakeWall(fenceElement, fakeWall, start, openingOnDrag.corners[0], openingOnDrag);
                    }
                    if (fakeWall.id === idFakeWalls[1]) {
                        countIdemId++;
                        this.updateFakeWall(fenceElement, fakeWall, openingOnDrag.corners[1], openingOnDrag.corners[2], openingOnDrag, true, countIdemId);
                    }
                    if (fakeWall.id === idFakeWalls[2]) {
                        this.updateFakeWall(fenceElement, fakeWall, openingOnDrag.corners[3], end, openingOnDrag);
                    }
                }

            }
        });
        this.scale = this.drawManager.scale
    }

    /**
     * Mise à jour d'un fake wall lié à l'ouverture
     * @param fenceElement
     * @param {Graphics} fakeWall
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param openingOnDrag
     * @param {boolean} isPortal
     * @param {number} gateNumber
     */
    updateFakeWall(fenceElement, fakeWall, start, end, openingOnDrag, isPortal = false, gateNumber = 0) {
        if (isPortal && (openingOnDrag.gateLeavesNumber === 2 || start.isSlideOpeningCornerMiddle)) {
            fakeWall.width = this.calculateWidthFakeWall(fakeWall, start, end, openingOnDrag) / 2;
        } else {
            fakeWall.width = this.calculateWidthFakeWall(fakeWall, start, end, openingOnDrag);
        }
        this.reDrawFakeWall(fenceElement, fakeWall, start, openingOnDrag, isPortal, gateNumber);
    }

    /**
     * Calcule la nouvelle longueur du fake wall
     * @param {Graphics} fakeWall
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param openingOnDrag
     * @returns {number}
     */
    calculateWidthFakeWall(fakeWall, start, end, openingOnDrag) {
        const wallNorm = openingOnDrag.wallBase.wallDirectionNormalized();
        let cornerStartView;
        let cornerEndView;
        if (start instanceof Corner) {
            cornerStartView = start.cornerView;
        } else {
            cornerStartView = start.simpleCornerView;
        }
        if (end instanceof Corner) {
            cornerEndView = end.cornerView;
        } else {
            cornerEndView = end.simpleCornerView;
        }
        let distX = cornerEndView.x - cornerStartView.x - cornerEndView.width * wallNorm.x;
        let distY = cornerEndView.y - cornerStartView.y - cornerEndView.height * wallNorm.y;

        return Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));
    }

    /**
     * Redessine le fake wall sur le plan
     * @param {object} fenceElement
     * @param {Graphics} fakeWall
     * @param {Corner|SimpleCorner} start
     * @param {object} openingOnDrag
     * @param isPortal
     * @param {Number} gateNumber
     */
    reDrawFakeWall(fenceElement, fakeWall, start, openingOnDrag, isPortal = false, gateNumber) {

        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(fakeWall);
        fakeWall.width = fakeWall.width + start.cornerDiameterOrLength / 2
        if (isPortal && openingOnDrag.gateLeavesNumber !== null) {
            if (openingOnDrag.gateLeavesNumber === 2) {
                if (gateNumber === 1) {
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, Dimensioning.cmToPixel(fakeWall.width * 4 - start.cornerDiameterOrLength));
                }
                if (gateNumber === 2) {
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, 0);
                }
            }
            if (openingOnDrag.gateLeavesNumber === 1) {
                if (openingOnDrag.pivotLeft) {
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, 0);
                } else {
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, Dimensioning.cmToPixel(fakeWall.width * 2));
                }
            }
        } else {
            if (start.isSlideOpeningCornerStart) {
                if(openingOnDrag.reverse){
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, 0);
                } else {
                    this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, Dimensioning.cmToPixel(fakeWall.width * 2 - start.cornerDiameterOrLength/2));
                }
            } else {
                this.setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, Dimensioning.cmToPixel(fakeWall.width));
            }
        }

        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.addChild(fakeWall);
    }

    /**
     * calcule la nouvelle position du fakeWall
     * @param {object} fenceElement
     * @param {Graphics} fakeWall
     * @param {Corner|SimpleCorner} start
     * @param {object} openingOnDrag
     * @param {Number} newDrawPosition en pixels
     */
    setFakeWallPosition(fenceElement, fakeWall, start, openingOnDrag, newDrawPosition) {
        let cornerStartView;
        if (start instanceof Corner) {
            cornerStartView = start.cornerView;
        } else {
            cornerStartView = start.simpleCornerView;
        }
        fakeWall.position.x = (cornerStartView.x + (newDrawPosition * fenceElement[openingOnDrag.belongToMainCornerSection].mainWalls[openingOnDrag.belongToMainWallKey].wallDirectionNormalized().x) / this.drawManager.scale);
        fakeWall.position.y = (cornerStartView.y + (newDrawPosition * fenceElement[openingOnDrag.belongToMainCornerSection].mainWalls[openingOnDrag.belongToMainWallKey].wallDirectionNormalized().y) / this.drawManager.scale);
    }

    /**
     * Mise à jour des dimensions des murs liés à une ouverture
     * @param fenceElement
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param {object} openingOnDrag
     * @param {string[]} idFakeWalls
     */
    updateFakeWallDims(fenceElement, start, end, openingOnDrag, idFakeWalls) {

        this.drawManager.drawWall2D.fakeWallDims.forEach((FakeWallDim, key) => {
            if (FakeWallDim.belongToFenceElement === fenceElement.name && FakeWallDim.belongToMainWallKey === openingOnDrag.belongToMainWallKey) {
                if (FakeWallDim.id === idFakeWalls[0]) {
                    this.updateFakeWallDim(FakeWallDim, start, openingOnDrag.corners[0], key);
                }
                if (FakeWallDim.id === idFakeWalls[1]) {
                    this.updateFakeWallDim(FakeWallDim, openingOnDrag.corners[1], openingOnDrag.corners[2], key);
                }
                if (FakeWallDim.id === idFakeWalls[2]) {
                    this.updateFakeWallDim(FakeWallDim, openingOnDrag.corners[3], end, key);
                }
            }
        })
    }

    /**
     * Mise à jour d'une dimension d'un mur
     * @param {WallDimensions2D} FakeWallDim
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param {number} key
     */
    updateFakeWallDim(FakeWallDim, start, end, key) {
        let cornerStartView;
        let cornerEndView;
        if (start instanceof Corner) {
            cornerStartView = start.cornerView;
        } else {
            cornerStartView = start.simpleCornerView;
        }
        if (end instanceof Corner) {
            cornerEndView = end.cornerView;
        } else {
            cornerEndView = end.simpleCornerView;
        }
        this.drawManager.blueprint3d.floorplanner.__floorplanElementsHolder.removeChild(FakeWallDim);
        let c1 = new Corner(this.drawManager.floorplan, Dimensioning.pixelToCm(cornerStartView.x), Dimensioning.pixelToCm(cornerStartView.y), "c1");
        let c2 = new Corner(this.drawManager.floorplan, Dimensioning.pixelToCm(cornerEndView.x), Dimensioning.pixelToCm(cornerEndView.y), "c2");
        let wall = new Wall(c1, c2);
        wall.id = FakeWallDim.id;
        wall.belongToFenceElement = FakeWallDim.belongToFenceElement;
        wall.belongToMainWallKey = FakeWallDim.belongToMainWallKey;
        this.fakeWallDims.splice(key, 1);
        this.drawManager.dimensionManager.addDimension(wall.start, wall.end, this.optionsDImension, this.fakeWallDims);
        c1.remove();
        c2.remove();
        wall.remove();
    }

    /**
     * Retourne les identifiants des éléments fake concernés par le mouvement de l'ouverture
     * @param {Wall} wall
     * @param {object} openingOnDrag
     * @returns {string[]}
     */
    getFakeElementIdToOpeningOnDrag(wall, openingOnDrag) {

        let idFakeWalls = [];
        let refWall = wall.id.split(',')[0].split('-')[1];
        let idFakeWall1 = 'wall-' + (parseInt(refWall) - 1) + ',idOpening-null,id-' + openingOnDrag.belongToMainCornerSection;
        idFakeWalls.push(idFakeWall1);
        let idFakeWall2 = 'wall-' + refWall + ',idOpening-' + openingOnDrag.id + ',id-' + openingOnDrag.belongToMainCornerSection;
        idFakeWalls.push(idFakeWall2);
        let idFakeWall3 = 'wall-' + (parseInt(refWall) + 1) + ',idOpening-null,id-' + openingOnDrag.belongToMainCornerSection;
        idFakeWalls.push(idFakeWall3);
        if (openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            idFakeWalls = [];
            let idFakeWall1 = 'wall-' + (parseInt(refWall) - 2) + ',idOpening-null,id-' + openingOnDrag.belongToMainCornerSection;
            idFakeWalls.push(idFakeWall1);
            let idFakeWall2 = 'wall-' + (parseInt(refWall) - 1) + ',idOpening-' + openingOnDrag.id + ',id-' + openingOnDrag.belongToMainCornerSection;
            idFakeWalls.push(idFakeWall2);
            let idFakeWall3 = 'wall-' + refWall + ',idOpening-' + openingOnDrag.id + ',id-' + openingOnDrag.belongToMainCornerSection;
            idFakeWalls.push(idFakeWall3);
            let idFakeWall4 = 'wall-' + (parseInt(refWall) + 1) + ',idOpening-null,id-' + openingOnDrag.belongToMainCornerSection;
            idFakeWalls.push(idFakeWall4);
        }
        return idFakeWalls;
    }

    /**
     * Retourne la clé du côté précédent à l'ouverture en mouvement
     * @param fenceElement
     * @param {string} sideOnDrag
     * @returns {string}
     */
    getPreviousSide(fenceElement, sideOnDrag) {
        let previousSide;
        if (fenceElement[sideOnDrag].id === 0) {
            previousSide = Environment.globalConfigurationEnv.mainCornerSectionName + (fenceElement.numberOfCorner - 1);
        } else {
            previousSide = Environment.globalConfigurationEnv.mainCornerSectionName + (fenceElement[sideOnDrag].id - 1);
        }
        return previousSide
    }

    /**
     * Retourne le premier poteau précédent l'ouverture
     * @param fenceElement
     * @param {object} openingOnDrag
     * @param wall
     * @returns {Corner|SimpleCorner}
     */
    getFirstCornerBeforeOpening(fenceElement, openingOnDrag, wall) {
        let start;
        let posOpeningOnMainWall = this.drawManager.openingManager.getPosOpeningOnMainWall(openingOnDrag);
        if (posOpeningOnMainWall === 0) {
            start = fenceElement[openingOnDrag.belongToMainCornerSection].mainWalls[wall.belongToMainWallKey].start;
        }
        if (posOpeningOnMainWall > 0) {
            let previousOpening = this.drawManager.openingManager.getPreviousOpeningOnMainWall(openingOnDrag)
            start = previousOpening.corners[previousOpening.corners.length - 1];
        }
        return start;
    }

    /**
     * Retourne le premier poteau suivant l'ouverture
     * @param fenceElement
     * @param openingOnDrag
     * @param wall
     * @returns {Corner|SimpleCorner}
     */
    getFirstCornerAfterOpening(fenceElement, openingOnDrag, wall) {
        let end;
        let posOpeningOnMainWall = this.drawManager.openingManager.getPosOpeningOnMainWall(openingOnDrag);
        let countOpeningsOnMainWall = this.drawManager.openingManager.getCountOpeningsOnMainWall(fenceElement[openingOnDrag.belongToMainCornerSection].openings, openingOnDrag.belongToMainWallKey)
        if (posOpeningOnMainWall === countOpeningsOnMainWall - 1) {
            end = fenceElement[openingOnDrag.belongToMainCornerSection].mainWalls[wall.belongToMainWallKey].end;
        }
        if (posOpeningOnMainWall < countOpeningsOnMainWall - 1) {
            end = this.drawManager.openingManager.getNextOpeningOnMainWall(openingOnDrag).corners[0];
        }
        return end;
    }

    /**
     * Vérifie si un mur de la liste possède une ouverture
     * @param {Wall[]} walls
     */
    checkWallsHasOpening(walls) {
        for (let i = 0; i < walls.length; i++) {
            let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(walls[i].belongToFenceElement);
            let openingList = fenceElement[walls[i].belongToMainCornerSection].openings
            for (let j = 0; j < openingList.length; j++) {
                if (openingList[j].belongToMainWallKey === walls[i].belongToMainWallKey) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Vérifie les combinaisons de clôtures possibles pour les segments liés à un seul des points du segment cible
     * @param {Wall} target
     * @param {Wall[]} boundWalls
     */
    getFencePossibleWallCombinationForOneCorner(target, boundWalls) {
        let result = null;
        // Récupération des informations de combinaison pour la cible
        // Vérifie que les autres segments liés ne comportent pas de mauvaise combinaison
        boundWalls.forEach((wall) => {
            if (wall.visible) {
                if (wall !== target && wall.fenceTypeCode != null) {
                    if (result === null && wall !== target) {
                        result = Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeAuthorized[wall.fenceTypeCode]
                    } else {
                        // Erreur lancée si un problème de combinaison est repéré
                        if (Environment.dataEcommerceEnv.combineFenceTypeTaxonCodeAuthorized[wall.fenceTypeCode] !== result) {
                            alert(Environment.drawConfiguration.main.errorMessage.errorFenceCombination);
                        }
                    }
                }
            }

        })
        return result;
    }

    /**
     * Compare les combinaisons de clôtures possibles pour les deux points du segment cible à partir de leurs combinaisons respectives
     * @param {Wall} target
     * @param {Wall[]} boundWallsStart
     * @param {Wall[]} boundWallsEnd
     * @param {string[]} excludedFenceTaxonCode
     */
    getFenceTypeNamesAuthorizedCombinationForBothCorner(target, boundWallsStart, boundWallsEnd, excludedFenceTaxonCode = null) {
        const resultStart = this.getFencePossibleWallCombinationForOneCorner(target, boundWallsStart);
        const resultEnd = this.getFencePossibleWallCombinationForOneCorner(target, boundWallsEnd);
        let fenceTypeNamesAuthorized;

        if (resultStart === null && resultEnd === null) {
            fenceTypeNamesAuthorized = this.drawManager.jsonDataManager.getFenceTypeNames(null, excludedFenceTaxonCode);
        } else {
            const fenceTypeNamesStart = this.drawManager.jsonDataManager.getFenceTypeNames(resultStart, excludedFenceTaxonCode);
            const fenceTypeNamesEnd = this.drawManager.jsonDataManager.getFenceTypeNames(resultEnd, excludedFenceTaxonCode);

            if (JSON.stringify(fenceTypeNamesEnd) === JSON.stringify(fenceTypeNamesStart)) {
                fenceTypeNamesAuthorized = fenceTypeNamesStart;
            } else if (resultStart !== null && resultEnd !== null) {
                fenceTypeNamesAuthorized = null;
            } else {
                fenceTypeNamesAuthorized = resultStart === null ? fenceTypeNamesEnd : fenceTypeNamesStart;
            }
        }

        return fenceTypeNamesAuthorized;
    }

    /**
     * Vérifie les combinaisons remplissage possibles pour les segments liés à un des points du segment cible
     * @param {Wall} target
     * @param {Wall[]} boundWalls
     */
    getFillingPossibleWallCombinationForOneCorner(target, boundWalls) {
        let result = null;
        // Récupération des informations de combinaison pour la cible
        // Vérifie que les autres segments liés ne comportent pas de mauvaise combinaison
        boundWalls.forEach((wall) => {
            if (wall.visible) {
                if (wall !== target && wall.fillingTypeCode != null) {
                    if (result === null && wall !== target) {
                        result = Environment.dataEcommerceEnv.combineFillingTypeTaxonCodeAuthorized[wall.fillingTypeCode]
                    } else {
                        // Erreur lancée si un problème de combinaison est repéré
                        if (Environment.dataEcommerceEnv.combineFillingTypeTaxonCodeAuthorized[wall.fillingTypeCode] !== result) {
                            alert(Environment.drawConfiguration.main.errorMessage.errorFillingCombination);
                        }
                    }
                }
            }
        })
        return result;
    }

    /**
     * Compare les combinaisons de remplissage possibles pour les deux points du segment cible à partir de leurs combinaisons respectives
     * @param {object[]} fillingTypeInfo
     * @param {Wall} target
     * @param {Wall[]} boundWallsStart
     * @param {Wall[]} boundWallsEnd
     * @param {string[]} excludedFillingTaxonCode
     */
    getFillingTypeNamesAuthorizedCombinationForBothCorner(fillingTypeInfo, target, boundWallsStart, boundWallsEnd, excludedFillingTaxonCode = null) {
        const resultStart = this.getFillingPossibleWallCombinationForOneCorner(target, boundWallsStart);
        const resultEnd = this.getFillingPossibleWallCombinationForOneCorner(target, boundWallsEnd);
        let fillingTypeNamesAuthorized;
        if (resultStart === null && resultEnd === null) {
            fillingTypeNamesAuthorized = this.drawManager.jsonDataManager.getFillingTypeNames(fillingTypeInfo, null, excludedFillingTaxonCode);
        } else {
            const fillingTypeNamesStart = this.drawManager.jsonDataManager.getFillingTypeNames(fillingTypeInfo, resultStart, excludedFillingTaxonCode);
            const fillingTypeNamesEnd = this.drawManager.jsonDataManager.getFillingTypeNames(fillingTypeInfo, resultEnd, excludedFillingTaxonCode);
            if (resultStart !== null && resultEnd !== null) {
                fillingTypeNamesAuthorized = fillingTypeNamesStart.filter((value) => fillingTypeNamesEnd.includes(value));
            } else {
                fillingTypeNamesAuthorized = resultStart === null ? fillingTypeNamesEnd : fillingTypeNamesStart;
            }
        }
        return fillingTypeNamesAuthorized;
    }
}
