import {OpeningManager} from "./OpeningManager";
import {MainCorner2D} from "../viewer2D/MainCorner2D";
import {FenceElementManager} from "./FenceElementManager";
import {IntermediateCorner2D} from "../viewer2D/IntermediateCorner2D";
import {DrawWall2D} from "../viewer2D/DrawWall2D";
import {ImageScaling} from "../imageScaling/ImageScaling";
import {Configuration} from "../../core/configuration";
import {JsonDataManager} from "./JsonDataManager";
import {WallConfigData} from "../configurationData/WallConfigData";
import {Environment} from "../environment/Environment";
import {PortalManager} from "./PortalManager";
import {SealingManager} from "./SealingManager";
import {DimensionManager} from "./DimensionManager";
import {CartData} from "../configurationData/CartData";

export class DrawManager {

    constructor(blueprint3d, options, configuratorStarter) {

        this.blueprint3d = blueprint3d;
        this.floorplan = this.blueprint3d.model.floorplan;
        this.options = options;
        this.configuratorStarter = configuratorStarter;
        this.rootCssStyle = getComputedStyle(document.querySelector(':root'));
        this.fenceElementManager = new FenceElementManager(this);
        this.imageScaling = new ImageScaling(this);
        this.openingManager = new OpeningManager(this);
        this.mainCorner2D = new MainCorner2D(this);
        this.sealingManager = new SealingManager(this);
        this.intermediateCorner2D = new IntermediateCorner2D(this);
        this.drawWall2D = new DrawWall2D(this);
        this.jsonDataManager = new JsonDataManager(this, this.configuratorStarter.jsonData);
        this.wallConfigManager = new WallConfigData(this);
        this.portalManager = new PortalManager(this)
        this.dimensionManager = new DimensionManager(this);
        this.fenceElementManager.newFenceElement(true);
        this.__drawNewOpeningEvent = this.__drawNewOpening.bind(this);
        this.scale = 1;
        this.resizingCoefficient = 1;
        this.willMergeWall = [];
        this.currentCartData = null;
    }


    /**
     * Gestion de l'affichage de la totalité des éléments 2D
     */
    drawFence() {
        this.floorplan.reset();
        this.removeAllGruge();
        this.currentCartData = new CartData(this);
        this.drawAllMainCorners();
        this.drawOthersElements();
        this.floorplan.update();
        console.log('element select list', this.fenceElementManager.getFenceElements());
        console.log('json data', this.jsonDataManager.jsonData);
        console.log('walls data', this.wallConfigManager.wallsConfigData);
        console.log('cart',this.currentCartData.getProducts());

    }

    /**
     * Affiche uniquement les mainCorners
     */
    drawAllMainCorners() {
        for (let i = 0; i < this.fenceElementManager.countFenceElements(); i++) {
            this.mainCorner2D.drawMainCorners(this.fenceElementManager.getFenceElement(i))
        }
    }

    /**
     * fonction nettoyae avant le dessin du reste des éléments (this.drawElements)
     */
    drawOthersElements() {
        for (let i = 0; i < this.fenceElementManager.countFenceElements(); i++) {
            this.resizingElement(this.fenceElementManager.getFenceElement(i));
            this.refreshDataFenceElement(this.fenceElementManager.getFenceElement(i));
            this.drawElements(this.fenceElementManager.getFenceElement(i));
        }
    }

    /**
     * Supprimme les grugeBox
     */
    removeAllGruge() {
        this.willMergeWall = [];
        this.sealingManager.resetSealings();
        this.dimensionManager.resetDimension(this.openingManager.opening2D.fakewallPortalDims);
        this.openingManager.opening2D.fakewallPortalDims = [];
        this.dimensionManager.resetDimension(this.intermediateCorner2D.intermediateDimension);
        this.intermediateCorner2D.intermediateDimension = [];
        this.dimensionManager.resetDimension(this.sealingManager.sealingDims);
        this.sealingManager.sealingDims = [];
        this.dimensionManager.resetDimension(this.drawWall2D.fakeWallDims);
        this.drawWall2D.fakeWallDims = [];
        this.drawWall2D.resetFakeWall();
        this.drawWall2D.grugeVisible = true;
        this.openingManager.opening2D.fakeWallOpeningList.forEach((item) => {
            this.blueprint3d.floorplanner.__floorplanContainer.removeChild(item)
        });
    }

    /**
     * Gestion sizing des éléments
     * @param {object} fenceElement
     */
    resizingElement(fenceElement) {
        if (Configuration.getNumericValue("scale") !== this.scale) {
            this.resizingCoefficient = this.scale / Configuration.getNumericValue("scale")
            Configuration.setValue("scale", this.scale);
            this.blueprint3d.floorplanner.origin.height = this.blueprint3d.floorplanner.origin.height * this.resizingCoefficient;
            this.blueprint3d.floorplanner.origin.width = this.blueprint3d.floorplanner.origin.width * this.resizingCoefficient;
            fenceElement.cornerRadius = fenceElement.cornerRadius * this.resizingCoefficient;
            fenceElement.wallThickness = fenceElement.wallThickness * this.resizingCoefficient;
        }
        this.blueprint3d.floorplanner.__options["corner-radius"] = fenceElement.cornerRadius;
        this.blueprint3d.floorplanner.__options["line-wall-thickness"] = fenceElement.wallThickness;
    }

    /**
     * Mise à jour des données de fenceElement
     * @param {object} fenceElement
     */
    refreshDataFenceElement(fenceElement) {
        for (const key of Object.keys(fenceElement)) {
            if (key.startsWith(Environment.globalConfigurationEnv.mainCornerSectionName)) {
                if (fenceElement[key].openings.length > 0) {
                    fenceElement[key].openingsWalls = [];
                }
                fenceElement[key].simpleCornersMainWall = [];
                fenceElement[key].mainWalls = [];
                fenceElement[key].openingsWalls = []
            }
        }
    }

    /**
     * Déssine les segments et ses éléments hors mainCorner
     * @param {object} fenceElement
     */
    drawElements(fenceElement) {
        for (const key of Object.keys(fenceElement)) {
            if (key.startsWith(Environment.globalConfigurationEnv.mainCornerSectionName)) {
                if (fenceElement[key].cornerLinkedTo.length > 0) {
                    fenceElement[key].mainWalls = [];
                    for (let futureMainWallsKey = 0; futureMainWallsKey < fenceElement[key].cornerLinkedTo.length; futureMainWallsKey++) {
                        let target = fenceElement[key].cornerLinkedTo[futureMainWallsKey];
                        let fenceElementTarget = this.fenceElementManager.getFenceElementByName(target[0]);
                        this.drawElement(futureMainWallsKey, fenceElement, fenceElementTarget[target[1].split('-')[0]].corner, fenceElement[key].corner, key);
                    }
                }
            }
        }

    }

    /**
     * Déssine un côté et ses éléments
     * @param futureMainWallsKey
     * @param fenceElement
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param {string} mainCornerSectionKey
     */
    drawElement(futureMainWallsKey, fenceElement, start, end, mainCornerSectionKey) {
        const openings = fenceElement[mainCornerSectionKey].openings;
        let counterOpenings = 0;
        let openingsBelongToMainWallOnGoing = []
        for (let opening of openings) {
            if (opening.belongToMainWallKey === futureMainWallsKey) {
                openingsBelongToMainWallOnGoing.push(opening);
                counterOpenings++;
            }
        }
        if (counterOpenings === 0) {
            let wall = this.drawWall2D.addDrawWall(fenceElement, start, end, mainCornerSectionKey, null, null, true);
            this.drawWall2D.addGrugeFakeWall(fenceElement.cornerRadius, wall);
            this.drawSealingsAndConfig(wall, null);
        }
        if (counterOpenings > 0) {
            this.openingManager.opening2D.drawOpenings(fenceElement, start, end, openingsBelongToMainWallOnGoing, mainCornerSectionKey);
        }
        this.willMergeWall.forEach((wall) => {
            // Merge les sealings superposés
            this.sealingManager.checkMergeSealings(wall);
        })


        this.dimensionManager.drawDimension(this.sealingManager.sealingDims)
        this.dimensionManager.drawDimension(this.intermediateCorner2D.intermediateDimension)
        this.dimensionManager.drawDimension(this.drawWall2D.fakeWallDims)
        this.dimensionManager.drawDimension(this.openingManager.opening2D.fakewallPortalDims)

    }

    /**
     * Dessine les poteaux intermédiaires d'un grillage
     * @param {Wall} wall
     * @param {object|null} openingData
     * @param {boolean} drawIntermediateCorner
     */
    drawSealingsAndConfig(wall, openingData, drawIntermediateCorner = true) {
        let fenceElement = this.fenceElementManager.getFenceElementByName(wall.belongToFenceElement);
        if (drawIntermediateCorner) {
            // Setting up intermediate corner draw, scellement and config
            this.intermediateCorner2D.drawIntermediateCorner(fenceElement, wall);
        }
        // suppression des sealings plus petit dans le cas d'un poteau d'angle
        this.sealingManager.deleteLowestSealing(wall);
        // setting up mainCorner des infos techniques puis affichage du gruge de scellement si nécéssaire
        this.jsonDataManager.setConfigCornerInRelationWall(wall, wall.start, 0, openingData);
        this.sealingManager.addSealing(wall, wall.start);
        this.jsonDataManager.setConfigCornerInRelationWall(wall, wall.end, 0, openingData);
        this.sealingManager.addSealing(wall, wall.end);
        this.willMergeWall.push(wall);
        this.currentCartData.addWallProduct(wall);

    }

    /**
     * @returns {OpeningManager}
     */
    getOpeningManager() {
        return this.openingManager;
    }

    /**
     * @returns {FenceElementManager}
     */
    getFenceElementManager() {
        return this.fenceElementManager;
    }

    /**
     * @returns {IntermediateCorner2D}
     */
    getIntermediateCorner() {
        return this.intermediateCorner2D;
    }

    /**
     * @returns {DrawWall2D}
     */
    getDrawWall() {
        return this.drawWall2D;
    }

    /**
     * @returns {Floorplan}
     */
    getFloorplan() {
        return this.floorplan;
    }

    /**
     * Ajoute une ouverture sur le mur sélectionné
     */
    __drawNewOpening(e) {
        let wallSelected = this.configuratorStarter.wallPanel.wallSelected;
        if(e.value === Environment.wallPanelEnv.button.addSlide){
            this.openingManager.openingConfigData.createDataOpening(
                wallSelected,
                this.fenceElementManager.getFenceElementByName(wallSelected.belongToFenceElement),
                Environment.wallPanelEnv.button.addSlide
            );
        }
        if(e.value === Environment.wallPanelEnv.button.addOpening){
            this.openingManager.openingConfigData.createDataOpening(
                wallSelected,
                this.fenceElementManager.getFenceElementByName(wallSelected.belongToFenceElement),
                Environment.wallPanelEnv.button.addOpening
            );
        }

        this.configuratorStarter.wallPanel.settingPanel.hide();
        this.drawFence();
    }

}
