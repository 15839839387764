import {Graphics, Sprite} from "pixi.js";
import {Vector2} from "three";
import {Configuration} from "../../core/configuration";
import {Environment} from "../environment/Environment";


export class ImageScaling {

    constructor(drawManager) {
        this.drawManager = drawManager;
        this.__addImageScalingEvent = this.__addImageScaling.bind(this);
        this.__removeImageScalingEvent = this.__removeImageScaling.bind(this);
        this.__updateImageScalingEvent = this.__updateImageScaling.bind(this);

        this.imageScalingOnGoing = null;
        this.distanceInPixelToSegment = null;
        this.clickUpdateScaling1 = false;
        this.clickUpdateScaling2 = false;
        this.positionClick = null;
        this.positionPreviousClick = null;
        this.firstCircle = null;
        this.secondCircle = null;
        this.line = null;
        this.alphaCircle = 0.3;
        this.alphaLine = 0.3;
        this.color = 0x0000FF
    }

    __addImageScaling(e) {
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.imageScalingOnGoing)
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            localStorage.setItem(Environment.scalingConfiguration.picture.recentImageLocaleStorageKey, String(reader.result));
            this.imageScalingOnGoing = Sprite.from(localStorage.getItem(Environment.scalingConfiguration.picture.recentImageLocaleStorageKey));
            // this.imageScalingOnGoing.anchor.set(-1 * this.drawManager.blueprint3d.floorplanner.renderer.width / 2, -1 * this.drawManager.blueprint3d.floorplanner.renderer.height / 2)
            this.imageScalingOnGoing.width = this.drawManager.blueprint3d.floorplanner.renderer.width;
            this.imageScalingOnGoing.height = this.drawManager.blueprint3d.floorplanner.renderer.height;
            this.imageScalingOnGoing.anchor.set(Environment.scalingConfiguration.anchor);
            this.imageScalingOnGoing.resolution = Environment.scalingConfiguration.resolution;
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.imageScalingOnGoing);
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.setChildIndex(this.imageScalingOnGoing, 0);
        })
        reader.readAsDataURL(e)
    }

    __removeImageScaling() {
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.imageScalingOnGoing);
        document.querySelector('#' + Environment.drawPanelEnv.titleInput.addPicture).value = ""
        document.querySelector('#' + Environment.drawPanelEnv.titleInput.addPicture).nextSibling.innerHTML = Environment.drawPanelEnv.defaultInputMessage.addPicture;
        localStorage.setItem(Environment.scalingConfiguration.picture.recentImageLocaleStorageKey, "");
    }

    __updateImageScaling(e) {
        let localPosition = this.drawManager.blueprint3d.floorplanner.localPosition;
        if (localPosition && !this.drawManager.blueprint3d.floorplanner.screenMoved) {
            if (!this.clickUpdateScaling1) {
                this.clickUpdateScaling1 = true;
                this.firstCircle = this.drawCircle(localPosition, this.color, this.alphaCircle, 5);
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.firstCircle);
            } else {
                this.clickUpdateScaling2 = true;
                this.secondCircle = this.drawCircle(localPosition, this.color, this.alphaCircle, 5);
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.secondCircle);
                this.line = this.drawLine(this.firstCircle, this.secondCircle, this.color, this.alphaLine, 2);
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.line);
            }
            if (this.clickUpdateScaling1 && this.clickUpdateScaling2) {
                this.openImageScalingPanel(e, Environment.scalingConfiguration.offset);
                this.distanceInPixelToSegment = this.getNumberOfPixels(this.firstCircle, this.secondCircle);
                this.drawManager.configuratorStarter.drawPanel.toggleUpdateScalingButton(document.querySelector('#' + Environment.drawPanelEnv.button.updateScale.id),false);
                document.querySelector('#' + Environment.drawPanelEnv.button.updateScale.id).setAttribute("disabled", "disabled");
            }
        }
        this.drawManager.blueprint3d.floorplanner.localPosition = null;
        this.drawManager.blueprint3d.floorplanner.screenMoved = false;
    }

    setPositionImageInRelationToOrigin() {
        if (this.imageScalingOnGoing) {
            this.imageScalingOnGoing.position.set(this.imageScalingOnGoing.position.x - this.secondCircle.localX, this.imageScalingOnGoing.position.y - this.secondCircle.localY);
        }
    }

    openImageScalingPanel(targetPosition, offset) {
        this.drawManager.configuratorStarter.imageScalingPanel.settingPanel.setPosition(targetPosition.x - offset, targetPosition.y + offset);
        this.drawManager.configuratorStarter.imageScalingPanel.settingPanel.show();
    }

    getNumberOfPixels(start, end) {
        let vector1 = new Vector2(start.localX, start.localY);
        let vector2 = new Vector2(end.localX, end.localY);
        // on adapte le nombre de pixels en prenant soin d'ignorer le zoom
        // return vector1.distanceTo(vector2) / this.drawManager.blueprint3d.floorplanner.__floorplanContainer.scale.x;
        return vector1.distanceTo(vector2);
    }

    /**
     *
     * @param localPosition
     * @param color
     * @param alpha
     * @param radius
     * @returns {Graphics}
     */
    drawCircle(localPosition, color, alpha, radius) {
        let circle = new Graphics();
        circle.beginFill(color, alpha);
        circle.drawCircle(localPosition.x, localPosition.y, radius * Configuration.getNumericValue("scale"));
        circle.localX = localPosition.x;
        circle.localY = localPosition.y;
        circle.on('mousedown', this.dragStart.bind(this, circle))
        circle.on('mousemove', this.dragMove.bind(this, circle))
        circle.on('mouseup', this.dragEnd.bind(this, circle))
        circle.__isSelected = false;
        circle.interactive = true;
        return circle;
    }

    updateCircle(localPosition) {
        let circle = this.drawCircle(localPosition, this.color, this.alphaCircle, 5)
        circle.__isSelected = true;
        circle.__isDragging = true;
        return circle;
    }

    drawLine(start, end, color, alpha, lineThickness) {
        let line = new Graphics();
        line.lineStyle(lineThickness * Configuration.getNumericValue("scale"), color, alpha, 0.5)
            .moveTo(start.localX, start.localY)
            .lineTo(end.localX, end.localY)
        return line;
    }

    updateLine() {
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.line);
        this.line = this.drawLine(this.firstCircle, this.secondCircle, 0x00FF, this.alphaLine, 2);
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.line);
    }

    dragStart(circle, e) {
        e.stopPropagation();
        circle.__isSelected = true;
        circle.__isDragging = true;
    }

    dragMove(circle, e) {
        let localPosition = e.data.getLocalPosition(this.drawManager.blueprint3d.floorplanner.__floorplanContainer)
        if (this.firstCircle && this.firstCircle.__isSelected === true) {
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.firstCircle);
            this.firstCircle = this.updateCircle(localPosition);
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.firstCircle);
            this.updateLine();
        }
        if (this.secondCircle && this.secondCircle.__isSelected === true) {
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.secondCircle);
            this.secondCircle = this.updateCircle(localPosition);
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.secondCircle);
            this.updateLine();
        }
    }

    dragEnd(circle) {
        circle.__isSelected = false;
        circle.__isSelected = false;
        this.distanceInPixelToSegment = this.getNumberOfPixels(this.firstCircle, this.secondCircle);
    }

    resetImageScaling() {
        this.clickUpdateScaling1 = false;
        this.clickUpdateScaling2 = false;
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.firstCircle);
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.secondCircle);
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.line);
        this.firstCircle = null;
        this.secondCircle = null;
        this.distanceInPixelToSegment = null;
        this.drawManager.blueprint3d.floorplanner.localPosition = null;
    }
}
