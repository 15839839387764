import { EVENT_UPDATED, EVENT_LOADED, EVENT_NEW, EVENT_DELETED, EVENT_MODE_RESET, EVENT_EXTERNAL_FLOORPLAN_LOADED } from '../core/events.js';
import { EVENT_CORNER_ATTRIBUTES_CHANGED, EVENT_WALL_ATTRIBUTES_CHANGED, EVENT_MOVED, EVENT_NEW_ROOMS_ADDED } from '../core/events.js';
import { EventDispatcher, Vector2, Vector3, Matrix4 } from 'three';
import { Utils } from '../core/utils.js';
import { Dimensioning } from '../core/dimensioning.js';
import { dimInch, dimFeetAndInch, dimMeter, dimCentiMeter, dimMilliMeter, defaultWallTexture } from '../core/constants.js';
import { WallTypes } from '../core/constants.js';
import { Version } from '../core/version.js';
import { cornerTolerance, Configuration, configDimUnit } from '../core/configuration.js';


import { HalfEdge } from './half_edge.js';
import { Corner } from './corner.js';
import { Wall } from './wall.js';
import { CornerGroups } from './cornergroups.js';
import Boundary from './boundary.js';
import SimpleCorner from "./SimpleCorner";

/** */
export const defaultFloorPlanTolerance = 10.0;

/**
 * A Floorplan represents a number of Walls, Corners. This is an
 * abstract that keeps the 2d and 3d in sync
 */
export class Floorplan extends EventDispatcher {
    /** Constructs a floorplan. */
    constructor() {
        super();
        /**
         * List of elements of Wall instance
         * 
         * @property {Wall[]} walls Array of walls
         * @type {Wall[]}
         */
        this.walls = [];
        /**
         * List of elements of Corner instance
         * 
         * @property {Corner[]} corners array of corners
         * @type {Corner[]}
         */
        this.corners = [];

        /**
         * List of elements of SimpleCorner instance
         *
         * @property {SimpleCorner[]} simpleCorners array of simple corners
         * @type {SimpleCorner[]}
         */
        this.simpleCorners = [];


        this.__boundary = new Boundary(this);

        this.__externalCorners = [];
        this.__externalSimpleCorners = [];
        this.__externalWalls = [];

        this.__roofPlanesForIntersection = [];
        this.__floorPlanesForIntersection = [];
        this.__wallPlanesForIntersection = [];

        // List with reference to callback on a new wall insert event
        /**
         * @deprecated
         */
        this.new_wall_callbacks = [];
        // List with reference to callbacks on a new corner insert event
        /**
         * @deprecated
         */
        this.new_corner_callbacks = [];
        // List with reference to callbacks on redraw event
        /**
         * @deprecated
         */
        this.redraw_callbacks = [];

        this.floorTextures = {};
        /**
         * The {@link CarbonSheet that handles the background image to show in
         * the 2D view
         * 
         * @property CarbonSheet _carbonSheet The carbonsheet instance
         * @type {Object}
         */
        this._carbonSheet = null;

        /**
         * This is necessary to sometimes explicitly stop the floorplan from doing
         * heavy computations
         */
        this.__updatesOn = true;

        this.__cornerGroups = new CornerGroups(this);

        this.__wallAttributesChangedEvent = this.__wallAttributesChanged.bind(this);
        this.__wallDeletedEvent = this.__wallDeleted.bind(this);
        this.__cornerAttributesChangedOrMovedEvent = this.__cornerAttributesChangedOrMoved.bind(this);
        this.__cornerDeletedEvent = this.__cornerDeleted.bind(this);

    }

    externalWallEdges() {
            let edges = [];
            this.__externalWalls.forEach((wall) => {
                if (wall.frontEdge) {
                    edges.push(wall.frontEdge);
                }
                if (wall.backEdge) {
                    edges.push(wall.backEdge);
                }
            });
            return edges;
        }
        /**
         * @return {HalfEdge[]} edges The array of {@link HalfEdge}
         */
    wallEdges() {
        var edges = [];
        this.walls.forEach((wall) => {
            if (wall.frontEdge) {
                edges.push(wall.frontEdge);
            }
            if (wall.backEdge) {
                edges.push(wall.backEdge);
            }
        });
        return edges;
    }

    __wallDeleted(evt) {
        let wall = evt.item;
        wall.removeEventListener(EVENT_DELETED, this.__wallDeletedEvent);
        wall.removeEventListener(EVENT_WALL_ATTRIBUTES_CHANGED, this.__wallAttributesChangedEvent);
        this.removeWall(wall);
    }

    __wallAttributesChanged(evt) {
        this.dispatchEvent(evt);
    }

    __cornerDeleted(evt) {
        let corner = evt.item;

        corner.removeEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        corner.removeEventListener(EVENT_CORNER_ATTRIBUTES_CHANGED, this.__cornerAttributesChangedOrMovedEvent);
        corner.removeEventListener(EVENT_MOVED, this.__cornerAttributesChangedOrMovedEvent);

        this.removeCorner(corner);
        this.update();
        this.dispatchEvent({ type: EVENT_DELETED, item: this });
    }

    __cornerAttributesChangedOrMoved(evt) {
        this.dispatchEvent(evt);
        let updateCorners = evt.item.adjacentCorners();
        updateCorners.push(evt.item);
        this.update(false, updateCorners);
    }


    /**
     * Returns all the planes for intersection for the walls
     * 
     * @return {Mesh[]} planes
     * @see <https://threejs.org/docs/#api/en/objects/Mesh>
     */
    createWallEdgePlanes() {
        var planes = [];
        this.walls.forEach((wall) => {
            if (wall.frontEdge) {
                planes.push(wall.frontEdge.plane);
                if(wall.frontEdge.exteriorPlane){
                    planes.push(wall.frontEdge.exteriorPlane);
                }
            }
            if (wall.backEdge) {
                planes.push(wall.backEdge.plane);
                if(wall.backEdge.exteriorPlane){
                    planes.push(wall.backEdge.exteriorPlane);
                }
            }
        });
        return planes;
    }

    fireOnNewWall(callback) {
        this.new_wall_callbacks.add(callback);
    }

    fireOnNewCorner(callback) {
        this.new_corner_callbacks.add(callback);
    }

    fireOnRedraw(callback) {
        this.redraw_callbacks.add(callback);
    }

    // This method needs to be called from the 2d floorplan whenever
    // the other method newWall is called.
    // This is to ensure that there are no floating walls going across
    // other walls. If two walls are intersecting then the intersection point
    // has to create a new wall.
    /**
     * Checks existing walls for any intersections they would make. If there are
     * intersections then introduce new corners and new walls as required at
     * places
     * 
     * @param {Corner} start
     * @param {Corner} end
     * @return {boolean} intersects
     */

    newWallsForIntersections(start, end) {
        var intersections = false;
        // This is a bug in the logic
        // When creating a new wall with a start and end
        // it needs to be checked if it is cutting other walls
        // If it cuts then all those walls have to removed and introduced as
        // new walls along with this new wall
        var cStart = new Vector2(start.getX(), start.getY());
        var cEnd = new Vector2(end.getX(), end.getY());
        var line = { p1: cStart, p2: cEnd };
        // var newCorners = [];

        for (var i = 0; i < this.walls.length; i++) {
            var twall = this.walls[i];
            var bstart = { x: twall.getStartX(), y: twall.getStartY() };
            var bend = { x: twall.getEndX(), y: twall.getEndY() };
            var iPoint;
            if (twall.wallType === WallTypes.CURVED) {
                iPoint = twall.bezier.intersects(line);
                if (iPoint.length) {
                    iPoint = twall.bezier.get(iPoint[0]);
                }
            } else {
                iPoint = Utils.lineLineIntersectPoint(cStart, cEnd, bstart, bend);
            }
            if (iPoint) {
                var nCorner = this.newCorner(iPoint.x, iPoint.y);
                newCorners.push(nCorner);
                nCorner.mergeWithIntersected(false);
                intersections = true;
            }
        }
        this.update();

        return intersections;
    }

    /**
     * Creates a new wall.
     *
     * @param {Corner} start The start corner.
     * @param {Corner} end The end corner.
     * @param a
     * @param b
     * @returns {Wall} The new wall.
     */
    newWall(start, end, a, b) {
        // var scope = this;
        var wall = new Wall(start, end, a, b);
        this.walls.push(wall);
        // wall.addEventListener(EVENT_DELETED, function(o) { scope.removeWall(o.item); });
        // wall.addEventListener(EVENT_WALL_ATTRIBUTES_CHANGED, function(o) {
        //     scope.dispatchEvent(o);
        // });

        wall.addEventListener(EVENT_DELETED, this.__wallDeletedEvent);
        wall.addEventListener(EVENT_WALL_ATTRIBUTES_CHANGED, this.__wallAttributesChangedEvent);

        this.dispatchEvent({ type: EVENT_NEW, item: this, newItem: wall });
        this.update();
        return wall;
    }



    /**
     * Creates a new corner.
     *
     *            x The x coordinate.
     *            y The y coordinate.
     *            id An optional id. If unspecified, the id will be created
     *            internally.
     * @returns {Corner} The new corner.
     */
    newCorner(x, y, id) {
        // var scope = this;
        var corner = new Corner(this, x, y, id);

        for (var i = 0; i < this.corners.length; i++) {
            var existingCorner = this.corners[i];
            if (existingCorner.distanceFromCorner(corner) < cornerTolerance) {
                existingCorner.hadChangedByIntersection = true;
                this.dispatchEvent({ type: EVENT_NEW, item: this, newItem: existingCorner });
                return existingCorner;
            }
        }

        this.corners.push(corner);

        corner.addEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        corner.addEventListener(EVENT_CORNER_ATTRIBUTES_CHANGED, this.__cornerAttributesChangedOrMovedEvent);
        corner.addEventListener(EVENT_MOVED, this.__cornerAttributesChangedOrMovedEvent);


        this.dispatchEvent({ type: EVENT_NEW, item: this, newItem: corner });

        // This code has been added by #0K. There should be an update whenever a
        // new corner is inserted
        // this.update();

        return corner;
    }

    /**
     * Creates a new simple corner.
     *
     * @param {Number} x The x coordinate.
     * @param {Number} y The y coordinate.
     * @param {String} id An optional id. If unspecified, the id will be created internally.
     * @returns {SimpleCorner} The new simple corner.
     */
    newSimpleCorner(x, y,id = null) {
        // var scope = this;
        if(!id){
            id = Utils.guide();
        }
        var simpleCorner = new SimpleCorner(this, x, y, id);
        // for (var i = 0; i < this.simpleCorners.length; i++) {
        //     var existingCorner = this.simpleCorners[i];
        //     if (existingCorner.distanceFromCorner(corner) < cornerTolerance) {
        //         this.dispatchEvent({ type: EVENT_NEW, item: this, newItem: existingCorner });
        //         return existingCorner;
        //     }
        // }

        this.simpleCorners.push(simpleCorner);

        // corner.addEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        // corner.addEventListener(EVENT_CORNER_ATTRIBUTES_CHANGED, this.__cornerAttributesChangedOrMovedEvent);
        // corner.addEventListener(EVENT_MOVED, this.__cornerAttributesChangedOrMovedEvent);
        // this.dispatchEvent({ type: EVENT_NEW, item: this, newItem: corner });

        // This code has been added by #0K. There should be an update whenever a
        // new corner is inserted
        // this.update();

        return simpleCorner;
    }

    /**
     * Removes a wall.
     *
     * @param {Wall} wall The wall to be removed.
     */
    removeWall(wall) {
        Utils.removeValue(this.walls, wall);
        this.update();
        this.dispatchEvent({ type: EVENT_DELETED, item: this, deleted: wall, item_type: 'wall' });
    }

    /**
     * Removes a corner.
     * 
     * @param {Corner} corner The corner to be removed.
     */
    removeCorner(corner) {
        Utils.removeValue(this.corners, corner);
        this.update();
        this.dispatchEvent({ type: EVENT_DELETED, item: this, deleted: corner, item_type: 'corner' });
    }

    /**
     * Removes a corner.
     *
     *            corner The corner to be removed.
     * @param simpleCorner
     */
    removeSimplCorner(simpleCorner) {
        Utils.removeValue(this.simpleCorners, simpleCorner);
        this.update();
        this.dispatchEvent({ type: EVENT_DELETED, item: this, deleted: simpleCorner, item_type: 'corner' });
    }

    /**
     * Gets the walls.
     * 
     * @return {Wall[]}
     */
    getWalls() {
        return this.walls;
    }

    /**
     * Gets the corners.
     * 
     * @return {Corner[]}
     */
    getCorners() {
        return this.corners;
    }

    /**
     * Gets the Control of a Curved Wall overlapping the location x, y at a
     * tolerance.
     *
     * @param wall
     * @param {Number} x
     * @param {Number} y
     * @param {Number} tolerance
     * @return {Corner}
     */
    overlappedControlPoint(wall, x, y, tolerance) {
        tolerance = tolerance || defaultFloorPlanTolerance * 5;
        if (wall.a.distanceTo(new Vector2(x, y)) < tolerance && wall.wallType === WallTypes.CURVED) {
            return wall.a;
        } else if (wall.b.distanceTo(new Vector2(x, y)) < tolerance && wall.wallType === WallTypes.CURVED) {
            return wall.b;
        }

        return null;
    }

    /**
     * Gets the Corner overlapping the location x, y at a tolerance.
     * 
     * @param {Number} x
     * @param {Number} y
     * @param {Number} tolerance
     * @return {Corner}
     */
    overlappedCorner(x, y, tolerance) {
        tolerance = tolerance || defaultFloorPlanTolerance;
        for (var i = 0; i < this.corners.length; i++) {
            if (this.corners[i].distanceFrom(new Vector2(x, y)) < tolerance) {
                return this.corners[i];
            }
        }
        return null;
    }

    /**
     * Gets the Wall overlapping the location x, y at a tolerance.
     * 
     * @param {Number} x
     * @param {Number} y
     * @param {Number} tolerance
     * @return {Wall}
     */
    overlappedWall(x, y, tolerance) {
        tolerance = tolerance || defaultFloorPlanTolerance;
        for (var i = 0; i < this.walls.length; i++) {
            var newtolerance = tolerance; // (tolerance+
            // ((this.walls[i].wallType ==
            // WallTypes.CURVED)*tolerance*10));
            if (this.walls[i].distanceFrom(new Vector2(x, y)) < newtolerance) {
                return this.walls[i];
            }
        }
        return null;
    }

    // Save the floorplan as a json object file
    /**
     * @return {void}
     */
    saveFloorplan() {
        var floorplans = { version: Version.getTechnicalVersion(), corners: {}, walls: [], wallTextures: [], floorTextures: {}, newFloorTextures: {}, carbonSheet: {} };
        var cornerIds = [];

        this.walls.forEach((wall) => {
            if (wall.getStart() && wall.getEnd()) {
                floorplans.walls.push({
                    'corner1': wall.getStart().id,
                    'corner2': wall.getEnd().id,
                    'frontTexture': wall.frontTexture,
                    'backTexture': wall.backTexture,
                    'wallType': wall.wallType.description,
                    'a': { x: wall.a.x, y: wall.a.y },
                    'b': { x: wall.b.x, y: wall.b.y },
                    'thickness': Dimensioning.cmToMeasureRaw(wall.thickness),
                });
                cornerIds.push(wall.getStart());
                cornerIds.push(wall.getEnd());
            }
        });

        cornerIds.forEach((corner) => {
            floorplans.corners[corner.id] = { 'x': Dimensioning.cmToMeasureRaw(corner.x), 'y': Dimensioning.cmToMeasureRaw(corner.y), 'elevation': Dimensioning.cmToMeasureRaw(corner.elevation) };
        });

        if (this.carbonSheet) {
            floorplans.carbonSheet['url'] = this.carbonSheet.url;
            floorplans.carbonSheet['transparency'] = this.carbonSheet.transparency;
            floorplans.carbonSheet['x'] = this.carbonSheet.x;
            floorplans.carbonSheet['y'] = this.carbonSheet.y;
            floorplans.carbonSheet['anchorX'] = this.carbonSheet.anchorX;
            floorplans.carbonSheet['anchorY'] = this.carbonSheet.anchorY;
            floorplans.carbonSheet['width'] = this.carbonSheet.width;
            floorplans.carbonSheet['height'] = this.carbonSheet.height;
        }

        if(this.__boundary){
            if(this.__boundary.isValid){
                let boundaryData = {};
                let measurePoints = [];
                for (let i =0;i < this.__boundary.points.length;i++){
                    let cmPoint = this.__boundary.points[i];
                    let measurePoint = {
                        x: Dimensioning.cmToMeasureRaw(cmPoint.x), 
                        y: Dimensioning.cmToMeasureRaw(cmPoint.y),
                        elevation: Dimensioning.cmToMeasureRaw(cmPoint.elevation),
                    };
                    measurePoints.push(measurePoint);
                }

                boundaryData.points = measurePoints;
                boundaryData.style = this.__boundary.style;
                floorplans.boundary = boundaryData;
            }
        }

        floorplans.units = Configuration.getStringValue(configDimUnit);

        floorplans.newFloorTextures = this.floorTextures;
        return floorplans;
    }

    // Load the floorplan from a previously saved json object file
    /**
     * @param {JSON} floorplan
     * @return {void}
     * @emits {EVENT_LOADED}
     */
    loadFloorplan(floorplan) {
        this.reset();
        this.__updatesOn = false;
        let corners = {};
        if (floorplan === null || !('corners' in floorplan) || !('walls' in floorplan)) {
            return;
        }
        let currentUnit = Configuration.getStringValue(configDimUnit);
        if (floorplan.units) {
            switch (floorplan.units) {
                case dimInch:
                    Configuration.setValue(configDimUnit, dimInch);
                    break;
                case dimFeetAndInch:
                    Configuration.setValue(configDimUnit, dimFeetAndInch);
                    break;
                case dimMeter:
                    Configuration.setValue(configDimUnit, dimMeter);
                    break;
                case dimCentiMeter:
                    Configuration.setValue(configDimUnit, dimCentiMeter);
                    break;
                case dimMilliMeter:
                    Configuration.setValue(configDimUnit, dimMilliMeter);
                    break;
            }

        }

        for (let id in floorplan.corners) {
            let corner = floorplan.corners[id];
            corners[id] = this.newCorner(Dimensioning.cmFromMeasureRaw(corner.x), Dimensioning.cmFromMeasureRaw(corner.y), id);
            if (corner.elevation) {
                corners[id].elevation = Dimensioning.cmFromMeasureRaw(corner.elevation);
            }
        }
        let scope = this;
        floorplan.walls.forEach((wall) => {
            let newWall = scope.newWall(corners[wall.corner1], corners[wall.corner2]);

            if (wall.frontTexture) {
                if (wall.frontTexture.colormap) {
                    newWall.frontTexture = wall.frontTexture;
                } else {
                    newWall.frontTexture = defaultWallTexture;
                }

            }
            if (wall.backTexture) {
                if (wall.backTexture.colormap) {
                    newWall.backTexture = wall.backTexture;
                } else {
                    newWall.backTexture = defaultWallTexture;
                }

            }
            if (wall.thickness) {
                newWall.thickness = Dimensioning.cmFromMeasureRaw(wall.thickness);
            }
            // Adding of a, b, wallType (straight, curved) for walls happened
            // with introduction of 0.0.2a
            if (Version.isVersionHigherThan(floorplan.version, '0.0.2a')) {
                newWall.a = wall.a;
                newWall.b = wall.b;
                if (wall.wallType === 'CURVED') {
                    newWall.wallType = WallTypes.CURVED;
                } else {
                    newWall.wallType = WallTypes.STRAIGHT;
                }
            }
        });

        if ('newFloorTextures' in floorplan) {
            this.floorTextures = floorplan.newFloorTextures;
        }

        if('boundary' in floorplan){
            if(floorplan.boundary.points){
                let cmPoints = [];
                for (let i =0;i < floorplan.boundary.points.length;i++){
                    let point = floorplan.boundary.points[i];
                    let cmPoint = {
                        x: Dimensioning.cmFromMeasureRaw(point.x), 
                        y: Dimensioning.cmFromMeasureRaw(point.y),
                        elevation: Dimensioning.cmFromMeasureRaw(point.elevation),
                    };
                    cmPoints.push(cmPoint);
                }

                floorplan.boundary.points = cmPoints;
                this.__boundary.addBoundaryRegion(cmPoints);
                this.__boundary.metadata = floorplan.boundary;
            }
        }

        this.__updatesOn = true;
        this.update();
        Configuration.setValue(configDimUnit, currentUnit);
        this.dispatchEvent({ type: EVENT_LOADED, item: this });
    }

    // Load the floorplan from a previously saved json object file
    /**
     * @param {JSON} floorplan
     * @return {void}
     * @emits {EVENT_LOADED}
     */
    loadLockedFloorplan(floorplan) {
        if (floorplan === null || !('corners' in floorplan) || !('walls' in floorplan)) {
            return;
        }
        let currentUnit = Configuration.getStringValue(configDimUnit);
        if (floorplan.units) {
            switch (floorplan.units) {
                case dimInch:
                    Configuration.setValue(configDimUnit, dimInch);
                    break;
                case dimFeetAndInch:
                    Configuration.setValue(configDimUnit, dimFeetAndInch);
                    break;
                case dimMeter:
                    Configuration.setValue(configDimUnit, dimMeter);
                    break;
                case dimCentiMeter:
                    Configuration.setValue(configDimUnit, dimCentiMeter);
                    break;
                case dimMilliMeter:
                    Configuration.setValue(configDimUnit, dimMilliMeter);
                    break;
            }
        }


        let externalNewCorners = {};

        for (let id in floorplan.corners) {
            let cornerData = floorplan.corners[id];
            let corner = new Corner(this, Dimensioning.cmFromMeasureRaw(cornerData.x), Dimensioning.cmFromMeasureRaw(cornerData.y));
            corner.elevation = Dimensioning.cmFromMeasureRaw(cornerData.elevation);
            corner.isLocked = true;
            externalNewCorners[id] = corner;
            this.__externalCorners.push(corner);
        }

        for (let id in floorplan.simpleCorners) {
            let simpleCornerData = floorplan.simpleCorners[id];
            let simpleCorner = new SimpleCorner(this, Dimensioning.cmFromMeasureRaw(simpleCornerData.x), Dimensioning.cmFromMeasureRaw(simpleCornerData.y));

            simpleCorner.isLocked = true;
            externalNewCorners[id] = simpleCorner;
            this.__externalCorners.push(simpleCorner);
        }
        floorplan.walls.forEach((wall) => {
            let corner1 = externalNewCorners[wall.corner1];
            let corner2 = externalNewCorners[wall.corner2];
            let newWall = new Wall(corner1, corner2);
            newWall.isLocked = true;
            if (wall.frontTexture) {
                if (wall.frontTexture.colormap) {
                    newWall.frontTexture = wall.frontTexture;
                } else {
                    newWall.frontTexture = defaultWallTexture;
                }

            }
            if (wall.backTexture) {
                if (wall.backTexture.colormap) {
                    newWall.backTexture = wall.backTexture;
                } else {
                    newWall.backTexture = defaultWallTexture;
                }

            }
            if (wall.thickness) {
                newWall.thickness = Dimensioning.cmFromMeasureRaw(wall.thickness);
            }
            // Adding of a, b, wallType (straight, curved) for walls happened
            // with introduction of 0.0.2a
            if (Version.isVersionHigherThan(floorplan.version, '0.0.2a')) {
                newWall.a = wall.a;
                newWall.b = wall.b;
                if (wall.wallType === 'CURVED') {
                    newWall.wallType = WallTypes.CURVED;
                } else {
                    newWall.wallType = WallTypes.STRAIGHT;
                }
            }
            this.__externalWalls.push(newWall);
        });

        Configuration.setValue(configDimUnit, currentUnit);
        this.dispatchEvent({ type: EVENT_EXTERNAL_FLOORPLAN_LOADED, item: this });
    }

    /**
     * @deprecated
     */
    getFloorTexture(uuid) {
        if (uuid in this.floorTextures) {
            let floorTexture = this.floorTextures[uuid];
            if (floorTexture.colormap) {
                return floorTexture;
            }

        }
        return null;
    }

    /**
     * @deprecated
     */
    setFloorTexture(uuid, texturePack) {
        this.floorTextures[uuid] = texturePack;
    }

    /**
     * Resets the floorplan data to empty
     * 
     * @return {void}
     */
    reset() {
        var tmpCorners = this.corners.slice(0);
        // var tmpSimpleCorners = this.simpleCorners.slice(0);
        var tmpWalls = this.walls.slice(0);
        tmpCorners.forEach((corner) => {
            corner.remove();
        });
        tmpCorners.forEach((simpleCorner) => {
            simpleCorner.remove();
        });
        tmpWalls.forEach((wall) => {
            wall.remove();
        });
        this.corners = [];
        this.simpleCorners = [];
        this.walls = [];
        this.__externalCorners = [];
        this.__externalSimpleCorners = [];
        this.__externalWalls = [];
        this.dispatchEvent({ type: EVENT_MODE_RESET });
    }

    /**
     * Returns the center of the floorplan in the y plane
     * 
     * @return {Vector2} center
     * @see https://threejs.org/docs/#api/en/math/Vector2
     */
    getCenter() {
        return this.getDimensions(true);
    }

    /**
     * Returns the bounding volume of the full floorplan
     * 
     * @return {Vector3} size
     * @see https://threejs.org/docs/#api/en/math/Vector3
     */
    getSize() {
        return this.getDimensions(false);
    }

    getSize3() {
        let size2D = this.getDimensions();
        let size3D = new Vector3(size2D.x, size2D.z, -Number.MAX_VALUE);
        for (let i = 0; i < this.corners.length; i++) {
            let corner = this.corners[i];
            size3D.z = Math.max(size3D.z, corner.elevation);
        }
        return size3D;
    }

    setSize(newSize) {
        let i = 0;
        let m = new Matrix4();
        let currentSize = this.getSize3();
        let scale = newSize.clone().divide(currentSize);
        m.scale(scale);
        for (; i < this.corners.length; i++) {
            let corner = this.corners[i];
            let vector = new Vector3(corner.location.x, corner.location.y, corner.elevation);
            vector = vector.applyMatrix4(m);
            corner.elevation = vector.z;
            corner.move(vector.x, vector.y);
        }
    }

    /**
     * Returns the bounding size or the center location of the full floorplan
     * 
     * @param {boolean} center If true return the center else the size
     * @return {Vector3} size
     * @see https://threejs.org/docs/#api/en/math/Vector3
     */
    getDimensions(center) {
        center = center || false; // otherwise, get size
        let infinity = 1.0e10;
        let xMin = infinity;
        let xMax = -infinity;
        let zMin = infinity;
        let zMax = -infinity;
        this.corners.forEach((corner) => {
            xMin = Math.min(xMin, corner.x);
            xMax = Math.max(xMax, corner.x);
            zMin = Math.min(zMin, corner.y);
            zMax = Math.max(zMax, corner.y);
        });
        // console.log(xMin, xMax, zMin, zMax);        
        let ret;
        if (xMin === infinity || xMax === -infinity || zMin === infinity || zMax === -infinity) {
            ret = new Vector3();
        } else {
            if (center) {
                // center
                ret = new Vector3(xMin + ((xMax - xMin)* 0.5), 0, zMin + ((zMax - zMin) * 0.5));
            } else {
                // size
                ret = new Vector3((xMax - xMin), 0, (zMax - zMin));
            }
        }
        return ret;
    }

    /**
     * An internal cleanup method
     */
    assignOrphanEdges() {
        // let orphanWalls = [];
        this.walls.forEach((wall) => {
            if (!wall.backEdge && !wall.frontEdge) {
                let back = new HalfEdge(null, wall, false);
                let front = new HalfEdge(null, wall, true);
                wall.orphan = true;
                back.generatePlane();
                front.generatePlane();                
                // orphanWalls.push(wall);
            }
        });
    }

    /**
     * Update the floorplan
     * //Should include for , updatewalls=null
     */
    update(updateroomconfiguration = true, updatecorners = null) {
        if (!this.__updatesOn) {
            return;
        }
        if (updatecorners != null) {
            updatecorners.forEach((corner) => {
                corner.updateAngles();
            });
        }
        if (!updateroomconfiguration) {
            this.dispatchEvent({ type: EVENT_UPDATED, item: this });
            return;
        }

        // var scope = this;
        this.walls.forEach((wall) => {
            wall.resetFrontBack();
        });

        this.assignOrphanEdges();

        this.__roofPlanesForIntersection.length = 0;
        this.__floorPlanesForIntersection.length = 0;
        this.__wallPlanesForIntersection.length = 0;

        this.__wallPlanesForIntersection.push.apply(this.__wallPlanesForIntersection, this.createWallEdgePlanes());

        this.__cornerGroups.createGroups();

        this.dispatchEvent({ type: EVENT_NEW_ROOMS_ADDED, item: this });
    }

    /**
     * @param {CarbonSheet} val
     */
    set carbonSheet(val) {
        this._carbonSheet = val;
    }

    /**
     * @return {CarbonSheet} _carbonSheet reference to the instance of
     *         {@link CarbonSheet}
     */
    get carbonSheet() {
        return this._carbonSheet;
    }

    get roofPlanesForIntersection() {
        return this.__roofPlanesForIntersection;
    }

    get floorPlanesForIntersection() {
        return this.__floorPlanesForIntersection;
    }

    get wallPlanesForIntersection() {
        return this.__wallPlanesForIntersection;
    }

    get cornerGroups() {
        return this.__cornerGroups;
    }

    get externalCorners() {
        return this.__externalCorners;
    }

    get externalSimpleCorners() {
        return this.__externalSimpleCorners;
    }

    get externalWalls() {
        return this.__externalWalls;
    }

    get boundary(){
        return this.__boundary;
    }
}
export default Floorplan;