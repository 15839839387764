import { Configuration, configDimUnit } from "./core/configuration";
import { dimCentiMeter } from "./core/constants";
import { Model } from "./model/model";
import { Viewer2D, floorplannerModes } from "./viewer2d/Viewer2D";
import { ConfigurationHelper } from "./helpers/ConfigurationHelper";
import { FloorPlannerHelper } from "./helpers/FloorplannerHelper";

///** BlueprintJS core application. */
class BlueprintJS {
    /**
     * Creates an instance of BlueprintJS. This is the entry point for the application
     *
     * @param {Object} options The initialization options.
     * @param {string} options.floorplannerElement - Id of the html element to use as canvas. Needs to exist in the html
     * @param {string} options.threeElement - Id of the html element to use as canvas. Needs to exist in the html and should be #idofhtmlelement
     * @param {string} options.threeCanvasElement - Id of the html element to use as threejs-canvas. This is created automatically
     * @param {string} options.textureDir - path to texture directory. No effect
     * @param {boolean} options.widget - If widget mode then disable the controller from interactions
     * @example
     * let blueprint3d = new BP3DJS.BlueprintJS(opts);
     */
    constructor(options) {
        Configuration.setValue(configDimUnit, dimCentiMeter);

        /**
         * @property {Object} options
         * @type {Object}
         **/
        this.options = options;
        /**
         * @property {Model} model
         * @type {Model}
         **/
        this.model = new Model(options.textureDir);

        this.configurationHelper = new ConfigurationHelper();
        this.floorplanningHelper = null;
        if (!options.widget) {

            let viewer2dOptions = this.options.viewer2d.viewer2dOptions || {};
            viewer2dOptions.resize = this.options.resize;
            this.floorplanner = new Viewer2D(options.viewer2d.id, this.model.floorplan, viewer2dOptions);
            this.floorplanningHelper = new FloorPlannerHelper(this.model.floorplan, this.floorplanner);
        }
        this.view_now = 3;
        this.switchView();
    }

    switchView() {
        if (this.options.widget) {
            return;
        }
        this.floorplanner.switchMode(floorplannerModes.MOVE);
        if (this.view_now === 3 && !this.options.widget) {
            this.view_now = 2;
            document.getElementById(this.options.viewer2d.id).style.visibility = "visible";
            document.getElementById(this.options.viewer3d.id).style.visibility = "hidden";
        } else if (this.view_now === 2 && !this.options.widget) {
            this.view_now = 3;
            document.getElementById(this.options.viewer2d.id).style.visibility = "hidden";
            document.getElementById(this.options.viewer3d.id).style.visibility = "visible";
        }
    }

    setViewer2DModeToDraw() {
        if (this.options.widget) {
            return;
        }
        this.floorplanner.switchMode(floorplannerModes.DRAW);
    }

    setViewer2DModeToMove() {
        if (this.options.widget) {
            return;
        }
        this.floorplanner.switchMode(floorplannerModes.MOVE);
    }

    switchViewer2DToTransform() {
        if (this.options.widget) {
            return;
        }
        this.floorplanner.switchMode(floorplannerModes.EDIT_ISLANDS);
    }

    get currentView() {
        return this.view_now;
    }
}
export { BlueprintJS };