import {FakeWall2D} from "./FakeWall2D";
import {Dimensioning} from "../../core/dimensioning";
import {Vector2} from "three";
import {openingClicked} from "../environment/Event";
import {Environment} from "../environment/Environment";
import Corner from "../../model/corner";

export class Opening2D {

    /**
     * @param {DrawManager} drawManager
     * @param {OpeningManager} openingManager
     */
    constructor(drawManager, openingManager) {
        this.optionsDimension = {
            dimlinecolor: '#0000CC',
            dimarrowcolor: '#FFCCCC',
            dimtextcolor: '#FFAA00',
            dimtextalpha: 1,
            reverse: true,
            offset: Environment.drawConfiguration.main.cotation.step5
        }
        this.fakeWallOpening = null;
        this.fakeWallOpeningList = [];
        this.openingManager = openingManager;
        this.openingOnDrag = null;
        this.drawManager = drawManager;
        this.isOpeningMoving = false;
        this.startPosMouseX = null;
        this.startPosMouseY = null;
        this.posStartGrugeX = null;
        this.posStartGrugeY = null;
        this.keyMainCornerSectionOnDrag = null;
        this.lastPositionOpening = 0;
        this.directionMovement = null;
        this.isClick = false;
        this.init = false;
        this.fakewallPortalDims = [];
        this.vectorOpeningMove = new Vector2(0, 0);
    }

    /**
     * Dessine toutes les openings d'un side
     * @param fenceElement
     * @param {Corner|SimpleCorner} start
     * @param {Corner|SimpleCorner} end
     * @param openingsList
     * @param {string} keyMainCornerSection
     */
    drawOpenings(fenceElement, start, end, openingsList, keyMainCornerSection) {
        // on supprime les poteaux intermédiaires
        fenceElement[keyMainCornerSection].simpleCornersMainWall = [];
        // on redessine le mur principal, mais non visible
        this.drawManager.getDrawWall().addDrawWall(fenceElement, start, end, keyMainCornerSection, null, null, true, false);
        for (let j = 0; j < openingsList.length; j++) {
            openingsList[j].startMainWall = start;
            openingsList[j].endMainWall = end;
            // cas pour la première opening si une seule opening
            if (j === 0 && openingsList.length === 1) {
                this.drawOpening(start, end, openingsList[j], true);
            }
            // cas pour la première opening si plusieurs openings
            if (j === 0 && openingsList.length > 1) {
                this.drawOpening(start, openingsList[j + 1].corners[0], openingsList[j]);
            }
            // cas pour les openings intermédiaire
            if (j > 0 && j < openingsList.length - 1) {
                this.drawOpening(openingsList[j - 1].corners[openingsList[j - 1].corners.length - 1], openingsList[j + 1].corners[0], openingsList[j]);
            }
            // cas pour la dernière opening
            if (j > 0 && j === openingsList.length - 1) {
                this.drawOpening(openingsList[j - 1].corners[openingsList[j - 1].corners.length - 1], end, openingsList[j], true);
            }
        }
    }

    /**
     * Dessine une opening d'un side
     * @param {SimpleCorner|Corner} start
     * @param {SimpleCorner|Corner} end
     * @param openingData
     * @param {boolean} isLastPosition
     * (isFirstPosition=true) nous avertit que l'ouverture est la première du segment en cours, dans ce cas,
     * on dessine le premier mur, le poteau suivant sera relié directement à la fin de cette fonction, donc on ne doit
     * pas le recréer pour l'élément suivant. (autre ouverture ou dernier poteau du mur principal)
     * rappel : les poteaux (opening.corners) sont déjà stockés dans fenceElement, on peut donc les anticiper
     */
    drawOpening(start, end, openingData, isLastPosition = false) {
        // cas glissière
        if (openingData.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            this.drawOpeningSlide(start, end, openingData);
        }
        // cas portail
        if (openingData.typeOpening === Environment.drawConfiguration.opening.type.portal) {
            this.drawOpeningStart(start, openingData);
            this.drawOpeningDoor(openingData);
            this.drawOpeningEnd(end, openingData, isLastPosition);
        }
        this.drawOpeningGrugeBox(openingData);
        if (openingData.gateLeavesNumber === 2) {
            this.drawManager.sealingManager.drawSealingCenter(openingData);
        }
        this.drawManager.wallConfigManager.resetWallConfig(openingData.wallBase);
    }


    /**
     * Dessine le premier poteau de l'ouverture et le segment précédent si il est en première position
     * @param {SimpleCorner|Corner} start
     * @param {SimpleCorner|Corner} end
     * @param openingData
     */
    drawOpeningSlide(start, end, openingData) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        let keyMainCornerSection = openingData.belongToMainCornerSection;
        let corner1 = this.drawMainSimpleCorner(openingData.corners[0], keyMainCornerSection);
        corner1.isSlideOpeningCornerStart = true;
        openingData.corners[0] = corner1;

        let corner2 = this.drawMainSimpleCorner(openingData.corners[1], keyMainCornerSection);
        corner2.isSlideOpeningCornerMiddle = true;
        openingData.corners[1] = corner2;

        let corner3 = this.drawMainSimpleCorner(openingData.corners[2], keyMainCornerSection);
        corner3.isSlideOpeningCornerEnd = true;
        openingData.corners[2] = corner3;

        corner1.isSlideClosure = false;
        corner3.isSlideClosure = true;
        if(openingData.reverse){
            corner1.isSlideClosure = true;
            corner3.isSlideClosure = false;
        }
        // Mur fake pour optimisation, on ne dessine pas l'entité wall2D
        let wall1 = this.drawManager.getDrawWall().addDrawWall(fenceElement, start, corner1, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, null, false, true, true);
        openingData.wallStart = wall1;
        // Vérifie si le segment de l'ouverture possède une configuration dans le cas contraire il héritera de la config de son mur porteur (maiWall)
        if (!this.drawManager.wallConfigManager.getWallConfigData(wall1) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
            this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall1);
        }
        this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall1);
        this.drawManager.drawSealingsAndConfig(wall1, null);
        // Ajout de l'information wallEnd de l'ouverture précédente car le segment n'est créé que maintenant
        const position = this.openingManager.getPositionOfOpeningIntoList(fenceElement, keyMainCornerSection, openingData.id);
        if (position > 0) {
            const previousOpening = fenceElement[openingData.belongToMainCornerSection].openings[position - 1];
            previousOpening.wallEnd = wall1;
        }

        // Mur fake pour optimisation, on ne dessine pas l'entité wall2D
        let wall2 = this.drawManager.getDrawWall().addDrawWall(fenceElement, corner1, corner2, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, openingData.id, false, true, true);
        wall2.isPortalFixed = true;
        if(wall2.fenceTypeName === null && wall2.fenceTypeCode === null){
            wall2.fenceTypeName = openingData.wallBase.fenceTypeName;
            wall2.fenceTypeCode = openingData.wallBase.fenceTypeCode;
            this.drawManager.wallConfigManager.addNewWallConfig(wall2);
            this.drawManager.wallConfigManager.setConfigFenceTypeName(wall2);
            this.drawManager.wallConfigManager.setConfigFenceTypeCode(wall2);
        }
        // On récupère la couleur du portail
        if (openingData.wallPortal) {
            wall2.fakeWallColor = openingData.wallPortal.fakeWallColor;
        }
        openingData.wallPortalFixed = wall2;
        if (!this.drawManager.wallConfigManager.getWallConfigData(wall2) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
            //this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall2);
        }
        this.drawManager.drawSealingsAndConfig(wall2, openingData, false);
        this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall2, openingData, true, false);


        // Mur fake pour optimisation, on ne dessine pas l'entité wall2D
        let wall3 = this.drawManager.getDrawWall().addDrawWall(fenceElement, corner2, corner3, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, openingData.id, false, true, true);
        wall3.isPortal = true;
        // On récupère la couleur du portail
        if (openingData.wallPortal) {
            wall3.fakeWallColor = openingData.wallPortal.fakeWallColor;
        }
        if(wall3.fenceTypeName === null && wall3.fenceTypeCode === null){
            wall3.fenceTypeName = openingData.wallBase.fenceTypeName;
            wall3.fenceTypeCode = openingData.wallBase.fenceTypeCode;
            this.drawManager.wallConfigManager.addNewWallConfig(wall3);
            this.drawManager.wallConfigManager.setConfigSlideLength(wall3, Environment.dataEcommerceEnv.handRail.slideLengthMini);
            this.drawManager.wallConfigManager.setConfigFenceTypeName(wall3);
            this.drawManager.wallConfigManager.setConfigFenceTypeCode(wall3);
        }
        openingData.wallPortal = wall3;

        if (!this.drawManager.wallConfigManager.getWallConfigData(wall3) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
         //   this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall3);
        }
        this.drawManager.drawSealingsAndConfig(wall3, openingData, false);
        this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall3, openingData, true, false);

        if (this.drawManager.configuratorStarter.drawPanel.displayPortalDimension) {
            this.drawManager.dimensionManager.addDimension(wall2.start, wall2.end, this.optionsDimension, this.fakewallPortalDims);
            this.drawManager.dimensionManager.addDimension(wall3.start, wall3.end, this.optionsDimension, this.fakewallPortalDims);
        }


        if (position === fenceElement[keyMainCornerSection].openings.length - 1) {
            // Mur fake pour optimisation on ne déssine pas l'entité wall2D
            let wall4 = this.drawManager.getDrawWall().addDrawWall(fenceElement, corner3, end, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, null, false, true, true);
            openingData.wallEnd = wall4;
            // Vérifie si le segment de l'ouverture possède une configuration dans le cas contraire il héritera de la config de son mur porteur (maiWall)
            if (!this.drawManager.wallConfigManager.getWallConfigData(wall4) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
               this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall4);
            }
            this.drawManager.drawSealingsAndConfig(wall4, null);
            this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall4);
        }

    }

    /**
     * Dessine le premier poteau de l'ouverture et le segment précédent si il est en première position
     * @param {SimpleCorner|Corner} start
     * @param openingData
     */
    drawOpeningStart(start, openingData) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        let keyMainCornerSection = openingData.belongToMainCornerSection;
        let corner = this.drawMainSimpleCorner(openingData.corners[0], keyMainCornerSection);
        corner.isOutsideOpeningCornerStart = true;
        corner.setSimpleCornerColor();
        openingData.corners[0] = corner;
        // Mur fake pour optimisation, on ne dessine pas l'entité wall2D
        let wall = this.drawManager.getDrawWall().addDrawWall(fenceElement, start, corner, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, null, false, true, true);
        openingData.wallStart = wall;
        // Vérifie si le segment de l'ouverture possède une configuration dans le cas contraire il héritera de la config de son mur porteur (maiWall)
        if (!this.drawManager.wallConfigManager.getWallConfigData(wall) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
            this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall);
        }
        this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall);
        this.drawManager.drawSealingsAndConfig(wall, null);
        // Ajout de l'information wallEnd de l'ouverture précédente car le segment n'est créé que maintenant
        const position = this.openingManager.getPositionOfOpeningIntoList(fenceElement, keyMainCornerSection, openingData.id);
        if (position > 0) {
            const previousOpening = fenceElement[openingData.belongToMainCornerSection].openings[position - 1];
            previousOpening.wallEnd = wall;
        }

    }

    /**
     * Dessine les deux poteaux de la porte, un mur visible les reliant et un mur non visible reliant l'élément précédent
     * @param openingData
     */
    drawOpeningDoor(openingData) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        let keyMainCornerSection = openingData.belongToMainCornerSection;
        let cornersList = [];
        for (let i = 1; i < openingData.corners.length - 1; i++) {
            let corner = this.drawMainSimpleCorner(openingData.corners[i], keyMainCornerSection);
            cornersList.push(corner)
            if (i === 1) {
                corner.isInsideOpeningCornerStart = true;
            }
            if (i === 2) {
                corner.isInsideOpeningCornerEnd = true;
            }
            corner.fillColor = Environment.drawConfiguration.opening.color.insideCorner.default.fillColor;
            corner.borderColor = Environment.drawConfiguration.opening.color.insideCorner.default.borderColor;
            openingData.corners[i] = corner;
            if (i % 2 === 0) {
                // Mur fake pour optimisation, on ne dessine pas l'entité wall2D
                let wall = this.drawManager.getDrawWall().addDrawWall(fenceElement, openingData.corners[i - 1], corner, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, openingData.id, false, true, true);
                wall.isPortal = true;
                wall.gapStart = openingData.gapStart;
                wall.gapEnd = openingData.gapEnd;
                // On récupère la couleur du portail
                if (openingData.wallPortal) {
                    wall.fakeWallColor = openingData.wallPortal.fakeWallColor;
                }
                openingData.wallPortal = wall;
                if (!this.drawManager.wallConfigManager.getWallConfigData(wall) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
                    this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall);
                }
                this.drawManager.drawSealingsAndConfig(wall, openingData, false);
                this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall, openingData, true, false);
            }
            if (this.drawManager.configuratorStarter.drawPanel.displayPortalDimension) {
                this.drawManager.dimensionManager.addDimension(openingData.corners[0], openingData.corners[1], this.optionsDimension, this.fakewallPortalDims);
                this.drawManager.dimensionManager.addDimension(openingData.corners[1], openingData.corners[2], this.optionsDimension, this.fakewallPortalDims);
            }
            if (openingData.wallPortal) {
                cornersList.forEach((item) => {
                    item.portalType = openingData.portalType;
                    item.setSimpleCornerColor();
                })
            }
        }
    }

    /**
     * Dessine le dernier poteau de l'ouverture et le relie à l'élément précédent (mur non visible) et suivant (mur visible)
     * @param {SimpleCorner|Corner} end
     * @param openingData
     @param {boolean} isLastPosition
     */
    drawOpeningEnd(end, openingData, isLastPosition) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        let keyMainCornerSection = openingData.belongToMainCornerSection;
        let corner = this.drawMainSimpleCorner(openingData.corners[3], keyMainCornerSection);
        corner.isOutsideOpeningCornerEnd = true;
        corner.setSimpleCornerColor();
        openingData.corners[3] = corner;
        if (isLastPosition) {
            // Mur fake pour optimisation on ne déssine pas l'entité wall2D
            let wall = this.drawManager.getDrawWall().addDrawWall(fenceElement, corner, end, keyMainCornerSection, fenceElement[keyMainCornerSection].openingsWalls.length + 1, null, false, true, true);
            openingData.wallEnd = wall;
            // Vérifie si le segment de l'ouverture possède une configuration dans le cas contraire il héritera de la config de son mur porteur (maiWall)
            if (!this.drawManager.wallConfigManager.getWallConfigData(wall) && this.drawManager.wallConfigManager.getWallConfigData(openingData.wallBase)) {
                this.drawManager.wallConfigManager.copyAndSetWallConfig(openingData.wallBase, wall);
            }
            this.drawManager.drawSealingsAndConfig(wall, null);
            this.drawManager.getDrawWall().addFakeWallSet(fenceElement, wall);
        }
        if (this.drawManager.configuratorStarter.drawPanel.displayPortalDimension) {
            this.drawManager.dimensionManager.addDimension(openingData.corners[2], openingData.corners[3], this.optionsDimension, this.fakewallPortalDims);
        }
    }

    /**
     * Dessine la grugeBox invisible pour cette ouverture
     * @param openingData
     */
    drawOpeningGrugeBox(openingData) {
        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingData.belongToFenceElement);
        let keyMainCornerSection = openingData.belongToMainCornerSection;
        let width = Dimensioning.cmToPixel(openingData.gapStart + openingData.gapEnd + openingData.openingSize) + fenceElement.cornerRadius * 2;
        if(openingData.typeOpening === Environment.drawConfiguration.opening.type.slide){
            width = Dimensioning.cmToPixel(openingData.openingSize) + fenceElement.cornerRadius * 2
        }
        const options = {
            normalizeVector: fenceElement[keyMainCornerSection].mainWalls[openingData.belongToMainWallKey].wallDirectionNormalized(),
            posX: Dimensioning.cmToPixel((openingData.corners[0].x + openingData.corners[openingData.corners.length - 1].x) / 2),
            posY: Dimensioning.cmToPixel((openingData.corners[0].y + openingData.corners[openingData.corners.length - 1].y) / 2),
            width: width,
            height: fenceElement.cornerRadius * 2,
            color: Environment.drawConfiguration.opening.grugeBox.color.off,
            opacity: Environment.drawConfiguration.opening.grugeBox.opacity.off
        }
        let grugeBox = (new FakeWall2D(options)).drawFakeWall();
        grugeBox.idOpening = openingData.id;
        grugeBox.side = keyMainCornerSection;
        grugeBox.selected = false;
        this.fakeWallOpeningList.push(grugeBox);
        this.addGrugeBoxEvents(fenceElement, grugeBox, keyMainCornerSection, openingData);
        this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(grugeBox);
    }

    updateSimpleCornerOpening(mainCornerOnDrag, openingOnDrag) {

        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingOnDrag.belongToFenceElement)
        let position = this.openingManager.getPositionOfOpeningIntoList(fenceElement, mainCornerOnDrag, openingOnDrag.id);


        for (let k = 0; k < fenceElement[mainCornerOnDrag].openings[position].corners.length; k++) {
            let cornerView = fenceElement[mainCornerOnDrag].openings[position].corners[k].simpleCornerView;
            let vector = fenceElement[mainCornerOnDrag].mainWalls[openingOnDrag.belongToMainWallKey].wallDirectionNormalized();

            // cas glissière
            if (openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
                let offsetReverse = 0;
                if(openingOnDrag.reverse){
                    offsetReverse = openingOnDrag.wallPortalFixed.centerDistance - openingOnDrag.wallPortal.centerDistance;
                }
                const offsetOpeningSize = (openingOnDrag.wallPortal.centerDistance + openingOnDrag.wallPortalFixed.centerDistance)/2
                switch (k) {
                    case 0:
                        cornerView.x = this.fakeWallOpening.position.x - Dimensioning.cmToPixel(offsetOpeningSize) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y - Dimensioning.cmToPixel(offsetOpeningSize) * vector.y;
                        break;
                    case 1:
                        cornerView.x = this.fakeWallOpening.position.x + Dimensioning.cmToPixel(openingOnDrag.wallPortalFixed.centerDistance - offsetOpeningSize - offsetReverse) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y + Dimensioning.cmToPixel(openingOnDrag.wallPortalFixed.centerDistance - offsetOpeningSize - offsetReverse) * vector.y;
                        break;
                    case 2:
                        cornerView.x = this.fakeWallOpening.position.x + Dimensioning.cmToPixel(offsetOpeningSize) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y + Dimensioning.cmToPixel(offsetOpeningSize) * vector.y;
                        break;
                    default:
                }
            }
            // cas portail
            if (openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.portal) {
                switch (k) {
                    case 0:
                        cornerView.x = this.fakeWallOpening.position.x - Dimensioning.cmToPixel(openingOnDrag.openingSize / 2 + openingOnDrag.gapStart) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y - Dimensioning.cmToPixel(openingOnDrag.openingSize / 2 + openingOnDrag.gapStart) * vector.y;
                        break;
                    case 1:
                        cornerView.x = this.fakeWallOpening.position.x - Dimensioning.cmToPixel(openingOnDrag.openingSize / 2) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y - Dimensioning.cmToPixel(openingOnDrag.openingSize / 2) * vector.y;
                        break;
                    case 2:
                        cornerView.x = this.fakeWallOpening.position.x + Dimensioning.cmToPixel(openingOnDrag.openingSize / 2) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y + Dimensioning.cmToPixel(openingOnDrag.openingSize / 2) * vector.y;
                        break;
                    case 3:
                        cornerView.x = this.fakeWallOpening.position.x + Dimensioning.cmToPixel(openingOnDrag.openingSize / 2 + openingOnDrag.gapEnd) * vector.x;
                        cornerView.y = this.fakeWallOpening.position.y + Dimensioning.cmToPixel(openingOnDrag.openingSize / 2 + openingOnDrag.gapEnd) * vector.y;
                        break;
                    default:
                }
            }

        }
    }

    /**
     * Supprime une ouverture
     * @param fenceElement
     * @param {string} keyMainCornerSection
     * @param openingData
     */
    deleteOpening(fenceElement, keyMainCornerSection, openingData) {
        Reflect.deleteProperty(fenceElement[keyMainCornerSection].openings, this.openingManager.getPositionOfOpeningIntoList(fenceElement, keyMainCornerSection, openingData.id));
        let openingsTemp = [];
        for (let i = 0; i < fenceElement[keyMainCornerSection].openings.length; i++) {
            if (fenceElement[keyMainCornerSection].openings[i] !== undefined) {
                openingsTemp.push(fenceElement[keyMainCornerSection].openings[i]);
            }
        }
        fenceElement[keyMainCornerSection].openings = openingsTemp;
        this.openingManager.openingConfigData.updateDataOpenings(keyMainCornerSection, fenceElement);
        this.drawManager.drawFence();

    }

    /**
     * Création d'un poteau principal d'une ouverture
     * @param {SimpleCorner} openingCorner
     * @param keyMainCornerSection
     * @returns {SimpleCorner}
     */
    drawMainSimpleCorner(openingCorner, keyMainCornerSection) {
        let corner = this.drawManager.getFloorplan().newSimpleCorner(openingCorner.x, openingCorner.y, openingCorner.id);
        corner.setIsOpening(true);
        corner.setBelongToMainCornerSection(keyMainCornerSection);
        return corner;
    }

    /**
     * Rend visible tous les murs de l'ouverture
     */
    showWallOpening(fenceElement) {
        for (let i = 0; i < fenceElement[this.keyMainCornerSectionOnDrag].openingsWalls.length; i++) {
            let idList = fenceElement[this.keyMainCornerSectionOnDrag].openingsWalls[i].id.split(',');
            if (idList[1].split('-')[1] === this.openingOnDrag.id) {
                fenceElement[this.keyMainCornerSectionOnDrag].openingsWalls[i].setVisible(true);
            }
        }
    }

    /**
     * Retourne le mouvement sur son segment de l'ouverture, en fonction du mouvement de la souris
     * @param {int} posMouseStartX in pixel
     * @param {int} posMouseStartY in pixel
     * @param {int} posMouseEndX in pixel
     * @param {int} posMouseEndY in pixel
     * @param {string} sideOpeningOnMoving
     * @param openingOnDrag
     * @param {int} speed
     * @param direction
     * @returns {Vector2}
     */
    setVectorMoveOpening(posMouseStartX, posMouseStartY, posMouseEndX, posMouseEndY, sideOpeningOnMoving, openingOnDrag, speed = 1, direction = null) {

        let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(openingOnDrag.belongToFenceElement);
        let mainWall = fenceElement[sideOpeningOnMoving].mainWalls[openingOnDrag.belongToMainWallKey];
        // On calcule la distance parcourue par la souris
        let lengthMoveMousePixel;
        if (this.init) {
            lengthMoveMousePixel = this.lastPositionOpening;
            this.init = false;
        } else {
            lengthMoveMousePixel = Math.sqrt(Math.pow(posMouseEndX - posMouseStartX, 2) + Math.pow(posMouseEndY - posMouseStartY, 2))
        }
        this.lastPositionOpening = direction * lengthMoveMousePixel;

        return new Vector2(
            direction * speed * lengthMoveMousePixel * mainWall.wallDirectionNormalized().x,
            direction * speed * lengthMoveMousePixel * mainWall.wallDirectionNormalized().y
        );
    }

    /**
     * Mise à jour des variables this.fakeWallOpening.overEnd et this.fakeWallOpening.overStart
     * en fonction de la position du grugeur
     * @param {FakeWall2D} grugeBox
     * @param fenceElement
     * @param openingData
     */
    checkOpeningNotOutOfBounds(grugeBox, fenceElement, openingData) {
        grugeBox.overEnd = false;
        grugeBox.overStart = false;
        // une seule opening
        let posOpeningOnMainWall = this.openingManager.getPosOpeningOnMainWall(openingData);
        let countOpeningsOnMainWall = this.openingManager.getCountOpeningsOnMainWall(
            fenceElement[this.keyMainCornerSectionOnDrag].openings,
            openingData.belongToMainWallKey
        );
        let nextOpenings = null;
        let previousOpenings = null;
        if (countOpeningsOnMainWall === 1) {
            this.openingManager.grugeBoxIsNotBetween(
                grugeBox,
                fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[openingData.belongToMainWallKey].end,
                fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[openingData.belongToMainWallKey].start
            );
        }
        // plusieurs openings on détermine ses voisins
        let before = false;
        let after = false;
        if (countOpeningsOnMainWall > 0) {
            if (posOpeningOnMainWall !== 0) {
                before = true;
            }
            if (posOpeningOnMainWall < countOpeningsOnMainWall - 1) {
                after = true;
            }
        }
        if (before) {
            previousOpenings = this.openingManager.getPreviousOpeningOnMainWall(this.openingOnDrag);
        }
        if (after) {
            nextOpenings = this.openingManager.getNextOpeningOnMainWall(this.openingOnDrag);
        }
        if (before && after && nextOpenings && previousOpenings) {
            this.openingManager.grugeBoxIsNotBetween(
                grugeBox,
                nextOpenings.corners[0],
                previousOpenings.corners[previousOpenings.corners.length - 1]
            );
        }
        if (before && !after && previousOpenings) {
            this.openingManager.grugeBoxIsNotBetween(
                grugeBox,
                previousOpenings.corners[previousOpenings.corners.length - 1],
                fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[openingData.belongToMainWallKey].end
            );
        }
        if (after && !before && nextOpenings) {
            this.openingManager.grugeBoxIsNotBetween(
                grugeBox,
                fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[openingData.belongToMainWallKey].start,
                nextOpenings.corners[0],
            );
        }
    }

    /** ******************************************************************************************/

    /**                                 FONCTIONS EVENT                                         **/
    /** ******************************************************************************************/

    addGrugeBoxEvents(fenceElement, grugeBox, mainCornerSection, openingData) {
        grugeBox.on("mousedown", this.__dragStartOpening.bind(this, grugeBox, mainCornerSection, openingData));
        grugeBox.on("mousemove", this.__dragMoveOpening.bind(this, grugeBox));
        document.addEventListener("mouseup", this.__dragEndOpening.bind(this));
        grugeBox.on("pointerover", () => {
            if (grugeBox.selected === false) {
                grugeBox.alpha = Environment.drawConfiguration.opening.grugeBox.opacity.hover;
                grugeBox.tint = Environment.drawConfiguration.opening.grugeBox.color.hover;
            } else {
                grugeBox.alpha = Environment.drawConfiguration.opening.grugeBox.opacity.off;
                grugeBox.tint = Environment.drawConfiguration.opening.grugeBox.color.off;
            }
        });
        grugeBox.on("pointerout", () => {
            grugeBox.alpha = Environment.drawConfiguration.opening.grugeBox.opacity.off;
            grugeBox.tint = Environment.drawConfiguration.opening.grugeBox.color.off;
        });
    }

    /**
     * @param grugeBox
     * @param mainCornerSection
     * @param openingData
     * @param event
     * @private
     */
    __dragStartOpening(grugeBox, mainCornerSection, openingData, event) {

        this.isClick = true;
        this.openingOnDrag = openingData
        this.keyMainCornerSectionOnDrag = mainCornerSection;
        grugeBox.selected = true;
        this.drawManager.drawWall2D.grugeVisible = false;
        event.stopPropagation();
        this.vectorOpeningMove = this.setVectorMoveOpening(
            this.startPosMouseX,
            this.startPosMouseY,
            event.data.global.x,
            event.data.global.y,
            this.keyMainCornerSectionOnDrag,
            this.openingOnDrag,
            this.drawManager.resizingCoefficient,
            this.directionMovement
        );
        if (grugeBox.side === mainCornerSection && grugeBox.idOpening === this.openingOnDrag.id) {
            let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(this.openingOnDrag.belongToFenceElement);
            this.selected = true;
            // renseigne dragMoveOpening et dragEndOpening
            this.isOpeningMoving = true;
            // On récupère les coordonnées de la souris au clic de départ
            this.startPosMouseX = event.data.global.x;
            this.startPosMouseY = event.data.global.y;
            let position = this.openingManager.getPositionOfOpeningIntoList(fenceElement, this.keyMainCornerSectionOnDrag, this.openingOnDrag.id);
            let openingSelected = fenceElement[this.keyMainCornerSectionOnDrag].openings[position];
            this.showWallOpening(fenceElement);
            this.drawManager.intermediateCorner2D.hideSimpleCornersMainWall(fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey]);
            // cas glissière
            if (openingData.typeOpening === Environment.drawConfiguration.opening.type.slide) {
                this.posStartGrugeX = Dimensioning.cmToPixel((openingSelected.corners[0].x + openingSelected.corners[2].x) / 2);
                this.posStartGrugeY = Dimensioning.cmToPixel((openingSelected.corners[0].y + openingSelected.corners[2].y) / 2);
            }
            // cas portail
            if (openingData.typeOpening === Environment.drawConfiguration.opening.type.portal) {
                this.posStartGrugeX = Dimensioning.cmToPixel((openingSelected.corners[0].x + openingSelected.corners[3].x) / 2);
                this.posStartGrugeY = Dimensioning.cmToPixel((openingSelected.corners[0].y + openingSelected.corners[3].y) / 2);
            }
            let width = Dimensioning.cmToPixel(this.openingOnDrag.openingSize + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2);
            if (this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
                width = Dimensioning.cmToPixel(this.openingOnDrag.wallPortalFixed.centerDistance + this.openingOnDrag.wallPortal.centerDistance + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2)
            }
            const options = {
                normalizeVector: fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[openingData.belongToMainWallKey].wallDirectionNormalized(),
                posX: this.posStartGrugeX,
                posY: this.posStartGrugeY,
                width: width,
                height: Dimensioning.cmToPixel(fenceElement.cornerRadius * 2),
                color: Environment.drawConfiguration.opening.grugeBox.color.onMove,
                opacity: Environment.drawConfiguration.opening.grugeBox.opacity.onMove
            }
            this.fakeWallOpening = (new FakeWall2D(options)).drawFakeWall();
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.fakeWallOpening);

        }
    }


    reverseAngle(angle) {
        if (angle > 180 && angle < 360) {
            return angle - 180;
        }
        return angle + 180;
    }

    getDirectionMovementFromAngle(angleMov, angleFix, gapDeg) {

        let angleDegWallReverse = this.reverseAngle(angleFix)
        if (angleMov < angleFix + gapDeg && angleMov > angleFix - gapDeg) {
            return 1;
        }
        if (angleMov > angleDegWallReverse - gapDeg && angleMov < angleDegWallReverse + gapDeg) {
            return -1;
        }
        return null;
    }

    /**
     * @param grugeBox
     * @param event
     * @private
     */
    __dragMoveOpening(grugeBox, event) {
        if (this.selected) {
            grugeBox.selected = true;
            this.drawManager.sealingManager.sealings.forEach((sealing) => {
                sealing.visible = false;
            })
            this.isClick = false;
            this.drawManager.dimensionManager.resetDimension(this.drawManager.sealingManager.sealingDims);
            this.drawManager.dimensionManager.resetDimension(this.drawManager.intermediateCorner2D.intermediateDimension);
            this.drawManager.dimensionManager.resetDimension(this.drawManager.drawWall2D.fakeWallDims);
            this.drawManager.dimensionManager.resetDimension(this.drawManager.openingManager.opening2D.fakewallPortalDims);
            this.drawManager.configuratorStarter.portalPanel.__closePanel();
            grugeBox.selected = true;
            event.stopPropagation();
            if (this.isOpeningMoving) {
                let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(this.openingOnDrag.belongToFenceElement);
                if (this.startPosMouseX === null) {
                    this.startPosMouseX = event.data.global.x;
                }
                if (this.startPosMouseY === null) {
                    this.startPosMouseY = event.data.global.y;
                }
                const wallNormalized = fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey].wallDirectionNormalized();
                let angleInDegMouse = (new Vector2(event.data.global.x - this.startPosMouseX, event.data.global.y - this.startPosMouseY)).angle() * (180 / Math.PI);
                let angleInDegWall = wallNormalized.angle() * (180 / Math.PI);
                this.directionMovement = this.getDirectionMovementFromAngle(angleInDegMouse, angleInDegWall, 30);

                // On récupère le mouvement de l'ouverture
                if (this.directionMovement) {
                    this.vectorOpeningMove = this.setVectorMoveOpening(
                        this.startPosMouseX,
                        this.startPosMouseY,
                        event.data.global.x,
                        event.data.global.y,
                        this.keyMainCornerSectionOnDrag,
                        this.openingOnDrag,
                        this.drawManager.resizingCoefficient,
                        this.directionMovement
                    );
                }
                // On supprime le gruge actuel
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.fakeWallOpening);
                let width = Dimensioning.cmToPixel(this.openingOnDrag.openingSize + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2);
                if (this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
                    width = Dimensioning.cmToPixel(this.openingOnDrag.wallPortalFixed.centerDistance + this.openingOnDrag.wallPortal.centerDistance + Environment.drawConfiguration.main.spacingMiniBetween2Corner * 2)
                }
                // Création du nouveau grugeur
                const options = {
                    normalizeVector: wallNormalized,
                    posX: this.posStartGrugeX + this.vectorOpeningMove.x,
                    posY: this.posStartGrugeY + this.vectorOpeningMove.y,
                    width: width,
                    height: Dimensioning.cmToPixel(fenceElement.cornerRadius * 2),
                    color: Environment.drawConfiguration.opening.grugeBox.color.onMove,
                    opacity: Environment.drawConfiguration.opening.grugeBox.opacity.onMove
                };
                this.fakeWallOpening = (new FakeWall2D(options)).drawFakeWall();
                this.updateDimensionsOnMove(this.vectorOpeningMove, wallNormalized, this.openingOnDrag)
                // On vérifie si le grugeur est dans ses frontières
                // Les valeurs de this.fakeWallOpening.overStart et this.fakeWallOpening.overEnd sont mises à jour.
                this.checkOpeningNotOutOfBounds(this.fakeWallOpening, fenceElement, this.openingOnDrag);
                // Les éléments fake sont mis à jour uniquement dans ces frontières
                if (!this.fakeWallOpening.overStart && !this.fakeWallOpening.overEnd) {
                    this.updateSimpleCornerOpening(this.keyMainCornerSectionOnDrag, this.openingOnDrag);
                    this.drawManager.drawWall2D.updateFakeElements(fenceElement, this.openingOnDrag);
                }
                // Changement de couleur du grugeur quand il se trouve hors limites
                if (this.fakeWallOpening.overStart) {
                    this.fakeWallOpening.tint = Environment.drawConfiguration.opening.grugeBox.color.isOut;
                    this.fakeWallOpening.alpha = Environment.drawConfiguration.opening.grugeBox.opacity.isOut;
                }
                if (this.fakeWallOpening.overEnd) {
                    this.fakeWallOpening.tint = Environment.drawConfiguration.opening.grugeBox.color.isOut;
                    this.fakeWallOpening.alpha = Environment.drawConfiguration.opening.grugeBox.opacity.isOut;
                }
                // ajout du grugeur au dessin
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.addChild(this.fakeWallOpening);
            }

        }
    }

    updateDimensionsOnMove(vectorOpeningMove, wallNormalized) {
        const next = this.openingManager.getNextOpeningOnMainWall(this.openingOnDrag)
        const previous = this.openingManager.getPreviousOpeningOnMainWall(this.openingOnDrag)
        let start = this.openingOnDrag.startMainWall;
        let end = this.openingOnDrag.endMainWall;
        let endPrevious;
        let startNext;
        if (previous) {
            endPrevious = previous.corners[previous.corners.length - 1];
        }
        if (next) {
            startNext = next.corners[0];
        }
        let offsetOpeningSize = this.openingOnDrag.openingSize / 2;
        if (this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide) {
            offsetOpeningSize = (this.openingOnDrag.wallPortal.centerDistance + this.openingOnDrag.wallPortalFixed.centerDistance)/2
        }
        let corner1 = new Corner(
            this.drawManager.floorplan,
            Dimensioning.pixelToCm(this.posStartGrugeX + vectorOpeningMove.x) - (offsetOpeningSize + this.openingOnDrag.gapStart) * wallNormalized.x,
            Dimensioning.pixelToCm(this.posStartGrugeY + vectorOpeningMove.y) - (offsetOpeningSize + this.openingOnDrag.gapStart) * wallNormalized.y,
            "1"
        );
        let corner2 = new Corner(
            this.drawManager.floorplan,
            Dimensioning.pixelToCm(this.posStartGrugeX + vectorOpeningMove.x) + (this.openingOnDrag.gapEnd + offsetOpeningSize) * wallNormalized.x,
            Dimensioning.pixelToCm(this.posStartGrugeY + vectorOpeningMove.y) + (this.openingOnDrag.gapEnd + offsetOpeningSize) * wallNormalized.y,
            "2"
        );
        this.drawManager.drawWall2D.fakeWallDims = [];

        if (next) {
            this.drawManager.dimensionManager.addDimension(
                corner2,
                startNext,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
            this.drawManager.dimensionManager.addDimension(
                startNext,
                end,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
        }
        if (previous) {
            this.drawManager.dimensionManager.addDimension(
                start,
                endPrevious,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
            this.drawManager.dimensionManager.addDimension(
                endPrevious,
                corner1,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
        }
        if (!previous) {
            this.drawManager.dimensionManager.addDimension(
                start,
                corner1,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
        }
        if (!next) {
            this.drawManager.dimensionManager.addDimension(
                corner2,
                end,
                this.optionsDimension,
                this.drawManager.drawWall2D.fakeWallDims
            );
        }
        this.drawManager.dimensionManager.addDimension(
            corner1,
            corner2,
            this.optionsDimension,
            this.drawManager.drawWall2D.fakeWallDims
        );

        this.drawManager.dimensionManager.drawDimension(this.drawManager.drawWall2D.fakeWallDims)
        corner1.remove();
        corner2.remove();
    }

    /**
     * @param event
     * @private
     */
    __dragEndOpening(event) {
        if (this.isClick) {
            this.isClick = false;
            this.selected = false;
            openingClicked.detail.item = this.openingOnDrag;
            openingClicked.detail.event = event;
            document.dispatchEvent(openingClicked)
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.fakeWallOpening);
            this.drawManager.drawFence();

        }
        if (this.selected) {
            this.selected = false;
            if (this.isOpeningMoving) {
                let fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(this.openingOnDrag.belongToFenceElement);
                // On récupère le mouvement de l'ouverture
                let vectorOpeningEnd = this.setVectorMoveOpening(
                    this.startPosMouseX,
                    this.startPosMouseY,
                    event.clientX,
                    event.clientY,
                    this.keyMainCornerSectionOnDrag,
                    this.openingOnDrag,
                    this.drawManager.resizingCoefficient,
                    this.directionMovement
                );
                // position de l'ouverture dans la liste des ouvertures de la section
                let posOpening = this.openingManager.getPositionOfOpeningIntoList(fenceElement, this.keyMainCornerSectionOnDrag, this.openingOnDrag.id);
                // coordonnées de la nouvelle position de départ de l'ouverture en cm
                let posStartX = fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey].start.x - fenceElement[this.keyMainCornerSectionOnDrag].openings[posOpening].corners[0].x;
                let posStartY = fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey].start.y - fenceElement[this.keyMainCornerSectionOnDrag].openings[posOpening].corners[0].y;
                // mise à jour de la nouvelle position (posStart) de départ de l'ouverture
                // Cas où la position de l'ouverture est placée hors limite
                if (this.fakeWallOpening.overStart || this.fakeWallOpening.overEnd) {
                    this.setPosStartOpeningAllCaseOfOutOfBounds();
                }
                // Cas où la position de l'ouverture est placée dans la zone autorisée
                if (!this.fakeWallOpening.overEnd && !this.fakeWallOpening.overStart) {
                    fenceElement[this.keyMainCornerSectionOnDrag].openings[posOpening].posStart = Math.sqrt(Math.pow(posStartX - Dimensioning.pixelToCm(vectorOpeningEnd.x), 2) + Math.pow(posStartY - Dimensioning.pixelToCm(vectorOpeningEnd.y), 2));
                }
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.fakeWallOpening);
                this.openingManager.openingConfigData.updateDataOpenings(this.keyMainCornerSectionOnDrag, fenceElement);
                this.drawManager.drawFence();
            }
            if (this.isOpeningMoving && (this.fakeWallOpening.overEnd || this.fakeWallOpening.overStart)) {
                alert("Désolé vous êtes hors des limites autorisés pour déplacer cette ouverture.");
                this.drawManager.blueprint3d.floorplanner.__floorplanContainer.removeChild(this.fakeWallOpening);
                this.drawManager.drawFence();
            }

        }
        // remise à zéro des variables
        this.drawManager.drawWall2D.grugeVisible = true;
        this.isOpeningMoving = false;
        this.startPosMouseX = null;
        this.startPosMouseY = null;
        this.posStartGrugeX = null;
        this.posStartGrugeY = null;
        this.openingOnDrag = null;
        this.keyMainCornerSectionOnDrag = null;
        this.directionMovement = null;

    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'ouverture placée entre deux autres ouvertures, le plus proche possible de sa sortie
     * gère les deux hors limites possibles
     * @param {Corner} startMainCorner
     * @param {Wall} wallBase
     * @param {Number} posStart1
     * @param {Number} posStart2
     */
    setPosStartOverMiddleOpening(startMainCorner, wallBase, posStart1, posStart2) {
        // if over to opening left
        if (this.fakeWallOpening.overStart && this.fakeWallOpening.overStart.id !== startMainCorner.id) {
            this.setPosStartOverMiddleOpeningInRelationToOneOpening(wallBase, posStart1, posStart2);
        }
        // if over to opening right
        if (this.fakeWallOpening.overEnd && this.fakeWallOpening.overEnd.id !== startMainCorner.id) {
            this.setPosStartOverMiddleOpeningInRelationToOneOpening(wallBase, posStart2, posStart1);
        }
    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'ouverture placée entre deux autres ouvertures, le plus proche possible de sa sortie
     * gère une des deux hors limites possibles
     * @param {Wall} wallBase
     * @param {Number} posStart1
     * @param {Number} posStart2
     */
    setPosStartOverMiddleOpeningInRelationToOneOpening(wallBase, posStart1, posStart2) {
        if (
            (wallBase.start.x <= wallBase.end.x && wallBase.start.y <= wallBase.end.y)
        ) {
            this.openingOnDrag.posStart = posStart1

        }
        if (
            (wallBase.start.x >= wallBase.end.x && wallBase.start.y >= wallBase.end.y) ||
            (wallBase.start.x > wallBase.end.x && wallBase.start.y < wallBase.end.y) ||
            (wallBase.start.x < wallBase.end.x && wallBase.start.y > wallBase.end.y)
        ) {
            this.openingOnDrag.posStart = posStart2;
        }
    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'ouverture en première position, le plus proche possible de sa sortie
     * gère les deux hors limites possibles
     * @param {Corner} startMainCorner
     * @param {Wall} wallBase
     * @param {Number} posStart1
     * @param {Number} posStart2
     */
    setPosStartOverFirstOpening(startMainCorner, wallBase, posStart1, posStart2) {
        if (this.fakeWallOpening.overStart && this.fakeWallOpening.overStart.id !== startMainCorner.id) {
            this.setPosStartOverFirstOpeningInRelationToOneCorner(wallBase, posStart1, posStart2);
        }
        if (this.fakeWallOpening.overEnd && this.fakeWallOpening.overEnd.id !== startMainCorner.id) {
            this.setPosStartOverFirstOpeningInRelationToOneCorner(wallBase, posStart2, posStart1);
        }
    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'ouverture en première position, le plus proche possible de sa sortie
     * gère une des deux hors limites possibles
     * @param {Wall} wallBase
     * @param {Number} posStart1
     * @param {Number} posStart2
     */
    setPosStartOverFirstOpeningInRelationToOneCorner(wallBase, posStart1, posStart2) {
        if (wallBase.start.x >= wallBase.end.x && wallBase.start.y >= wallBase.end.y) {
            this.openingOnDrag.posStart = posStart1;
        } else {
            this.openingOnDrag.posStart = posStart2;
        }
    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'ouverture en dernière position, le plus proche possible de sa sortie
     * @param {Corner} startMainCorner
     * @param {Number} posStartFromPreviousOpening
     * @param {Number} posStartOverOpposedMainCorner
     */
    setPosStartOverLastOpening(startMainCorner, posStartFromPreviousOpening, posStartOverOpposedMainCorner) {
        if (this.fakeWallOpening.overStart.id === startMainCorner.id) {
            this.openingOnDrag.posStart = posStartFromPreviousOpening;
        }
        if (this.fakeWallOpening.overEnd.id === startMainCorner.id) {
            this.openingOnDrag.posStart = posStartFromPreviousOpening;
        }
        if (this.fakeWallOpening.overStart && this.fakeWallOpening.overStart.id !== startMainCorner.id) {
            this.openingOnDrag.posStart = posStartOverOpposedMainCorner;
        }
        if (this.fakeWallOpening.overEnd && this.fakeWallOpening.overEnd.id !== startMainCorner.id) {
            this.openingOnDrag.posStart = posStartOverOpposedMainCorner;
        }
    }

    /**
     * Gére tous les cas de repositionnement d'une ouverture hors limites
     */
    setPosStartOpeningAllCaseOfOutOfBounds() {
        const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(this.openingOnDrag.belongToFenceElement);
        const spacingMini = Environment.drawConfiguration.main.spacingMiniBetween2Corner;
        const wallBase = this.openingOnDrag.wallBase;
        const startMainCorner = fenceElement[this.keyMainCornerSectionOnDrag].corner;
        const previousOpening = this.openingManager.getPreviousOpeningOnMainWall(this.openingOnDrag);
        const nextOpening = this.openingManager.getNextOpeningOnMainWall(this.openingOnDrag);
        let posStartFromPreviousOpening = null;
        let posStartFromNextOpening = null;
        let posStartFromEndMainCorner = null;
        if(this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide){
            posStartFromEndMainCorner = fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey].wallLength() - spacingMini - this.openingOnDrag.wallPortal.centerDistance - this.openingOnDrag.wallPortalFixed.centerDistance;
        }
        if(this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.portal){
            posStartFromEndMainCorner = fenceElement[this.keyMainCornerSectionOnDrag].mainWalls[this.openingOnDrag.belongToMainWallKey].wallLength() - spacingMini - this.openingOnDrag.openingSize - (this.openingOnDrag.gapStart + this.openingOnDrag.gapEnd);

        }
        if(previousOpening){
            if(previousOpening.typeOpening === Environment.drawConfiguration.opening.type.slide){
                posStartFromPreviousOpening = previousOpening.posStart + previousOpening.wallPortal.centerDistance + previousOpening.wallPortalFixed.centerDistance + spacingMini;
            }
            if(previousOpening.typeOpening === Environment.drawConfiguration.opening.type.portal){
                posStartFromPreviousOpening = previousOpening.posStart + previousOpening.openingSize + (previousOpening.gapStart + previousOpening.gapEnd) + spacingMini;
            }
        }
        if(nextOpening){
            if(this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.slide){
                posStartFromNextOpening = nextOpening.posStart - spacingMini - this.openingOnDrag.wallPortal.centerDistance - this.openingOnDrag.wallPortalFixed.centerDistance;
            }
            if(this.openingOnDrag.typeOpening === Environment.drawConfiguration.opening.type.portal){
                posStartFromNextOpening = nextOpening.posStart - spacingMini - this.openingOnDrag.openingSize - (this.openingOnDrag.gapStart + this.openingOnDrag.gapEnd);
            }
        }
        // Si une seule opening sur le segment
        if (!nextOpening && !previousOpening) {
            this.setPosStartOverUniqueOpening(startMainCorner, spacingMini, posStartFromEndMainCorner)
        }
        if (!nextOpening && previousOpening) {
            this.setPosStartOverLastOpening(startMainCorner, posStartFromPreviousOpening, posStartFromEndMainCorner)
        }

        if (nextOpening && previousOpening) {
            this.setPosStartOverMiddleOpening(startMainCorner, wallBase, posStartFromPreviousOpening, posStartFromNextOpening)

        }
        if (nextOpening && !previousOpening) {
            this.setPosStartOverFirstOpening(startMainCorner, wallBase, posStartFromNextOpening, spacingMini);
        }
    }

    /**
     * retourne dans le cas d'un positionnement hors limite, la position de l'unique ouverture, le plus proche possible de sa sortie
     * @param {Corner} startMainCorner
     * @param {Number} posStartMainCorner
     * @param {Number} posStartFromEndMainCorner
     */
    setPosStartOverUniqueOpening(startMainCorner, posStartMainCorner, posStartFromEndMainCorner) {
        if (this.fakeWallOpening.overStart.id === startMainCorner.id) {
            this.openingOnDrag.posStart = posStartMainCorner;
        }
        if (this.fakeWallOpening.overEnd.id === startMainCorner.id) {
            this.openingOnDrag.posStart = posStartMainCorner;
        }
        if (this.fakeWallOpening.overStart && this.fakeWallOpening.overStart.id !== startMainCorner.id) {
            this.openingOnDrag.posStart = posStartFromEndMainCorner;
        }
        if (this.fakeWallOpening.overEnd && this.fakeWallOpening.overEnd.id !== startMainCorner.id) {
            this.openingOnDrag.posStart = posStartFromEndMainCorner;
        }
    }
}
