export class QuickSettingsHelper {

    constructor(settingPanel) {
        this.settingPanel = settingPanel;
    }

    /**
     * Ajoute un titre dans le panneau de configuration
     */
    addSectionTitle(id, value) {
        let title = document.createElement("h2")
        title.innerHTML = value;
        title.className = "qs_title_section_custom";
        let containerTitle = this.settingPanel._createContainer();

        containerTitle.className = "qs_container_title_section_custom";
        containerTitle.appendChild(title);
        this.settingPanel._controls[id] = {
            container: containerTitle,
            label: null
        };
    }

    updateTextSectionTitle(id,html) {
        this.settingPanel._controls[id].container.innerHTML = html;
    }

    addInfoText(title, value) {
        let paragraph = document.createElement("p");

        let weightValue = document.createElement("span");
        weightValue.className = "qs_paragraph_value_custom";
        weightValue.innerHTML = value;
        weightValue.id = title + "-span"

        paragraph.appendChild(weightValue);

        let containerParagraph = this.settingPanel._createContainer();
        containerParagraph.id = title
        containerParagraph.appendChild(paragraph);
        containerParagraph.className = "qs_container_paragraph_custom"

        this.settingPanel._controls[title] = {
            container: containerParagraph,
            label: null
        };
    }

    /**
     * change le backGroundColor de l'élément
     * @param {string} id
     * @param {string} color (#FFFFFF)
     */
    changeBackgroundColorInfoText(id,color){
        document.getElementById(id).firstChild.style.backgroundColor = color;
    }

    updateInfoText(title, value){
        document.getElementById(title + "-span").innerHTML = value;
    }

    removeControlUcFirst(title) {
        this.settingPanel.removeControl(title.charAt(0).toUpperCase() + title.slice(1).toLowerCase());
    }
}
