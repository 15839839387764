export const cornerMouseUp = new CustomEvent("cornerMouseUp", {
        detail: {
            item: null
        }
    }
);

export const cornerMouseMove = new CustomEvent("cornerMouseMove", {
        detail: {
            item: null
        }
    }
);

export const cornerMouseDown = new CustomEvent("cornerMouseDown", {
        detail: {
            event: null,
            item: null
        }
    }
);

export const moveOpening = new CustomEvent("moveOpening", {
        detail: {
            item: null,
            event: null
        }
    }
);

export const dragEndOpening = new CustomEvent("dragEndOpening", {
        detail: {
            item: null,
            event: null
        }
    }
);

export const dragMoveOpening = new CustomEvent("dragMoveOpening", {
        detail: {
            item: null,
            event: null
        }
    }
);

export const dragStartOpening = new CustomEvent("dragStartOpening", {
        detail: {
            item: null,
            event: null
        }
    }
);

export const wallClicked = new CustomEvent("wallClicked", {
        detail: {
            item: null
        }
    }
);

export const drawModeAddCorner = new CustomEvent("drawModeAddCorner", {
        detail: {
            item: null,
            event: null
        }
    }
);

export const cornerHaveBeenIntersected = new CustomEvent("cornerHaveBeenIntersected", {
        detail: {
            fixedCorner: null,
            movedCorner: null
        }
    }
);

export const openingClicked = new CustomEvent("openingClicked", {
        detail: {
            item: null,
            event: null
        }
    }
);
