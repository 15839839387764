import {Environment} from "../../environment/Environment";

export class NetDataSet {

    constructor(products, accessoryConfigData) {
        this.products = products;
        this.accesoryConfigData = accessoryConfigData;
        // longueur total en fonction du type de filet
        this.netLengthTotal = {};
        // longueur total de cable pour le périmètre des filets peu importe son type
        this.netPerimeterTotal = 0;
        this.wireIntermediateLengthTotal = 0;
        this.brokenRingsUnitTotal = 0;
    }

    addWireIntermediateLength(wall, length) {
        if (!wall.isPortal || wall.netAbovePortal)
            this.wireIntermediateLengthTotal += length;
    }

    addNetSet(wall) {
        // TODO gestion des poteau de retention
        this.brokenRingsUnitTotal = 0;
        const codeNet = this.getCodeNet(wall);
        const height = this.accesoryConfigData.getHeightNet(wall);
        let length = this.getLength(wall);
        const quantityExtraStrut = this.accesoryConfigData.getQuantityExtraStrut(wall);
        const sparePartsNetInfo = Environment.sparePartsEnv.pareBallon.net;
        const quantityAdditionalWire = (Math.ceil(height / Environment.dataEcommerceEnv.pareBallon.recommendHeightIntermediateWire) - 1);
        // TODO gérer l ajout si non voulu c(ontraintes: même hauteur filet, cloture netfilling? wall.netAbovePortal)
        if (!wall.isPortal || (wall.isPortal && wall.netAbovePortal)) {
            this.setNetLengthTotal(codeNet, length);
        }
        // Ajout du filet
        this.accesoryConfigData.addNet(codeNet, height, Math.ceil(this.getNetLengthTotal(codeNet)));
        this.setPerimeterNetWireTotal(wall, length, height);
        // Mise à jour du cable pour le périmètre du filet arrondi au supérieur
        this.accesoryConfigData.setAccessory(sparePartsNetInfo.wire, Math.ceil(this.netPerimeterTotal));
        // Mise à jour du nombre d'anneaux brisés nécessaires
        this.addBrokenRings(Math.ceil(this.netPerimeterTotal));
        if (wall.netIntermediateWireChoice && quantityAdditionalWire > 0) {
            // Ajout de la longueur de cable intermédiaire (différent du cable filet)
            this.addWireIntermediateLength(wall, length * quantityAdditionalWire);
            // Mise à jour de la nouvelle quantité
            this.accesoryConfigData.setAccessory(Environment.sparePartsEnv.pareBallon.net.wireIntermediate, Math.ceil(this.wireIntermediateLengthTotal));
            // Mise à jour du nombre d'anneaux brisés nécessaires
            this.addBrokenRings(quantityAdditionalWire * Math.ceil(this.getNetLengthTotal(codeNet)));

        }
        // Mise à jour du nombre de boite d'anneaux brisés nécessaires
        this.accesoryConfigData.setAccessory(
            Environment.sparePartsEnv.pareBallon.net.brokenRing,
            this.getBrokenRingBoxQuantity()
        );
        if (!wall.isPortal || (wall.isPortal && !wall.sameNetIsPossible && wall.netAbovePortal)) {

            // Ajout des raidisseurs
            this.accesoryConfigData.addAccessory(
                sparePartsNetInfo.stiffener[wall.netStiffenerColor].code,
                sparePartsNetInfo.stiffener[wall.netStiffenerColor].quantity * (quantityExtraStrut + 1 )
            );
            // Ajout des brides barre de renfort, raidisseur et crochet filet
            this.accesoryConfigData.addAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.netFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.netFlange.quantity * (quantityExtraStrut + 1)
            );
            this.accesoryConfigData.addAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.strutFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.strutFlange.quantity * (quantityExtraStrut + 1)
            );
            this.accesoryConfigData.addAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.quantity * (quantityExtraStrut + 1)
            );
            // TODO choix boolean wall.netIntermediateWireChoice est true
            if (wall.netIntermediateWireChoice) {
                const quantityStiffener = quantityAdditionalWire * 2;
                if (quantityAdditionalWire > 0) {
                    // Ajout des raidisseurs supplémentaires
                    this.accesoryConfigData.addAccessory(sparePartsNetInfo.stiffener[wall.netStiffenerColor].code, quantityStiffener);
                    // Ajout des brides raidisseurs
                    this.accesoryConfigData.addAccessory(
                        Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.code,
                        Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.quantity * (quantityExtraStrut + 1) * quantityAdditionalWire
                    );
                }
            }
        }
        if (wall.isPortal && wall.netAbovePortal && wall.sameNetIsPossible) {
            // Suppression des raidisseurs
            this.accesoryConfigData.removeAccessory(
                sparePartsNetInfo.stiffener[wall.netStiffenerColor].code,
                sparePartsNetInfo.stiffener[wall.netStiffenerColor].quantity
            );
            // Suppression des brides barre de renfort, raidisseur et crochet filet
            this.accesoryConfigData.removeAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.netFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.netFlange.quantity
            );
            this.accesoryConfigData.removeAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.strutFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.strutFlange.quantity
            );
            this.accesoryConfigData.removeAccessory(
                Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.code,
                Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.quantity * (quantityAdditionalWire + 1)
            );
            // TODO ajout des guides cables
            // TODO choix boolean wall.netIntermediateWireChoice est true
            if (wall.netIntermediateWireChoice) {

                const quantityStiffener = quantityAdditionalWire * 2;
                if (quantityAdditionalWire > 0) {
                    // Ajout des raidisseurs supplémentaires
                    this.accesoryConfigData.removeAccessory(sparePartsNetInfo.stiffener[wall.netStiffenerColor].code, quantityStiffener);

                    // Suppression des brides raidisseurs
                    this.accesoryConfigData.removeAccessory(
                        Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.code,
                        Environment.sparePartsEnv.pareBallon.net.flange.stiffenerFlange.quantity * quantityAdditionalWire
                    );
                }
                // TODO ajout guides cables
            }
        }

    }

    // filet
    getCodeNet(wall) {
        return wall.netProductChoice.code;
    }

    setPerimeterNetWireTotal(wall, length, height) {
        if (wall.isPortal && wall.netAbovePortal && wall.sameNetIsPossible) {
            this.netPerimeterTotal += length * 2 - height * 2;
            return;
        }
        if (!wall.isPortal || (wall.isPortal && wall.netAbovePortal && !wall.sameNetIsPossible)) {
            this.netPerimeterTotal += height * 2 + length * 2;
        }
    }

    getNetLengthTotal(code) {
        return this.netLengthTotal[code]
    }

    getLength(wall) {
        let length = wall.wallLength() / 100;
        if (wall.isPortal && wall.gapStart !== null && wall.gapEnd !== null) {
            length += wall.gapStart / 100 + wall.gapEnd / 100
        }
        return length;
    }

    // anneaux brisés

    addBrokenRings(length) {
        const spacingBetweenBrokenRing = Environment.dataEcommerceEnv.pareBallon.netBrokenRingSpacing;
        this.brokenRingsUnitTotal += Math.ceil(length / spacingBetweenBrokenRing);
    }

    getBrokenRingBoxQuantity() {
        const boxQuantity = Environment.sparePartsEnv.defaultBoxQuantity.brokenRing[Environment.sparePartsEnv.pareBallon.net.brokenRing];
        return Math.ceil(this.brokenRingsUnitTotal / boxQuantity);
    }

    // setters
    setNetLengthTotal(code, length) {
        if (this.netLengthTotal.hasOwnProperty(code)) {
            this.netLengthTotal[code] += length;
        } else {
            this.netLengthTotal[code] = length;
        }
    }
}
