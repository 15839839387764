import {DrawManager} from "../manager/DrawManager";
import {QuickSettingsHelper} from "./QuickSettingsHelper";
import {Environment} from "../environment/Environment";

export class WallPanel {

    /**
     * @param quickSettings
     * @param {DrawManager} drawManager
     */
    constructor(quickSettings, drawManager) {
        this.app_parent = document.getElementById('bp3d-js-app');
        this.wallSelected = null;
        this.quickSettings = quickSettings;
        this.drawManager = drawManager;
        this.settingPanel = null;
        this.quickSettingsHelper = null;
        this.drawOpening = this.drawManager.getOpeningManager();
        this.__openPanelEvent = this.__openPanelPhase0.bind(this);
        this.wallBoundsStart = [];
        this.wallBoundsEnd = [];
        this.netsProductProperties = [];
        this.fencesProductProperties = [];
        this.panelsProductProperties = [];
    }

    /**
     * Création du panneau settings de départ
     */
    createStartControl() {
        const width = 350
        this.settingPanel = this.quickSettings.create(
            window.innerWidth - width - 50,
            window.innerWidth / 2 - width * 2,
            Environment.wallPanelEnv.title,
            this.app_parent
        );
        this.settingPanel.setWidth(width);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.addOpening, this.__drawNewOpening.bind(this));
        this.settingPanel.addBoolean(Environment.wallPanelEnv.defaultInputMessage.intermediateCornerReverse, this.wallSelected.reverse, this.reverseIntermediateCorner.bind(this));
        this.settingPanel.show();
    }

    /**
     * Ouverture de la fenêtre de configuration d'un segment
     * @param e
     */
    __openPanelPhase0(e) {
        this.drawManager.configuratorStarter.wallClicked = true;
        this.wallSelected = e.item;
        this.wallBoundsStart = [...this.wallSelected.start.wallStarts, ...this.wallSelected.start.wallEnds];
        this.wallBoundsEnd = [...this.wallSelected.end.wallStarts, ...this.wallSelected.end.wallEnds];
        // Si une fenêtre existe déjà on la détruit
        if (this.settingPanel) {
            this.__closePanel();
        }
        // vérifie si le segment est déjà sauvegardé
        if (!(this.wallSelected.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + this.wallSelected.end.id in this.drawManager.wallConfigManager.wallsConfigData)) {
            this.drawManager.wallConfigManager.addNewWallConfig(this.wallSelected)
        }
        // Création de base de la nouvelle fenêtre
        this.createStartControl();
        this.quickSettingsHelper = new QuickSettingsHelper(this.settingPanel);
        this.handleFenceTypeFormPhase0();
        this.settingPanel.removeControl(Environment.wallPanelEnv.button.closePanel);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.closePanel, this.__closePanel.bind(this));
    }

    /**
     * gestion de la partie formulaire type de clôture (création et remplissage)
     */
    handleFenceTypeFormPhase0() {
        this.quickSettingsHelper.addSectionTitle(
            Environment.wallPanelEnv.subtitleConfiguration.id,
            Environment.wallPanelEnv.subtitleConfiguration.text
        );
        this.quickSettingsHelper.addInfoText(
            Environment.wallPanelEnv.displayInfo.currentDistance.title,
            Environment.wallPanelEnv.displayInfo.currentDistance.text(this.wallSelected.centerDistance / 100)
        );
        // Récupère les valeurs autorisées pour l'input du segment cible
        let fenceTypeNamesAuthorized = this.drawManager.drawWall2D.getFenceTypeNamesAuthorizedCombinationForBothCorner(
            this.wallSelected,
            this.wallBoundsStart,
            this.wallBoundsEnd,
            [Environment.dataEcommerceEnv.tennis.taxonCode.main]
        );

        if (fenceTypeNamesAuthorized === null) {
            this.quickSettingsHelper.addInfoText(Environment.wallPanelEnv.defaultInfo.noFenceCombination.title, Environment.wallPanelEnv.defaultInfo.noFenceCombination.text);
        } else {
            // Ajout de l'input type de clôture
            this.settingPanel.addDropDown(Environment.wallPanelEnv.titleInput.fenceType,
                fenceTypeNamesAuthorized,
                this.__setWallConfigPhase1.bind(this, true)
            );
            // on passe les options selectionnées si elles existent
            if (this.wallSelected.fenceTypeName !== null) {
                let ec = {
                    value: this.wallSelected.fenceTypeName
                }
                this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fenceType].control.value = this.wallSelected.fenceTypeName;
                // Comme fenceType du segment n'est pas null, on active le champ type de remplissage
                this.__setWallConfigPhase1(false, ec);
            }
        }
    }

    /**
     * Gestion du select choix type clôture et ajout du select choix de remplissage
     * @param {boolean} isCallBack
     * @param {object} e
     */
    __setWallConfigPhase1(isCallBack, e) {


        if (isCallBack) {
            this.__removeWallConfigInput(this.wallSelected, 1)
        }
        this.wallSelected.fenceTypeName = e.value;
        this.drawManager.wallConfigManager.setConfigFenceTypeName(this.wallSelected);
        const fenceTypeInfo = this.drawManager.jsonDataManager.getFenceTypeInfoByName(e.value);
        if (fenceTypeInfo) {
            this.updateInfosConfigPhase1(fenceTypeInfo);
            this.handleFillingTypeFormPhase1(fenceTypeInfo);
        } else {
            this.drawManager.wallConfigManager.setConfigFenceTypeCode(this.wallSelected);
            this.drawManager.wallConfigManager.setFakeWallColor(this.wallSelected);

        }
        // Ajout du bouton glissière dans le cas d'une main courante, on le positionne avant le choix d'inversion des poteaux intermédiaires.
        if (this.wallSelected.fenceTypeCode === Environment.dataEcommerceEnv.handRail.taxonCode.main) {
            this.settingPanel.addButton(Environment.wallPanelEnv.button.addSlide, this.__drawNewOpening.bind(this));
            this.settingPanel._content.insertBefore(this.settingPanel._controls[Environment.wallPanelEnv.button.addSlide].container, this.settingPanel._controls[Environment.wallPanelEnv.defaultInputMessage.intermediateCornerReverse].container)
        }
        // Suppression du bouton glissière dans le cas d'une main courante
        if (this.wallSelected.fenceTypeCode !== Environment.dataEcommerceEnv.handRail.taxonCode.main && this.settingPanel._controls[Environment.wallPanelEnv.button.addSlide]) {
            this.settingPanel._controls[Environment.wallPanelEnv.button.addSlide].container.remove();
        }
        this.settingPanel.removeControl(Environment.wallPanelEnv.button.closePanel);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.closePanel, this.__closePanel.bind(this));
        this.drawManager.drawFence();
    }

    /**
     * Mise à jour des éléments après le choix du type de clôture
     * @param {object} fenceTypeInfo
     */
    updateInfosConfigPhase1(fenceTypeInfo) {
        this.settingPanel.hide()
        this.settingPanel.show()
        // si mains courantes on informe la hauteur
        if (fenceTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.main) {
            this.wallSelected.cornerHeightType = 1;
            this.drawManager.wallConfigManager.setConfigCornerHeightType(this.wallSelected);
        }
        // si clôture 2m on informe la hauteur
        if (fenceTypeInfo.code === Environment.dataEcommerceEnv.cloture2m.taxonCode.main) {
            this.wallSelected.cornerHeightType = 2;
            this.drawManager.wallConfigManager.setConfigCornerHeightType(this.wallSelected);
        }
        this.wallSelected.fenceTypeCode = fenceTypeInfo.code;
        this.drawManager.wallConfigManager.setConfigFenceTypeCode(this.wallSelected);
        this.drawManager.wallConfigManager.setFakeWallColor(this.wallSelected);
    }

    /**
     * Ajout du formulaire type de remplissage
     * @param {object} fenceTypeInfo
     */
    handleFillingTypeFormPhase1(fenceTypeInfo) {
        const fillingTypeNamesAuthorized = this.drawManager.drawWall2D.getFillingTypeNamesAuthorizedCombinationForBothCorner(
            fenceTypeInfo.fillingType,
            this.wallSelected,
            this.wallBoundsStart,
            this.wallBoundsEnd
        );
        if (fillingTypeNamesAuthorized === null) {
            this.quickSettingsHelper.addInfoText(Environment.wallPanelEnv.defaultInfo.noFillingCombination.title, Environment.wallPanelEnv.defaultInfo.noFillingCombination.text);
        } else {
            // Ajout de l'input type de remplissage
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.fillingType,
                fillingTypeNamesAuthorized,
                this.__setWallConfigPhase2.bind(this, true)
            );
            // on passe les options selectionnées si elles existent
            if (this.wallSelected.fillingTypeName !== null) {
                let ec = {
                    value: this.wallSelected.fillingTypeName
                }
                this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fillingType].control.value = this.wallSelected.fillingTypeName;
                this.__setWallConfigPhase2(false, ec);
            }
        }
    }

    /**
     * Gestion du select choix type remplissage et ajout du/des select choix du filet,panneau et/ou grillage torsadé
     */
    __setWallConfigPhase2(isCallBack, e) {
        if (isCallBack) {
            this.__removeWallConfigInput(this.wallSelected, 2)
        }
        this.wallSelected.fillingTypeName = e.value;

        this.drawManager.wallConfigManager.setConfigFillingTypeName(this.wallSelected);
        const fillingTypeInfo = this.drawManager.jsonDataManager.getFillingTypeInfoByName(this.wallSelected);

        if (fillingTypeInfo) {
            this.updateInfosConfigPhase2(fillingTypeInfo);
            this.handleFillingFormPhase2(fillingTypeInfo)
        } else {
            this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.wallSelected)
        }
        // ajout des informations des accèssoires par défaut
        // TODO ajouter la sélection par couleur
        if(
            this.wallSelected.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main ||
            this.wallSelected.fenceTypeCode === Environment.dataEcommerceEnv.cloture2m.taxonCode.main
        ){
            this.drawManager.wallConfigManager.setConfigPlug(this.wallSelected);
        }
        if(
            this.wallSelected.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main
        ){
            this.drawManager.wallConfigManager.setConfigNetStiffener(this.wallSelected);
        }
        if(
            this.wallSelected.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.fenceFilling
        ){
            this.drawManager.wallConfigManager.setConfigFenceStiffener(this.wallSelected);
        }


        this.settingPanel.removeControl(Environment.wallPanelEnv.button.closePanel);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.closePanel, this.__closePanel.bind(this));
        this.drawManager.drawFence();
    }

    /**
     * Mise à jour des éléments après le choix du type de remplissage
     * @param {object} fillingTypeInfo
     */
    updateInfosConfigPhase2(fillingTypeInfo) {
        // Mise à jour du champ entraxe dans le panneau dans le cas d'une main courantes avec remplissage
        if (
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.panelFilling ||
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling
        ) {
            this.wallSelected.railLength = Environment.drawConfiguration.opening.defaultDimension.handRailRailLength;
            this.drawManager.wallConfigManager.setConfigRailLength(this.wallSelected,this.wallSelected.railLength);
            this.wallSelected.centerDistance = Environment.drawConfiguration.opening.defaultDimension.handRailCenterDistance;
            this.quickSettingsHelper.updateInfoText(Environment.wallPanelEnv.displayInfo.currentDistance.title, Environment.wallPanelEnv.displayInfo.currentDistance.text(this.wallSelected.centerDistance / 100));
        }
        // Mise à jour des infos de remplissage du segment
        this.wallSelected.fillingTypeCode = fillingTypeInfo.code;
        this.drawManager.wallConfigManager.setConfigFillingTypeCode(this.wallSelected)
    }

    /**
     * Ajout du formulaire avec la liste des produits de remplissage ou le fromulaire hauteur poteau dans le cas d'un pare-ballons
     * @param {object} fillingTypeInfo
     */
    handleFillingFormPhase2(fillingTypeInfo) {

        // TODO input stiffener color + bouchon color (hidden input si un seule choix)


        // Ajout de l'input type du choix de la hauteur du pare ballons
        if (this.wallSelected.fenceTypeName === this.drawManager.jsonDataManager.getFenceTypeNameByTaxonCode(Environment.dataEcommerceEnv.pareBallon.taxonCode.main)) {
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.cornerHeightType,
                [Environment.wallPanelEnv.defaultInputMessage.CornerHeightType, ...Environment.dataEcommerceEnv.pareBallon.heightCornerAvailable],
                this.__setWallConfigPhase3.bind(this, Environment.wallPanelEnv.type.cornerHeight)
            );
        }
        if (
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.noFilling
        ) {
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.railLength,
                Environment.dataEcommerceEnv.handRail.railLengthAvailable,
                this.__setWallConfigPhase3.bind(this, Environment.portalPanelEnv.inputHandRailRailLength.type)
            );
        }
        if (
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.noFilling ||
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.fenceFilling ||
            fillingTypeInfo.code === Environment.dataEcommerceEnv.handRail.taxonCode.panelFilling
        ) {
            let railLength = this.wallSelected.railLength;
            if(!this.wallSelected.railLength){
                railLength = 5000;
            }
            this.quickSettingsHelper.addInfoText(
                Environment.wallPanelEnv.displayInfo.slideLength.title,
                Environment.wallPanelEnv.displayInfo.slideLength.text(railLength / 1000)
            );
            this.settingPanel._content.insertBefore(this.settingPanel._controls[Environment.wallPanelEnv.displayInfo.slideLength.title].container, this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fenceType].container)
        }
        // Ajout de l'input type de nets
        if (fillingTypeInfo.nets.length > 0) {
            this.netsProductProperties = this.drawManager.jsonDataManager.getProducts(fillingTypeInfo.nets)
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.netType,
                this.drawManager.jsonDataManager.getProductsNames(fillingTypeInfo.nets,Environment.wallPanelEnv.defaultInputMessage.NetType),
                this.__setWallConfigPhase3.bind(this, Environment.wallPanelEnv.type.net)
            );
        }
        // Ajout de l'input type de fences
        if (fillingTypeInfo.fences.length > 0) {
            this.fencesProductProperties = this.drawManager.jsonDataManager.getProducts(fillingTypeInfo.fences)
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.fenceProductType,
                this.drawManager.jsonDataManager.getProductsNames(fillingTypeInfo.fences,Environment.wallPanelEnv.defaultInputMessage.FenceProductType),
                this.__setWallConfigPhase3.bind(this, Environment.wallPanelEnv.type.fenceProduct)
            );
        }
        // Ajout de l'input type de panels
        if (fillingTypeInfo.panels.length > 0) {
            this.panelsProductProperties = this.drawManager.jsonDataManager.getProducts(fillingTypeInfo.panels)
            this.settingPanel.addDropDown(
                Environment.wallPanelEnv.titleInput.panelType,
                this.drawManager.jsonDataManager.getProductsNames(fillingTypeInfo.panels,Environment.wallPanelEnv.defaultInputMessage.PanelType),
                this.__setWallConfigPhase3.bind(this, Environment.wallPanelEnv.type.panel)
            );
        }
        // on passe les options selectionnées si elles existent
        if (this.settingPanel._controls[Environment.wallPanelEnv.titleInput.railLength] && this.wallSelected.railLength !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.railLength].control.value = this.wallSelected.railLength/1000;
        }
        if (this.wallSelected.cornerHeightType > 2) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.cornerHeightType].control.value = this.wallSelected.cornerHeightType;
        }
        if (this.wallSelected.netType !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.netType].control.value = this.wallSelected.netType;
        }
        if (this.wallSelected.fenceProductType !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.fenceProductType].control.value = this.wallSelected.fenceProductType;
        }
        if (this.wallSelected.panelType !== null) {
            this.settingPanel._controls[Environment.wallPanelEnv.titleInput.panelType].control.value = this.wallSelected.panelType;
        }
    }

    __setWallConfigPhase3(type, e) {
        if (type === Environment.wallPanelEnv.type.cornerHeight) {
            // Si on modifie la hauteur d'un poteau et qu'un portail est connexe on met la valeur netAbovePortal à false
            this.updateNetAbovePortal();
            this.wallSelected.cornerHeightType = e.value;
            this.drawManager.wallConfigManager.setConfigCornerHeightType(this.wallSelected);
            if (this.wallSelected.cornerHeightType > 2 && this.wallSelected.cornerHeightType !== Environment.wallPanelEnv.defaultInputMessage.CornerHeightType) {
                const technicalInfo = this.drawManager.jsonDataManager.getTechnicalInfoBallScreen(this.wallSelected);
                if (!technicalInfo) {
                    alert(Environment.drawConfiguration.main.errorMessage.technicalInfoNotFound);
                } else {
                    this.wallSelected.centerDistance = parseInt(technicalInfo.centerDistance) / Environment.scalingConfiguration.coeffShrinkElement;
                    this.quickSettingsHelper.updateInfoText(Environment.wallPanelEnv.displayInfo.currentDistance.title, Environment.wallPanelEnv.displayInfo.currentDistance.text(this.wallSelected.centerDistance / 100));
                }
            }
        }
        if (type === Environment.portalPanelEnv.inputHandRailRailLength.type) {
            let value = e.value * 1000;
            this.drawManager.wallConfigManager.setConfigRailLength(this.wallSelected,value);
            const technicalInfoHandRail = this.drawManager.jsonDataManager.getTechnicalInfoHandRail(this.wallSelected.fillingTypeCode, this.wallSelected.railLength);
            this.wallSelected.centerDistance = technicalInfoHandRail.centerDistance/10;
            this.quickSettingsHelper.updateInfoText(Environment.wallPanelEnv.displayInfo.slideLength.title, Environment.wallPanelEnv.displayInfo.slideLength.text(this.wallSelected.railLength / 1000));
            this.quickSettingsHelper.updateInfoText(Environment.wallPanelEnv.displayInfo.currentDistance.title, Environment.wallPanelEnv.displayInfo.currentDistance.text(this.wallSelected.centerDistance / 100));
        }
        if (type === Environment.wallPanelEnv.type.net) {
            this.wallSelected.netType = e.value;
            this.wallSelected.netProductChoice = this.netsProductProperties[e.value];
            this.drawManager.wallConfigManager.setConfigNetProductChoice(this.wallSelected);
            this.drawManager.wallConfigManager.setConfigNetType(this.wallSelected)
        }
        if (type === Environment.wallPanelEnv.type.panel) {
            this.wallSelected.panelType = e.value;
            this.wallSelected.panelProductChoice = this.panelsProductProperties[e.value];
            this.drawManager.wallConfigManager.setConfigPanelProductChoice(this.wallSelected);
            this.drawManager.wallConfigManager.setConfigPanelType(this.wallSelected)
        }
        if (type === Environment.wallPanelEnv.type.fenceProduct) {
            this.wallSelected.fenceProductType = e.value;
            this.wallSelected.fenceProductChoice = this.fencesProductProperties[e.value];
            this.drawManager.wallConfigManager.setConfigFenceProductChoice(this.wallSelected);
            this.drawManager.wallConfigManager.setConfigFenceProductType(this.wallSelected)
        }
        this.settingPanel.removeControl(Environment.wallPanelEnv.button.closePanel);
        this.settingPanel.addButton(Environment.wallPanelEnv.button.closePanel, this.__closePanel.bind(this));
        this.drawManager.drawFence();
    }

    __removeWallConfigInput(wall, phaseNumber) {
        if (phaseNumber === 1 || phaseNumber === 2) {
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.netType)
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.fenceProductType)
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.panelType)
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.cornerHeightType)
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.railLength)
            this.settingPanel.removeControl(Environment.wallPanelEnv.displayInfo.slideLength.title)
            wall.netType = null;
            wall.fenceProductType = null;
            wall.panelType = null;
            wall.cornerHeightType = null;
            wall.railLength = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].netType = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceProductType = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].panelType = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].cornerHeightType = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].railLength = null;
        }
        if (phaseNumber === 1) {
            this.settingPanel.removeControl(Environment.wallPanelEnv.titleInput.fillingType)
            wall.fillingTypeName = null;
            wall.fillingTypeCode = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeName = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fillingTypeCode = null;
            wall.fenceTypeCode = null;
            wall.fenceTypeName = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeCode = null;
            this.drawManager.wallConfigManager.wallsConfigData[wall.start.id + Environment.drawConfiguration.main.wallConfigDataSeparator + wall.end.id].fenceTypeName = null;
        }
    }

    updateNetAbovePortal(){
        let cornerTarget = null;
        if(this.wallSelected.start.isOutsideOpeningCornerStart || this.wallSelected.start.isOutsideOpeningCornerEnd){
            cornerTarget = this.wallSelected.start;
        }
        if(this.wallSelected.end.isOutsideOpeningCornerStart || this.wallSelected.end.isOutsideOpeningCornerEnd ){
            cornerTarget = this.wallSelected.end;
        }
        if(cornerTarget){
            const fenceElement = this.drawManager.fenceElementManager.getFenceElementByName(this.wallSelected.belongToFenceElement)
            fenceElement[this.wallSelected.belongToMainCornerSection].openings.forEach((opening) => {
                opening.corners.forEach((corner) => {
                    if(corner.id === cornerTarget.id){
                        opening.wallPortal.netAbovePortal = false;
                        opening.wallPortal.sameNetIsPossible = false;
                        this.drawManager.wallConfigManager.setNetAbovePortal(opening.wallPortal);
                        this.drawManager.wallConfigManager.setSameNetIsPossible(opening.wallPortal);
                    }
                })
            })
        }
    }

    reverseIntermediateCorner(e) {
        this.drawManager.wallConfigManager.toggleConfigIntermediateCornerReverse(this.wallSelected, e);
        this.drawManager.drawFence();
    }

    remove() {
        this.settingPanel.destroy();
    }

    randomColor() {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return "#" + randomColor;
    }


    __closePanel() {
        if (this.settingPanel !== null) {
            this.settingPanel._content.parentElement.remove();
            this.settingPanel = null;
        }
    }

    __drawNewOpening(e) {
        this.drawManager.__drawNewOpeningEvent(e);
        this.settingPanel.hide();
    }
}
