import { BaseFloorplanViewElement2D } from './BaseFloorplanViewElement2D.js';
import { Dimensioning } from '../core/dimensioning.js';
import { EVENT_MOVED, EVENT_DELETED } from '../core/events.js';
import { Point } from 'pixi.js';
import { Configuration, snapTolerance, snapToGrid, dragOnlyX, dragOnlyY } from '../core/configuration.js';
import {cornerMouseDown, cornerMouseMove, cornerMouseUp} from "../clomo/environment/Event";



// import {pointPolygon, pointBox} from 'intersects';


export class CornerView2D extends BaseFloorplanViewElement2D {
    constructor(floorplan, options, corner) {
        super(floorplan, options);
        this.__options['corner-radius'] = 12.5;
        this.__options['coefficient-corner-outer-radius'] = 1;
        this.__options['main-corner-fix-color-out'] = '0xCCCCCC';
        this.__options['main-corner-fix-color-in'] = '0x000000';
        this.__options['main-corner-alpha-out'] = 1;
        this.__options['main-corner-move-color-out'] = '0x04A9F5';
        this.__options['main-corner-move-color-in'] = '0x049995';
        this.__options['main-corner-hover-color-out'] = '0x04A9F5';
        this.__options['main-corner-hover-color-in'] = '0x049995';

        for (var opt in options) {
            if (this.__options.hasOwnProperty(opt) && options.hasOwnProperty(opt)) {
                this.__options[opt] = options[opt];
            }
        }

        this.__corner = corner;
        this.pivot.x = this.pivot.y = 0.5;

        this.__cornerUpdateEvent = this.__updateWithModel.bind(this);
        this.__cornerDeletedEvent = this.__cornerDeleted.bind(this);
        this.interactive = corner.isLocked;
        this.buttonMode = corner.isLocked;

        if (corner.isLocked) {
            this.__deactivate();
        }

        this.__drawHoveredOffState();

        this.__corner.addEventListener(EVENT_MOVED, this.__cornerUpdateEvent);
        this.__corner.addEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        this.__updateWithModel();
        this.corner.setBounds(this.getBounds());
    }

    __drawCornerState() {
        this.clear();
        let alpha = this.__options['main-corner-alpha-out']

        let xOut = 0;
        let yOut = 0;
        if (this.__corner.cornerWidth) {
            this.beginFill(this.__corner.fillColor, alpha);
            this.drawRect(
                xOut - Dimensioning.cmToPixel(this.__corner.cornerDiameterOrLength) / 2,
                yOut - Dimensioning.cmToPixel(this.__corner.cornerWidth) / 2,
                Dimensioning.cmToPixel(this.__corner.cornerDiameterOrLength),
                Dimensioning.cmToPixel(this.__corner.cornerWidth)
            );
            this.endFill();
        } else {
            // this.beginFill(this.__corner.borderColor, alpha);
            // this.drawCircle(xOut, yOut, useRadius);
            // this.endFill();
            this.beginFill(this.__corner.fillColor, alpha);
            this.drawCircle(xOut, yOut, Dimensioning.cmToPixel(this.__corner.cornerDiameterOrLength) / 2);
            this.endFill();
        }
        this.rotation = this.__corner.wallAngle;
    }

    __drawSelectedState() {
        super.__drawSelectedState();
        this.__drawCornerState();
    }
    __drawHoveredOnState() {
        this.cursor = "grab"
        super.__drawHoveredOnState();
        this.__drawCornerState();
    }
    __drawHoveredOffState() {
        super.__drawHoveredOffState();
        this.__drawCornerState();
    }

    __updateWithModel() {
        let xx = Dimensioning.cmToPixel(this.__corner.location.x);
        let yy = Dimensioning.cmToPixel(this.__corner.location.y);
        this.position.x = xx;
        this.position.y = yy;
    }

    __click(evt) {
        this.selected = true; //!this.selected;
        this.__isDragging = true;
        this.__dragStart(evt);

        this.__drawSelectedState();
        if (evt !== undefined) {
            evt.stopPropagation();
        }
    }
    __dragStart(evt) {
        super.__dragStart(evt);
        cornerMouseDown.item = this
        cornerMouseDown.event = evt;
        document.dispatchEvent(cornerMouseDown)
    }

    __dragMove(evt) {
        super.__dragMove(evt);
        cornerMouseMove.item = this
        document.dispatchEvent(cornerMouseMove)
        if (this.__isDragging) {
            let co = evt.data.getLocalPosition(this.parent);
            let cmCo = new Point(co.x, co.y);

            cmCo.x = Dimensioning.pixelToCm(cmCo.x);
            cmCo.y = Dimensioning.pixelToCm(cmCo.y);

            if (Configuration.getBooleanValue(snapToGrid) || this.__snapToGrid) {
                cmCo.x = Math.floor(cmCo.x / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
                cmCo.y = Math.floor(cmCo.y / Configuration.getNumericValue(snapTolerance)) * Configuration.getNumericValue(snapTolerance);
            }

            if (Configuration.getBooleanValue(dragOnlyX) && !Configuration.getBooleanValue(dragOnlyY)) {
                cmCo.y = this.__corner.location.y;
            }

            if (!Configuration.getBooleanValue(dragOnlyX) && Configuration.getBooleanValue(dragOnlyY)) {
                cmCo.x = this.__corner.location.x;
            }

            if(this.__floorplan.boundary){
                if(this.__floorplan.boundary.containsPoint(cmCo.x, cmCo.y)){
                    return;
                }
                if(this.__floorplan.boundary.intersectsExternalDesign(cmCo.x, cmCo.y)){
                    return;
                }
            }
            this.__corner.move(cmCo.x, cmCo.y);
        }
    }

    __dragEnd(evt) {
        super.__dragEnd(evt);
        cornerMouseUp.item = this
        document.dispatchEvent(cornerMouseUp)
        this.__floorplan.update();
    }

    __cornerDeleted() {
        this.remove();
        this.__corner = null;
    }

    __removeFromFloorplan() {
        this.__corner.remove();
    }

    remove() {
        this.__corner.removeEventListener(EVENT_DELETED, this.__cornerDeletedEvent);
        this.__corner.removeEventListener(EVENT_MOVED, this.__cornerUpdateEvent);
        super.remove();
    }

    get corner() {
        return this.__corner;
    }
}
