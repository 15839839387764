export class Environment {
    /** GlobalConfiguration */
    static globalConfigurationEnv = {
        clomoDomain: "https://clomo.3dh.ovh/",
        uriGetJsonData: "configurateur/fence-data",
        pictureAcceptList: "image/png,image/jpg,image/jpeg,image/webp",
        mainCornerSectionName: "mainCornerSection",
        mainCornerSectionJsonInit: {
            "name": null,
            "numberOfCorner": 0,
            "cornerRadius": 25,
            "wallThickness": 2,
            "mainCornerSection0": {
                "id": 0,
                "corner": {},
                "cornerLinkedTo": [],
                "mainWalls": [],
                "simpleCornersMainWall": [],
                "openingsWalls": [],
                "openings": [],
                "slides": [],
            }
        },
        openingDataInit: {
            'id': null,
            "typeOpening": null,
            'posStart': null,
            'corners': [],
            'belongToFenceElement': null,
            'belongToMainWallKey': null,
            'belongToMainCornerSection': null,
            'wallBase': null,
            'wallPortal': null,
            'wallPortalFixed': null,
            'wallStart': null,
            'wallEnd': null,
            'portal': null,
            'portalType': null,
            'positionOnMainWall': null,
            'openingSize': null,
            'gapStart': null,
            'gapEnd': null,
            'gateLeavesNumber': null,
            'centerSealingWidth': null,
            'centerSealingLength': null,
            'centerSealingHeight': null,
            'reverse': false,
            'pivotLeft': null,
            'technicalInfoPortal': null,
            'startMainWall': null,
            'endMainWall': null,
        },
        wallDataInit: {
            "fenceTypeName": null,
            "fenceTypeCode": null,
            "fillingTypeName": null,
            "fillingTypeCode": null,
            "cornerHeightType": null,
            "railLength": null,
            "slideLength": null,
            "netProductChoice": null,
            "fenceProductChoice": null,
            "panelProductChoice": null,
            "netType": null,
            "panelType": null,
            "fenceProductType": null,
            "intermediateCornerReverse": false,
            "fakeWallColor": 0xCCCCCC,
            "netStiffenerColor": null,
            "plugColor": null,
            "netAbovePortal": false,
            "sameNetIsPossible": false
        }
    }
    /** ScaleConfiguration */
    static scalingConfiguration = {
        picture: {
            anchor: 0.5,
            resolution: 5,
            offset: 50,
            recentImageLocaleStorageKey: "recent-image"
        },
        coeffShrinkElement: 5,
        defaultScale: 1
    }
    /** drawConfiguration */
    static drawConfiguration = {
        main: {
            wallConfigDataSeparator: '@',
            cotation: {
                step1: 40,
                step2: 100,
                step3: 150,
                step4: 60,
                step5: 130
            },
            spacingMiniBetween2Corner: 100,
            confirmMessage: {
                initProjectMessage: "Êtes vous sûr de vouloir réinitialiser le projet, toutes les données seront perdues?"
            },
            errorMessage: {
                technicalInfoNotFound: "Désolé, nous rencontrons un problème avec la hauteur de poteau selectionnée.\nSi le problème persiste, veuillez contacter le support!\nCode d'erreur: 'technicalInfoNotFound'",
                wallTooShort: "Le segment est trop petit pour ajouter une ouverture!",
                fenceCombination: "Une erreur de compatibilités de clôtures s'est glissée dans le dessin. Corriger cette erreur ou demander de l'aide au support.",
                fillingCombination: "Une erreur de compatibilités de remplissage s'est glissée dans le dessin. Corriger cette erreur ou demander de l'aide au support."
            },
            cornerType: {
                specialSlide: {
                    name: "specialSlide",
                    fillColor: 0xFF00FF
                },
                angle: {
                    name: "angle",
                    color: {
                        ballScreen: {
                            fillColor: 0x000000
                        },
                        cloture2m: {
                            fillColor: 0x000000
                        },
                        handrail: {
                            fillColor: 0x000000
                        }
                    }
                },
                inter: {
                    name: "inter",
                    color: {
                        ballScreen: {
                            fillColor: 0x00AA00
                        },
                        cloture2m: {
                            fillColor: 0x006600
                        },
                        handrail: {
                            fillColor: 0xAAAA00
                        }
                    }
                },
                start: {
                    name: "start",
                    color: {
                        ballScreen: {
                            fillColor: 0xDFAF2C
                        },
                        cloture2m: {
                            fillColor: 0xDFAF2C
                        },
                        handrail: {
                            fillColor: 0xDFAF2C
                        }
                    }
                },
            }
        },
        wall: {
            fakeWall: {
                thicknessWall: 2,
                thickness: 15,
                opacity: 1,
                defaultColor: 0x333333,
                anglePortalOpening: Math.PI / 12
            },
            grugeBox: {
                color: {
                    hover: 0x0000FF,
                    off: 0xFFFFFF
                },
                opacity: {
                    hover: 5,
                    off: 0.1
                }
            },
        },
        opening: {
            type: {
                slide: "SLIDE",
                portal: "PORTAL"
            },
            color: {
                outsideCorner: {
                    default: {
                        fillColor: 0x0000FF,
                        borderColor: 0xFFFFFF
                    },
                },
                insideCorner: {
                    default: {
                        fillColor: 0xFF0000,
                        borderColor: 0xFFFFFF,
                    },
                    handRail: {
                        portal: {
                            fillColor: 0xDFAF2C,
                            borderColor: 0xFFFFFF
                        },
                        wicket: {
                            fillColor: 0xDFAF2C,
                            borderColor: 0xFFFFFF
                        }
                    },
                    cloture2m: {
                        portal: {
                            fillColor: 0x7F00FF,
                            borderColor: 0xFFFFFF
                        },
                        wicket: {
                            fillColor: 0x7F00FF,
                            borderColor: 0xFFFFFF
                        }
                    }
                }
            },
            grugeBox: {
                color: {
                    onMove: 0x00FF00,
                    isOut: 0xFF0000,
                    hover: 0x00FF00,
                    off: 0xFFFFFF
                },
                opacity: {
                    onMove: 0.5,
                    isOut: 3,
                    hover: 30,
                    off: 0.01
                }
            },
            defaultDimension: {
                gap: 3,
                gapStart: 15,
                gapEnd: 15,
                cornerRadius: 24,
                openingSize: 150,
                slideSize: 500,
                centerDistance: 250,
                handRailCenterDistance: 250,
                handRailRailLength: 5000,
            }
        },
        mainCorner: {
            color: {
                fillColor: 0x0000FF,
                borderColor: 0xFFFFFF
            },
            defaultDimension: {
                diameterOrLength: 60,
                width: null
            }
        },
        intermediateCorner: {
            color: {
                fillColor: 0x00FF00,
                borderColor: 0xFFFFFF
            },
            defaultDimension: {
                diameterOrLength: 60,
                width: null
            }
        }
    }
    /** ScalePanel*/
    static scalePanelEnv = {
        title: "CONFIGURATION DE L'ÉCHELLE",
        defaultInputValue: {
            distanceValue: 0,
            stepValue: 1,
            maxValue: 0,
            minValue: 200000,
            panelX: 300,
            panelY: 300
        },
        titleInput: {
            distance: "Distance en mètres"
        },
        button: {
            cancel: "Annuler",
            confirm: "Valider"
        }
    }
    /** DrawPanel */
    static drawPanelEnv = {
        title: "CONFIGURATEUR",
        subtitleDrawing: {
            id: "subtitleDrawPanelDrawSection",
            text: "<h4>Dessiner:</h4>"
        },
        subtitleScaling: {
            id: "subtitleDrawPanelScaleInfo",
            text(value) {
                return "<h4>Échelle: " + value + " cm/pixel</h4>";
            }
        },
        titleInput: {
            addPicture: "Ajouter une image",
            elementList: "elementList"
        },
        defaultInputMessage: {
            addPicture: "Choisissez une image",
            hideWallBasesDim: "Cotes segments principaux (total)",
            hideSealingDim: "Cotes scellements",
            hideFakeWallDim: "Cotes segments principaux (détail)",
            hideIntermediateDim: "Cotes poteaux intermédiaires",
            hidePortalDim: "Cotes portails"
        },
        defaultInputValue: {
            elementName(value) {
                return "ÉlémentNuméro" + value;
            }
        },
        button: {
            deleteProject: "Supprimer le projet",
            basketProject: "Ajouter au panier",
            exportProject: "Exporter le projet",
            addPicture: 'Choisissez une image',
            deletePicture: "Retirer l'image",
            updateScale: {
                id: "scaling-button",
                text: "Modifier l'échelle"
            },
            deleteOpening: "Supprimer l'ouverture",
            addCorner: 'Ajouter un poteau',
            updateOriginCorner: 'Modifier l origine',
            newElement: "Créer un nouvel élément"
        },
    };
    /** WallPanel */
    static wallPanelEnv = {
        title: "CONFIGURATION GRILLAGE",
        type: {
            net: "net",
            cornerHeight: "cornerHeight",
            fenceProduct: "fence",
            panel: "panel"
        },
        subtitleConfiguration: {
            id: "subtitlePanelWallConfigurationId",
            text: "<h4>Configuration</h4>"
        },
        titleInput: {
            fenceType: "Type de clôtures",
            cornerHeightType: "Hauteur des poteaux pare-ballons",
            fillingType: "Type de remplissage",
            netType: "Type de filet",
            fenceProductType: "Type de grillage",
            panelType: "Type de panneau",
            railLength: "Longueur de lisse",
            railLengthUnavailable: "Longueur de lisse indisponible"
        },
        defaultInputMessage: {
            FenceType: "Choisissez un type de clôture",
            CornerHeightType: "Choisissez une hauteur de poteau pare-ballons",
            FillingType: "Choisissez un type de remplissage",
            NetType: "Choisissez un filet",
            FenceProductType: "Choisissez un type de grillages",
            PanelType: "Choisissez un type de panneau",
            RailLengthAvailable: "Choisissez une longueur de lisse",
            RailLengthUnavailable: "Longueur de lisse non disponible (segment trop court)",
            intermediateCornerReverse: "Inverser le sens d'implentation des poteaux intermédiaires"
        },
        defaultInfo: {
            noFenceCombination: {
                title: "WallNoCombinationFence",
                text: "Il n'est pas possible de choisir une clôture pour ce segment car les types de clôture qui s'y appliquent ne sont pas compatibles entre eux."
            },
            noFillingCombination: {
                title: "WallNoCombinationFilling",
                text: "Il n'est pas possible de choisir un remplissage pour ce segment car les types de remplissage qui s'y appliquent ne sont pas compatibles entre eux."
            }
        },
        button: {
            closePanel: "Fermer le panneau",
            addOpening: "Ajouter un portail/portillon",
            addSlide: "Ajouter une glissière"
        },
        displayInfo: {
            currentDistance: {
                title: "currentCenterDistanceInfoText",
                text(value) {
                    return "Entraxe actuel : " + value + " mètre(s)"
                }
            },
            slideLength: {
                title: "slideLengthInfoText",
                text(value) {
                    return "Longueur de lisse : " + value + " mètre(s)"
                }
            }
        }
    };

    /** PortalPanel */
    static portalPanelEnv = {
        title: {
            slide: "CONFIGURATION GLISSIÈRE",
            portal: "CONFIGURATION PORTAIL/PORTILLON",
        },
        subtitleHandrailSlide: {
            id: "subtitlePortalPanelHandRailSLideId",
            text: "<h4>Glissières mains courantes</h4>"
        },
        subtitleHandRail: {
            id: "subtitlePortalPanelHandRailId",
            text: "<h4>Ouvertures mains courantes</h4>"
        },
        subtitleFence2m: {
            id: "subtitlePortalPanelCloture2mId",
            text: "<h4>Ouvertures clôture 2 mètres</h4>"
        },
        defaultInputMessage: {
            portals: "Choisissez un portail",
            portalsNoAdaptive: "liste des portails non compatibles",
            wickets: "Choisissez un portillon",
            wicketsNoAdaptive: "liste des portillons non compatibles",
            slideLength: "Choisissez une taille d'ouverture",
            slideLengthUnavailable: "Longueur d'ouverture non disponible (segment trop court)"
        },
        defaultInfoText: {
            color: {
                noAdaptive: "#990000"
            }
        },
        button: {
            closePanel: "Fermer le panneau"
        },
        portalCodeStartsWith: {
            portal: "portail",
            wicket: "portillon",
            slide: "passage"
        },
        inputHandrailSlide: {
            type: "slideHandRail",
            titleInput: "titleInputHandrailSLide",
            titleInfoText: "titleInfoTextHandrailSlide",
        },
        inputHandrailSlideUnavailable: {
            type: "slideHandRailUnavailable",
            titleInput: "titleInputHandrailSLideUnavailable",
            titleInfoText: "titleInfoTextHandrailSlideUnavailable",
        },
        inputHandrailPortal: {
            type: "portalHandRail",
            titleInputAdaptive: "titleInputHandrailPortal",
            titleInputNoAdaptive: "titleInputHandrailPortalNoAdaptive",
            titleInfoText: "titleInfoTextHandrailPortal",
            titleNoAdaptiveInfoText: "titleNoAdaptiveInfoTextHandrailPortal",
            noAdaptivePortalMessage: "PORTAIL: non compatible, segment trop court",
            adaptivePortalMessage: "PORTAIL: compatible(s)",

        },
        inputHandrailWicket: {
            type: "wicketHandRail",
            titleInputAdaptive: "titleInputHandrailWicket",
            titleInputNoAdaptive: "titleInputHandrailWicketNoAdaptive",
            titleInfoText: "titleInfoTextHandrailWicket",
            titleNoAdaptiveInfoText: "titleNoAdaptiveInfoTextHandrailWicket",
            noAdaptivePortalMessage: "PORTILLON: non compatible, segment trop court",
            adaptivePortalMessage: "PORTILLON: compatible(s)"

        },
        inputHandRailRailLength: {
            type: "rail",
            titleInputAdaptive: "titleInputHandrailRailLength",
            titleInputNoAdaptive: "titleInputHandrailRailLengthNoAdaptive",
            titleInfoText: "titleInfoTextHandRailRailLength",
            titleNoAdaptiveInfoText: "titleNoAdaptiveInfoTextHandRailRailLength",
            noAdaptivePortalMessage: "",
            adaptivePortalMessage: "",
        },
        inputFence2mPortal: {
            type: "portalCloture2m",
            titleInputAdaptive: "titleInputFence2mPortal",
            titleInputNoAdaptive: "titleInputFence2mPortalNoAdaptive",
            titleInfoText: "titleInfoTextFence2mPortal",
            titleNoAdaptiveInfoText: "titleNoAdaptiveInfoTextFence2mPortal",
            noAdaptivePortalMessage: "PORTAIL: non compatible, segment trop court",
            adaptivePortalMessage: "PORTAIL: compatible(s)",

        },
        inputFence2mWicket: {
            type: "wicketCloture2m",
            titleInputAdaptive: "titleInputFence2mWicket",
            titleInputNoAdaptive: "titleInputFence2mWicketNoAdaptive",
            titleInfoText: "titleInfoTextFence2mWicket",
            titleNoAdaptiveInfoText: "titleNoAdaptiveInfoTextFence2mWicket",
            noAdaptivePortalMessage: "PORTILLON: non compatible, segment trop court",
            adaptivePortalMessage: "PORTILLON: compatible(s)",
        },
        inputReverseOpening: {
            titleInfoText: "Inverser le sens d'ouverture"
        },
        inputReversePivotOpening: {
            titleInfoText: "Inverser le pivot de l'ouverture"
        }
    }

    /** spare parts */
    static sparePartsEnv = {
        defaultBoxQuantity: {
            brokenRing: {
                'anneaux_brises': 100
            },
            // stapleOmega: {1000},
            // stapleGalfan: {1000}
        },
        pareBallon: {
            net: {
                brokenRing: 'anneaux_brises',
                wireIntermediate: 'cble-gaine-inox-noir',
                wire: 'Cable_gaine_pour_filet_4_5_Galvanise_noir',
                stiffener: {
                    galvanized: {code: '90R4_G_raidisseur_N4_Galvanise', quantity: 6},
                    green: {code: '90R4_GPV_Raidisseur_N_4_Thermolaque_vert_RAL_6005', quantity: 6},
                    white: {code: '90R4_GPB_Raidisseur_N_4_Thermolaque_blanc_RAL_9010', quantity: 6}
                },
                flange:{
                    netFlange: {code: 'Bride_poulie_crochet_pour_poteau_60_mm_Zamac', quantity: 2},
                    stiffenerFlange: {code: 'Bride_crochet_pour_poteau_60_mm_Zamac', quantity: 2},
                    strutFlange: {code: 'Bride_pour_jambe_de_force_60_mm_Zamac', quantity: 2}
                }
            },
        },
        cloture2m: {
            stiffener: {
                defaultQuantity: 10,
                "27": {
                    green: {code: '90R3_GPV_Raidisseur_N3_Thermolaque_vert_RAL_6005'},
                    grey: {code: '90R3_GPG_Raidisseur_N3_Thermolaque_gris_RAL_7016'}
                },
                "31": {
                    green: {code: '90R3_GPV_Raidisseur_N3_Thermolaque_vert_RAL_6005'},
                    grey: {code: '90R3_GPG_Raidisseur_N3_Thermolaque_gris_RAL_7016'}
                },
                "38": {
                    green: {code: '90R4_GPV_Raidisseur_N_4_Thermolaque_vert_RAL_6005'},
                    grey: {code: '90R4_GPG_Raidisseur_N_4_Thermolaque_gris_RAL_7016'}
                }
            },
            tensionCable: {
                "27": {code: 'Fils_de_tension_diam_32_mm_plastifie_vert', linearMeter: 100},
                "31": {code: 'Fils_de_tension_diam_32_mm_plastifie_vert', linearMeter: 100},
                "38": {code: 'Fils_de_tension_diam_38_mm_Plastifie_Vert', linearMeter: 100}
            },
            tensionBar: {
                "27": {code: 'longueur_barre_2_m'},
                "31": {code: 'longueur_barre_2_m'},
                "38": {code: 'longueur_barre_2_m'},
            }
        },
        rollerLength: {
            'Grillage_simple_torsion_1_m_diam_3_8_mm_long_25_m_Blanc_RAL_9010': 25,
            'Grillage_simple_torsion_1_m_diam_4_4_mm_Long_25_m_Blanc_RAL_9010': 25,
            'Grillage_simple_torsion_1_m_diam_3_9_mm_long_25_m_galvanise': 25,
            'Grillage_simple_torsion_2_4_mm_Ht_1_m_Long_12_m_Galvanise': 12,
            'Grillage_simple_torsion_2_4_mm_Ht_3_m_Long_18_m_Galvanise': 18,
            'Grillage_simple_torsion_2_4_mm_Ht_3_50_m_Long_18_m_Galvanise': 18,
            'Grillage_simple_torsion_2_4_mm_Ht_4_m_Long_18_m_Galvanise': 18,
            'Grillage_simple_torsion_3_2_mm_Ht_1_m_Long_12_m_Vert_RAL_6005': 12,
            'Grillage_simple_torsion_3_2_mm_Ht_3_m_Long_18_m_Vert_RAL_6005': 18,
            'Grillage_simple_torsion_3_2_mm_Ht_3_m_50_Long_18_m_Vert_RAL_6005': 18,
            'Grillage_simple_torsion_3_2_mm_Ht_4_m_Long_18_m_Vert_RAL_6005': 18,
            'Grillage_simple_torsion_2_7_mm_Ht_3_m_Long_25_m_Vert_RAL_6005': 25,
            'Grillage_simple_torsion_3_1_mm_Ht_2_m_Long_25_m_Vert_RAL_6005': 25,
            'Grillage_simple_torsion_3_8_mm_Ht_2_m_Long_25_m_Vert_RAL_6005': 25
        },
        fenceThreadDiameter: {
            'Grillage_simple_torsion_2_7_mm_Ht_3_m_Long_25_m_Vert_RAL_6005': 27,
            'Grillage_simple_torsion_3_1_mm_Ht_2_m_Long_25_m_Vert_RAL_6005': 31,
            'Grillage_simple_torsion_3_8_mm_Ht_2_m_Long_25_m_Vert_RAL_6005': 38
        },

    }

    /** data ecommerce */
    static dataEcommerceEnv = {
        // TODO ajout des poteaux
        // TODO ajout des jambe de force "start" et "angle"
        sets: {
            defaultColor: {
                plug: 'black',
                netStiffener: 'green',
                fenceStiffener: 'green',
            },
            // ballscreen
            "1": {
                "ballscreen_base": {
                    tube60: {
                        start: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B60_N_bouchon_diam60_mm', quantity: 2}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_60_mm_Zamac', quantity: 1},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_60_mm_Zamac', quantity: 1},
                            strutFlange: {code: 'Bride_pour_jambe_de_force_60_mm_Zamac', quantity: 1}
                        },
                        inter: {
                            // corner: {},
                            plug: {
                                black: {code: '90B60_N_bouchon_diam60_mm', quantity: 1}
                            },
                            cableGuide: {
                                code: 'Guide_cable_CLOMO_PRO_Zamac_Pour_60_mm_Vis_metrique',
                                quantity: 2
                            }
                        },
                        angle: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B60_N_bouchon_diam60_mm', quantity: 3}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_60_mm_Zamac', quantity: 2},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_60_mm_Zamac', quantity: 2},
                            strutFlange: {code: 'Bride_pour_jambe_de_force_60_mm_Zamac', quantity: 2}
                        }
                    },
                    tube90: {
                        start: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B90_N_bouchon_diam90_mm', quantity: 2}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_90_mm_Zamac', quantity: 1},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_90_mm_Zamac', quantity: 1},
                            strutFlange: {code: 'bride-pour-jambedefoce-90-mm-zamac', quantity: 1}
                        },
                        inter: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B90_N_bouchon_diam90_mm', quantity: 1}
                            },
                            cableGuide: {
                                code: 'Guide_cable_CLOMO_PRO_Zamac_Pour_90_mm_Vis_metrique',
                                quantity: 2
                            }
                        },
                        angle: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B90_N_bouchon_diam90_mm', quantity: 1}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_90_mm_Zamac', quantity: 2},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_90_mm_Zamac', quantity: 2},
                            strutFlange: {code: 'bride-pour-jambedefoce-90-mm-zamac', quantity: 2}
                        }
                    },
                    rectangle: {
                        start: {
                            // corner: {},
                            // tensionCorner: {},
                            // bracing: {ajout de deux brides, 2 bouchons, et morceau de poteau},
                            plug: {
                                black: {code: '90B16_N_bouchon_120x60_mm', quantity: 2}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_120x60_mm_Inox', quantity: 1},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_120x60_mm_Inox', quantity: 1},
                            strutFlange: {code: 'Bride_crochet_pour_jamde_de_force_120x60_mm_Inox', quantity: 1}
                        },
                        inter: {
                            // corner: {},
                            // tensionCorner: {},
                            plug: {
                                black: {code: '90B16_N_bouchon_120x60_mm', quantity: 1}
                            },
                            cableGuide: {
                                code: 'Guide_cable_CLOMO_PRO_Zamac_Pour_poteau_rectangle_Vis_metrique',
                                quantity: 2
                            }
                        },
                        angle: {
                            // corner: {},
                            // tensionCorner: {},
                            // bracing: {ajout de deux brides, 2 bouchons, et morceau de poteau},
                            plug: {
                                black: {code: '90B16_N_bouchon_120x60_mm', quantity: 1}
                            },
                            netFlange: {code: 'Bride_poulie_crochet_pour_poteau_120x60_mm_Inox', quantity: 2},
                            stiffenerFlange: {code: 'Bride_crochet_pour_poteau_120x60_mm_Inox', quantity: 2},
                            strutFlange: {code: 'Bride_crochet_pour_jamde_de_force_120x60_mm_Inox', quantity: 2}
                        }
                    }
                },
                // supplément remplissage grillage
                "13": {
                    start: {
                        fixing: {code: 'Equerre_orientable_simple_Inox_Vis_metrique', quantity: 5},
                    },
                    inter: {
                        cableGuide: {
                            code: 'Guide_cable_CLOMO_PRO_Zamac_Pour_poteau_rectangle_Vis_metrique',
                            quantity: 5
                        }
                    },
                    angle: {
                        fixing: {code: 'Equerre_orientable_double_Inox_Vis_metrique', quantity: 5},
                    }
                },
                // supplément remplissage panneau
                "14": {
                    tube60: {
                        start: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_60_mm', quantity: 5}
                        },
                        inter: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_60_mm', quantity: 5}
                        },
                        angle: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_60_mm', quantity: 5}
                        }
                    },
                    tube90: {
                        start: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_90_mm', quantity: 5}
                        },
                        inter: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_90_mm', quantity: 5}
                        },
                        angle: {
                            fixing: {code: 'Fixation_CLOMO_FIX_Noir_Pour_poteau_90_mm', quantity: 5}
                        }
                    },
                    rectangle: {
                        start: {
                            fixing: {code: 'Fixation-_CLOMO_FIX_Noir_Pour_poteau_rectangle', quantity: 5}
                        },
                        inter: {
                            fixing: {code: 'Fixation-_CLOMO_FIX_Noir_Pour_poteau_rectangle', quantity: 5}
                        },
                        angle: {
                            fixing: {code: 'Fixation-_CLOMO_FIX_Noir_Pour_poteau_rectangle', quantity: 5}
                        }
                    }
                }
            },
            // handrail
            "2": {
                "21": {
                    start: [{code: '', quantity: 1}],
                    inter: [{code: '', quantity: 1}],
                    angle: [{code: '', quantity: 1}],
                    specialSlide: [{code: '', quantity: 1}]
                },
                "22": {
                    start: [{code: '', quantity: 1}],
                    inter: [{code: '', quantity: 1}],
                    angle: [{code: '', quantity: 1}],
                    specialSlide: [{code: '', quantity: 1}]
                },
                "23": {
                    start: [{code: '', quantity: 1}],
                    inter: [{code: '', quantity: 1}],
                    angle: [{code: '', quantity: 1}],
                    specialSlide: [{code: '', quantity: 1}]
                }
            },
            // cloture2m
            "4": {
                "41": {
                    defaultColor: {
                        plug: 'black',
                        stiffener: 'green'
                    },
                    start: {},
                    inter: {},
                    angle: {}
                },
                "42": {
                    defaultColor: {
                        plug: 'black',
                        stiffener: 'green'
                    },
                    start: {},
                    inter: {},
                    angle: {}
                }
            }
        },
        pareBallon: {
            netBrokenRingSpacing: 0.3,
            recommendHeightIntermediateWire: 4,
            recommendExtraStrut: 15,
            heightCornerAvailable: [3, 4, 5, 6, 7, 8],
            taxonCode: {
                main: "1",
                netFilling: "12",
                fenceFilling: "13",
                panelFilling: "14"
            }
        },
        handRail: {
            railLengthAvailable: [5, 6],
            slideLengthMini: 100,
            slideLengthAvailable: [1, 1.5, 2, 3],
            taxonCode: {
                main: "2",
                noFilling: "21",
                fenceFilling: "22",
                panelFilling: "23"
            }
        },
        tennis: {
            taxonCode: {
                main: "3",
                threeMeters: "31",
                threeMetersFifty: "32",
                fourMeters: "33",
                doubleSeparatHandRail: "33"
            }
        },
        cloture2m: {
            recommendExtraStrut: 85,
            taxonCode: {
                main: "4",
                fenceFilling: "41",
                panelFilling: "42"
            }
        },
        combineFenceTypeTaxonCodeFakeWallColor: {
            // pareBallon ?
            "1": {
                base: 0xFF00FF,
                portal: 0xFF00FF,
                wicket: 0xFF00FF
            },
            // mains courantes
            "2": {
                base: 0xDFAF2C,
                portal: 0xDFAF2C,
                wicket: 0xDFAF2C
            },
            // tennis ?
            "3": {
                base: 0xDFAF00,
                portal: 0xDFAF00,
                wicket: 0xDFAF00
            },
            // fence 2M
            "4": {
                base: 0x7F00FF,
                portal: 0x7F00FF,
                wicket: 0x7F00FF
            }
        },
        combineFenceTypeTaxonCodeAuthorized: {
            "1": ["1", "4"],
            "2": ["2"],
            "4": ["1", "4"]
        },
        combineFillingTypeTaxonCodeAuthorized: {
            "12": ["12", "13", "14", "41", "42"],
            "13": ["12", "13", "41"],
            "14": ["12", "14", "42"],
            "21": ["21", "22", "23"],
            "22": ["21", "22"],
            "23": ["21", "23"],
            "41": ["13", "41", "12"],
            "42": ["14", "42", "12"]
        },
        combineTechnicalInfoPortalNameWithIdPortal: {
            "portail_3m_bars_fence2m": [343, 344],
            "portail_4m_bars_fence2m": [345, 346],
            "portail_3m_bars_handrails": [339, 340],
            "portail_4m_bars_handrails": [341, 342],
            "portail_3m_panels_handrails": [335, 336],
            "portail_4m_panels_handrails": [337, 338],
            "portillon_1,50m_bars_fence2m": [347, 348],
            "portillon_2m_bars_fence2m": [349, 350],
            "portillon_1,50m_bars_handrails": [327, 328],
            "potillon_2m_bars_handrails": [329, 330],
            "portillon_1,50m_panels_handrails": [331, 332],
            "potillon_2m_panels_handrails": [333, 334],
            "lisse_1m": [318, 321],
            "lisse_1m50": [320, 322],
            "lisse_2m": [323, 324],
            "lisse_3m": [325, 326],
            "porte_tennis_1m20_ht_3m_diam60_angle": [351],
            "porte_tennis_1m20_ht_3m_diam60_lineaire": [352],
            "porte_tennis_1m20_ht_3m_diam90_angle": [353],
            "porte_tennis_1m20_ht_3m_diam90_lineaire": [354],
            "porte_tennis_1m20_ht_3m_diam102_angle": [355],
            "porte_tennis_1m20_ht_3m_diam102_lineaire": [356],
            "porte_tennis_1m20_ht_3m50_diam60_angle": [363],
            "porte_tennis_1m20_ht_3m50_diam60_lineaire": [364],
            "porte_tennis_1m20_ht_3m50_diam90_angle": [365],
            "porte_tennis_1m20_ht_3m50_diam90_lineaire": [366],
            "porte_tennis_1m20_ht_3m50_diam102_angle": [367],
            "porte_tennis_1m20_ht_3m50_diam102_lineaire": [368],
            "porte_tennis_1m20_ht_4m_diam60_angle": [369],
            "porte_tennis_1m20_ht_4m_diam60_lineaire": [370],
            "porte_tennis_1m20_ht_4m_diam90_angle": [371],
            "porte_tennis_1m20_ht_4m_diam90_lineaire": [372],
            "porte_tennis_1m20_ht_4m_diam102_angle": [373],
            "porte_tennis_1m20_ht_4m_diam102_lineaire": [374],
            "porte_tennis_2m40_ht_3m_diam60_angle": [357],
            "porte_tennis_2m40_ht_3m_diam60_lineaire": [358],
            "porte_tennis_2m40_ht_3m_diam90_angle": [359],
            "porte_tennis_2m40_ht_3m_diam90_lineaire": [362],
            "porte_tennis_2m40_ht_3m_diam102_angle": [361],
            "porte_tennis_2m40_ht_3m_diam102_lineaire": [360],
            "porte_tennis_2m40_ht_3m50_diam60_angle": [380],
            "porte_tennis_2m40_ht_3m50_diam60_lineaire": [381],
            "porte_tennis_2m40_ht_3m50_diam90_angle": [383],
            "porte_tennis_2m40_ht_3m50_diam90_lineaire": [382],
            "porte_tennis_2m40_ht_3m50_diam102_angle": [384],
            "porte_tennis_2m40_ht_3m50_diam102_lineaire": [386],
            "porte_tennis_2m40_ht_4m_diam60_angle": [387],
            "porte_tennis_2m40_ht_4m_diam60_lineaire": [385],
            "porte_tennis_2m40_ht_4m_diam90_angle": [388],
            "porte_tennis_2m40_ht_4m_diam90_lineaire": [389],
            "porte_tennis_2m40_ht_4m_diam102_angle": [390],
            "porte_tennis_2m40_ht_4m_diam102_lineaire": [391]
        }
    }
}
