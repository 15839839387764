import {NetDataSet} from "./Set/NetDataSet";
import {CornerDataSet} from "./Set/CornerDataSet";
import {AccessoryConfigData} from "./AccessoryConfigData";
import {FenceDataSet} from "./Set/FenceDataSet";
import {PanelDataSet} from "./Set/PanelDataSet";

export class CartData {

    constructor(drawManager) {
        this.drawManager = drawManager;
        this.products = [];
        this.cornerAlreadyCounted = [];
        this.accesoryConfigData = new AccessoryConfigData(this.products);
        this.netDataSet = new NetDataSet(this.products, this.accesoryConfigData);
        this.fenceDataSet = new FenceDataSet(this.products, this.accesoryConfigData);
        this.panelDataSet = new PanelDataSet(this.products, this.accesoryConfigData);
        this.cornerDataSet = new CornerDataSet(this.products, this.accesoryConfigData);
    }

    /**
     * @param {Wall} wall
     */
    addWallProduct(wall) {
        // TODO wall.cornerHeightType doit être modfier avec netLength (choix de la hauteur du filet)
        if (wall.netProductChoice && (wall.cornerHeightType)) {
            this.netDataSet.addNetSet(wall);
        }
        if (wall.fenceProductChoice && !wall.isPortal) {
            this.fenceDataSet.addFenceSet(wall);
        }
        if (wall.panelProductChoice && !wall.isPortal) {
            this.panelDataSet.addPanelSet(wall);
        }
    }

    /**
     * @param {Wall} wall
     * @param {Corner|SimpleCorner} corner
     */
    addCornerProduct(wall, corner) {
        this.removeDuplicateCornerProduct(corner);

        const addedProducts = this.cornerDataSet.addCornerAccessories(wall, corner);
        this.cornerAlreadyCounted.push({
            corner: corner,
            addedProducts: addedProducts
        });
    }

    removeDuplicateCornerProduct(corner){
        this.cornerAlreadyCounted.forEach((item) => {
            if(item.corner.id === corner.id){
                item.addedProducts.forEach((product) => {
                    this.accesoryConfigData.removeAccessory(product.code,product.quantity);
                })
                this.cornerAlreadyCounted.splice(this.cornerAlreadyCounted.indexOf(item),1);
            }
        })
    }

    getProducts() {
        return this.products;
    }

}
