import {DrawManager} from "../manager/DrawManager";
import {QuickSettingsHelper} from "./QuickSettingsHelper";
import {Environment} from "../environment/Environment";
const jc = require('json-cycle');

export class DrawPanel {
    /**
     *
     * @param quickSettings
     * @param {DrawManager} drawManager
     * @param {string} pass
     * @param configuratorStarter
     */
    constructor(quickSettings, drawManager,pass, configuratorStarter) {
        this.pass = pass;
        this.MODE_MODIFY_ORIGIN = "mode_modify_origin";
        this.MODE_ADD_CORNER = 'add_corner';
        this.app_parent = document.getElementById('bp3d-js-app');
        this.quickSettings = quickSettings;
        this.settingPanel = this.quickSettings.create(450, 0, Environment.drawPanelEnv.title, this.app_parent);
        this.quickSettingsHelper = new QuickSettingsHelper(this.settingPanel);
        this.drawManager = drawManager;
        this.fenceElementManager = this.drawManager.getFenceElementManager();
        this.configuratorStarter = configuratorStarter;
        this.modeUpdateScaling = false;
        this.scaleValue = 2;
        this.toggleButtonOn = null;
        this.modifyOriginButton = null;
        this.addCornerButton = null;
        this.displayWallBaseDimension = true;
        this.displaySealingDimension = true;
        this.displayOpeningDimension = true;
        this.displayIntermediateDimension = true;
        this.displayPortalDimension = true;
    }

    /**
     * Création du panneau settings de départ
     */
    createStartControl() {
        this.settingPanel.setPosition(0, 0);
        this.settingPanel.addButton(Environment.drawPanelEnv.button.deleteProject, this.reset.bind(this));
        this.settingPanel.addButton(Environment.drawPanelEnv.button.basketProject, this.addCart.bind(this));
        this.settingPanel.addButton(Environment.drawPanelEnv.button.exportProject, this.export.bind(this));
        this.settingPanel.addBoolean(Environment.drawPanelEnv.defaultInputMessage.hideWallBasesDim, this.displayWallBaseDimension, this.toggleDimsBase.bind(this));
        this.settingPanel.addBoolean(Environment.drawPanelEnv.defaultInputMessage.hideSealingDim, this.displaySealingDimension, this.toggleDimsSealing.bind(this));
        this.settingPanel.addBoolean(Environment.drawPanelEnv.defaultInputMessage.hideFakeWallDim, this.displayOpeningDimension, this.toggleDimsOpening.bind(this));
        this.settingPanel.addBoolean(Environment.drawPanelEnv.defaultInputMessage.hideIntermediateDim, this.displayIntermediateDimension, this.toggleDimsIntermediate.bind(this));
        this.settingPanel.addBoolean(Environment.drawPanelEnv.defaultInputMessage.hidePortalDim, this.displayIntermediateDimension, this.toggleDimsPortal.bind(this));
        this.quickSettingsHelper.addSectionTitle(Environment.drawPanelEnv.subtitleScaling.id, Environment.drawPanelEnv.subtitleScaling.text(this.scaleValue));
        this.settingPanel.addFileChooser(Environment.drawPanelEnv.titleInput.addPicture, Environment.drawPanelEnv.button.addPicture, Environment.globalConfigurationEnv.pictureAcceptList, this.drawManager.imageScaling.__addImageScalingEvent);
        this.settingPanel.addButton(Environment.drawPanelEnv.button.deletePicture, this.drawManager.imageScaling.__removeImageScalingEvent);
        this.settingPanel.addButton(Environment.drawPanelEnv.button.updateScale.text, this.toggleUpdateScalingButton.bind(this));
        this.quickSettingsHelper.addSectionTitle(Environment.drawPanelEnv.subtitleDrawing.id, Environment.drawPanelEnv.subtitleDrawing.text);
        this.settingPanel.addButton(Environment.drawPanelEnv.button.addCorner, this.toggleAddCornerButton.bind(this));
        this.settingPanel.addButton(Environment.drawPanelEnv.button.updateOriginCorner, this.toggleButtonModifyLastNode.bind(this));
        this.settingPanel.addButton(Environment.drawPanelEnv.button.newElement, this.fenceElementManager.__createFenceElementEvent);
        this.settingPanel.addDropDown(Environment.drawPanelEnv.titleInput.elementList, this.fenceElementManager.fenceElementsName, this.fenceElementManager.__changeFenceElementEvent);
        this.settingPanel.setHeight(window.innerHeight);
    }

    async addCart(){

        let jsonData = {
            'user': this.pass,
            'products' : this.drawManager.currentCartData.getProducts()
        };
        console.log(this.drawManager.currentCartData.getProducts())
        const response = await fetch('https://clomo.3dh.ovh/configurateur/add-cart-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        });

        const data = await response.json();
        console.log(data);
    }
    toggleDimsBase(){
        this.displayWallBaseDimension = !this.displayWallBaseDimension;
        this.drawManager.drawFence();
    }
    toggleDimsOpening(){
        this.displayOpeningDimension = !this.displayOpeningDimension;
        this.drawManager.drawFence();
    }

    toggleDimsSealing(){
        this.displaySealingDimension = !this.displaySealingDimension;
        this.drawManager.drawFence();
    }
    toggleDimsIntermediate(){
        this.displayIntermediateDimension = !this.displayIntermediateDimension;
        this.drawManager.drawFence();
    }

    toggleDimsPortal(){
        this.displayPortalDimension = !this.displayPortalDimension;
        this.drawManager.drawFence();
    }

    toggleAllButtonOff() {
        if (this.toggleButtonOn === this.MODE_MODIFY_ORIGIN && this.modifyOriginButton !== null) {
            this.toggleButtonModifyLastNode();
        }
        if (this.toggleButtonOn === this.MODE_ADD_CORNER && this.addCornerButton !== null) {
            this.toggleAddCornerButton();
        }
    }

    toggleButtonModifyLastNode() {
        if (this.modifyOriginButton === null) {
            this.modifyOriginButton = document.querySelector('.qs_container_selected input')
        }
        this.modifyOriginButton.style.transition = this.drawManager.rootCssStyle.getPropertyValue("--transition-button");
        if (this.toggleButtonOn === this.MODE_MODIFY_ORIGIN) {
            this.modifyOriginButton.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--panel-title");
            this.modifyOriginButton.style.color = this.drawManager.rootCssStyle.getPropertyValue("--white");
            this.toggleButtonOn = null;
        } else {
            this.toggleAllButtonOff();
            this.toggleButtonOn = this.MODE_MODIFY_ORIGIN;
            this.modifyOriginButton.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--white");
            this.modifyOriginButton.style.color = this.drawManager.rootCssStyle.getPropertyValue("--black");
        }
        this.drawManager.mainCorner2D.toggleModifyLastNodeMode();
    }

    updateCornerBaseListControl() {
        this.settingPanel.removeControl(Environment.drawPanelEnv.titleInput.elementList)
        this.settingPanel.addDropDown(Environment.drawPanelEnv.titleInput.elementList, this.fenceElementManager.fenceElementsName, this.fenceElementManager.__changeFenceElementEvent);
    }

    remove() {
        this.settingPanel.destroy();
    }


    toggleUpdateScalingButton(element = null, reset = true) {
        if (element === null) {
            element = document.querySelector('.qs_container_selected input');
        }
        element.style.transition = this.drawManager.rootCssStyle.getPropertyValue("--transition-button");
        element.id = Environment.drawPanelEnv.button.updateScale.id;
        if (this.modeUpdateScaling) {
            element.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--panel-title");
            element.style.color = this.drawManager.rootCssStyle.getPropertyValue("--white");
        } else {
            element.style.color = this.drawManager.rootCssStyle.getPropertyValue("--black");
            element.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--white");

        }
        this.modeUpdateScaling = !this.modeUpdateScaling;
        if (this.modeUpdateScaling) {
            this.drawManager.blueprint3d.floorplanner.localPosition = null;
            document.addEventListener('click', this.drawManager.imageScaling.__updateImageScalingEvent);
        } else {
            document.removeEventListener('click', this.drawManager.imageScaling.__updateImageScalingEvent);
            if (reset) {
                this.drawManager.imageScaling.resetImageScaling();
            } else {
                this.drawManager.imageScaling.clickUpdateScaling1 = null;
                this.drawManager.imageScaling.clickUpdateScaling2 = null;
            }
        }
    }

    reset() {
        let confirm = window.confirm(Environment.drawConfiguration.main.confirmMessage.initProjectMessage);
        if (confirm) {
            location.reload();
        }
    }

    export() {
        const blob = new Blob([JSON.stringify(jc.decycle(this.drawManager.fenceElementManager.getFenceElements()),this.replacer)], {type: "application/json"})
        const href = URL.createObjectURL(blob);
        const a = Object.assign(
            document.createElement('a'), {
                href,
                style: "display:none",
                download: "myProject.json"
            });
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(href);
        console.log(jc.retrocycle(blob));
        a.remove();
    }
    replacer(key,value)
    {
        if (key==="floorplan") return undefined;
        else return value;
    }

    toggleAddCornerButton() {
        if (this.addCornerButton === null) {
            this.addCornerButton = document.querySelector('.qs_container_selected input')
        }
        this.addCornerButton.style.transition = this.drawManager.rootCssStyle.getPropertyValue("--transition-button");
        if (this.toggleButtonOn === this.MODE_ADD_CORNER) {
            this.drawManager.blueprint3d.floorplanner.__tempWall.visible = false;
            this.drawManager.blueprint3d.floorplanner.__floorplanContainer.plugins.resume('drag');
            this.addCornerButton.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--panel-title");
            this.drawManager.drawFence();
            this.addCornerButton.style.color = this.drawManager.rootCssStyle.getPropertyValue("--white");
            this.toggleButtonOn = null;
        } else {
            this.toggleAllButtonOff();
            this.toggleButtonOn = this.MODE_ADD_CORNER;
            this.drawManager.blueprint3d.setViewer2DModeToDraw();
            this.addCornerButton.style.backgroundColor = this.drawManager.rootCssStyle.getPropertyValue("--white");

            this.addCornerButton.style.color = this.drawManager.rootCssStyle.getPropertyValue("--black");
        }
    }

}
