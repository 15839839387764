import {Environment} from "../../environment/Environment";

export class CornerDataSet {

    BALLSCREEN_BASE = 'ballscreen_base';

    constructor(products, accessoryConfigData) {
        this.products = products;
        this.accesoryConfigData = accessoryConfigData;
    }

    /**
     * @param {Wall} wall
     * @param {Corner|SimpleCorner} corner
     * @returns {object}
     */
    addCornerAccessories(wall, corner) {
        let addedProducts = [];
        if(corner.cornerFenceType === Environment.dataEcommerceEnv.pareBallon.taxonCode.main){
            const ballScreenSetBase = this.getBallScreenSetBase(wall, corner);
            this.addSet(ballScreenSetBase, wall, corner, addedProducts);
            const setAdditional = this.getBallScreenAdditionalSet(wall, corner);
            this.addSet(setAdditional, wall, corner, addedProducts);
        }
        return addedProducts;
    }

    addSet(set, wall, corner, addedProducts) {
        const accessories = ['corner', 'tensionCorner', 'cableGuide', 'bracing','fixing']
        const accessoriesWithColorChoice = ['plug']
        if (set) {
            this.ballScreenNeedAdditionalGuideCable(set, wall, corner);
            accessories.forEach((accessory) => {
                if (set.hasOwnProperty(accessory)) {
                    const code = set[accessory].code;
                    const quantity = set[accessory].quantity;
                    this.accesoryConfigData.addAccessory(code, quantity);
                    addedProducts.push({code: code, quantity: quantity});
                }
            });
            accessoriesWithColorChoice.forEach((accessory) => {
                if (set.hasOwnProperty(accessory)) {
                    const code = set[accessory][wall[accessoriesWithColorChoice + "Color"]].code;
                    const quantity = set[accessory][wall[accessoriesWithColorChoice + "Color"]].quantity;
                    this.accesoryConfigData.addAccessory(code, quantity);
                    addedProducts.push({code: code, quantity: quantity});
                }
            });
        }
    }

    /**
     * @param {Wall} wall
     * @param {Corner|SimpleCorner} corner
     * @returns {object|null}
     */
    getBallScreenSetBase(wall, corner) {
        let set = null;
        if (wall.fillingTypeCode !== null) {
            if (wall.fenceTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.main) {
                if (corner.cornerDiameterOrLength === 6 && corner.cornerWidth === null) {
                    set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][this.BALLSCREEN_BASE].tube60[corner.cornerType];
                }
                if (corner.cornerDiameterOrLength === 9 && corner.cornerWidth === null) {
                    set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][this.BALLSCREEN_BASE].tube90[corner.cornerType];
                }
                if (corner.cornerDiameterOrLength !== null && corner.cornerWidth !== null) {
                    set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][this.BALLSCREEN_BASE].rectangle[corner.cornerType];
                }
            }
        }
        return set;
    }
    getBallScreenAdditionalSet(wall,corner){
        let set = null;
        if(wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.fenceFilling){
            set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][wall.fillingTypeCode][corner.cornerType];
        }
        if(wall.fillingTypeCode === Environment.dataEcommerceEnv.pareBallon.taxonCode.panelFilling){
            if (corner.cornerDiameterOrLength === 6 && corner.cornerWidth === null) {
                set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][wall.fillingTypeCode].tube60[corner.cornerType];
            }
            if (corner.cornerDiameterOrLength === 9 && corner.cornerWidth === null) {
                set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][wall.fillingTypeCode].tube90[corner.cornerType];
            }
            if (corner.cornerDiameterOrLength !== null && corner.cornerWidth !== null) {
                set = Environment.dataEcommerceEnv.sets[wall.fenceTypeCode][wall.fillingTypeCode].rectangle[corner.cornerType];
            }
        }
        return set;
    }
    /**
     * Ajoute des guides cables si la hauteur du filet dépasse la recommandation, que ce poteau est de type intermédiaire et pare-ballons
     * et que l'utilisateur a validé l'ajout
     * @param {object} set
     * @param {Wall} wall
     * @param {Corner} corner
     */
    ballScreenNeedAdditionalGuideCable(set, wall, corner) {

        const heightNet = this.accesoryConfigData.getHeightNet(wall);
        if (
            heightNet > Environment.dataEcommerceEnv.pareBallon.recommendHeightIntermediateWire &&
            corner.cornerType === Environment.drawConfiguration.main.cornerType.inter &&
            corner.cornerFenceType === Environment.dataEcommerceEnv.pareBallon.taxonCode.main &&
            wall.netIntermediateWireChoice
        ) {
            const quantity = Math.ceil(heightNet / Environment.dataEcommerceEnv.pareBallon.recommendHeightIntermediateWire) - 1;
            this.accesoryConfigData.addAccessory(set.cableGuide.code, quantity);
        }
    }
}
